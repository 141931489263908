import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logo-color-1.png";
class Footer4 extends Component {
  render() {
    return (
      <>
        <footer className="site-footer footer-white">
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-6 footer-col-4 ">
                  <div className="widget">
                    <img src={logo} alt="Logo here" />

                    <ul className="list-inline m-a0">
                      <li>
                        <Link
                          to={{
                            pathname:
                              "https://www.facebook.com/theyoungtoyoung",
                          }}
                          target="blank"
                          className="site-button facebook circle mr-1"
                        >
                          <i className="fa fa-facebook"></i>
                        </Link>
                      </li>

                      <li>
                        <a
                          className="site-button google-plus circle mr-1"
                          href="mailto:they2yofficial@gmail.com"
                          target="bank"
                        >
                          <strong>
                            <i className="fa fa-google-plus"></i>
                          </strong>
                        </a>
                      </li>

                      <li>
                        <Link
                          to={{
                            pathname:
                              "https://www.linkedin.com/company/the-young-to-young/",
                          }}
                          target="blank"
                          className="site-button linkedin circle mr-1"
                        >
                          <i className="fa fa-linkedin"></i>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={{
                            pathname: "https://www.instagram.com/the_y2y",
                          }}
                          target="blank"
                          className="site-button instagram circle mr-1"
                        >
                          <i className="fa fa-instagram"></i>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={{ pathname: "https://twitter.com/Y2yThe" }}
                          target="_blank"
                          className="site-button twitter circle mr-1"
                        >
                          <i className="fa fa-twitter"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-5 col-lg-2 col-md-6 col-sm-6 footer-col-4">
                  <div className="widget widget_getintuch border-0">
                    <ul>
                      <li>
                        <Link to={"/aboutus"}>
                          <strong>About Us</strong>{" "}
                        </Link>
                      </li>

                      <li>
                        <Link to={"/y2y-webinar"}>
                          {" "}
                          <strong>the Y2Y Webinar</strong>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/y2y-on-line"}>
                          <strong>the Y2Y Online</strong>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/web-developement"}>
                          <strong>E-Learning</strong>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-7 col-lg-2 col-md-6 col-sm-6 footer-col-4">
                  <div className="widget widget_getintuch border-0">
                    <ul>
                      <li>
                        <Link to={"/science-and-tech"}>
                          <strong>Science & Technology</strong>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/compus-ambasador"}>
                          <strong>Ambassador</strong>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/blog-classic-right-sidebar"}>
                          <strong>Blog</strong>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/research"}>
                          <strong>Research</strong>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/join-our-program"}>
                          <strong>Registration</strong>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* contact comomun */}
                <div className="col-lg-4 col-md-6 col-sm-6 footer-col-4">
                  <div className="widget widget_getintuch">
                    <ul>
                      <li>
                        <Link to={"/contactus"}>
                          <strong>Our Team</strong>
                        </Link>
                      </li>

                      <li>
                        <a href="mailto:they2yofficial@gmail.com" target="bank">
                          <strong style={{ textTransform: "lowercase" }}>
                            they2yofficial@gmail.com
                          </strong>
                        </a>
                      </li>
                      <li>
                        <Link to={{ pathname: "+212698487054" }} target="blank">
                          <strong>+212698487054</strong>
                        </Link>
                      </li>
                      <li>
                        <Link to={{ pathname: "+918050399715" }} target="blank">
                          <strong>+918050399715</strong>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* again */}
              </div>
            </div>
          </div>

          <div className="footer-bottom bg-primary">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-sm-6 text-center ">
                  {" "}
                  <span>
                    Copyright © 2021 - {new Date().getFullYear()}
                    {"  "}The Young2Young. All Rights Reserved
                  </span>{" "}
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer4;
