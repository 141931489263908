import React from "react";
// import about15 from "./../../images/about/pic15.PNG";
import Homepage1 from "./../../images/about/homepage.gif";
import "./homepages.css";
import Toastify from "./munfiles/advertisement/ReactToastify";
function Homepage() {
  return (
    <div className="section-full industry-service">
      <div
        style={
          {
            // backgroundColor: "#1D29A8",
          }
        }
        className="section-full m-bt0 p-tb0"
      >
        <div className="row d-flex ">
          <div
            style={{ marginRight: "0px" }}
            className="col-lg-5 col-md-12 homepage__mediaquery"
          >
            <h1
              style={{ color: "#1D29A8" }}
              className="box-title m-t0 m-b20 font-60 text-white text-left align-items-center p-l100 p-t100 homepage__textcenter"
            >
              Stay close to <br />
              the vision that <br /> you feel it's your strength
            </h1>
          </div>

          <div className="col-lg-7 col-md-12 ">
            <img src={Homepage1} className="radius-sm w-100 h-100" alt="" />
          </div>
        </div>
        <div className="container">
          <Toastify />
        </div>
      </div>
    </div>
  );
}

export default Homepage;
