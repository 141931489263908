import React from "react";
import loading from "./loading.svg";
import "./App.css";
function Loading() {
  return (
    <div className="loader">
      <img src={loading} alt="Loading" />
      <h3>Loading...</h3>
    </div>
  );
}

export default Loading;
