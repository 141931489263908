import React, { useEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import ThemeButton from "./Element/ThemeButton/ThemeButton";
 

import StudentDashboard from "./Pages/dashboar-student/students-dashboard/StudentDashboard.jsx";
import Index2 from "./Pages/Index2";
import Index3 from "./Pages/Index3";
import Index4 from "./Pages/Index4";
import Index5 from "./Pages/Index5";
import Index6 from "./Pages/Index6";
import Index7 from "./Pages/Index7";
import Aboutus1 from "./Pages/Aboutus/Aboutus1";
import Aboutus2 from "./Pages/Aboutus/Aboutus2";
import Error403 from "./Pages/Error/Error403";
import Error404 from "./Pages/Error/Error404";
import Error405 from "./Pages/Error/Error405";
import ComingSoon1 from "./Pages/ComingSoon/ComingSoon1";
import UnderMaintenance from "./Pages/UnderMaintenance";
import Expertise from "./Pages/Expertise";
import ProjectManagement from "./Pages/ProjectManagement";
import OurProjects from "./Pages/OurProjects";
import ProjectDetails from "./Pages/ProjectDetails";
import Team from "./Pages/Team";
import Faqs from "./Pages/Faqs";
import Login from "./Pages/Login";
import Login1 from "./payment/Login";
import Register from "./Pages/Register";
import Register1 from "./payment/Register";
import Contact from "./Pages/Contact";
import Service from "./Pages/Service/Service";
import ServicesDetails from "./Pages/Service/ServicesDetails";
import BlogStandard from "./Pages/BlogStandard/BlogStandard";
import BlogStandardLeftSidebar from "./Pages/BlogStandard/BlogStandardLeftSidebar";
import BlogStandardRightSidebar from "./Pages/BlogStandard/BlogStandardRightSidebar";
import BlogClassicGrid from "./Pages/BlogClassic/BlogClassicGrid";
import BlogClassicLeftSidebar from "./Pages/BlogClassic/BlogClassicLeftSidebar";
import BlogClassicRightSidebar from "./Pages/BlogClassic/BlogClassicRightSidebar";
import BlogListLeftSidebar from "./Pages/BlogList/BlogListLeftSidebar";
import BlogListRightSidebar from "./Pages/BlogList/BlogListRightSidebar";
import BlogDetails from "./Pages/BlogDetail/BlogDetails";
import ProtfolioFullWidth from "./Pages/Protfolio/ProtfolioFullWidth";
import PortfolioGrid2 from "./Pages/Protfolio/PortfolioGrid2";
import PortfolioGrid3 from "./Pages/Protfolio/PortfolioGrid3";
import PortfolioGrid4 from "./Pages/Protfolio/PortfolioGrid4";
import ShopColumns from "./Pages/Shop/ShopColumns";
import ShopColumnsSidebar from "./Pages/Shop/ShopColumnsSidebar";

import ShopCart from "./Pages/Shop/ShopCart";
import ShopCheckout from "./Pages/Shop/ShopCheckout";
import ShopProductDetails from "./Pages/Shop/ShopProductDetails";

import ShopWishlist from "./Pages/Shop/ShopWishlist";
import ShopLogin from "./Pages/Shop/ShopLogin";
import ShopRegister from "./Pages/Shop/ShopRegister";

import ShortIconBox from "./Pages/ShortCode/ShortIconBox";
import ShortCounter from "./Pages/ShortCode/ShortCounter";
import ShortPortfolio from "./Pages/ShortCode/ShortPortfolio";
import ShortTabs from "./Pages/ShortCode/ShortTabs";
import ShortTeam from "./Pages/ShortCode/ShortTeam";
import ShortTestimonial from "./Pages/ShortCode/ShortTestimonial";
import ShortForm from "./Pages/ShortCode/ShortForm";
import ShortAccordions from "./Pages/ShortCode/ShortAccordions";

import ScrollToTop from "./Element/ScrollToTop";
import Dashboard from "./Pages/dashboard/Dashboard";
import { useDispatch } from "react-redux";
import { getContacts } from "../actions/contacts";
import { getNews } from "../actions/news";
import SignInSide from "./Pages/dashboar-student/SignInSide";
import Membership from "./payment/Membership";
import OrderSummary from "./payment/OrderSummary";
import { getRegistrations } from "../actions/registration";
import AboutUs from "./Pages/munfiles/AboutUs";
import Contactus from "./Pages/munfiles/Contactus";
import MunOnline from "./Pages/munfiles/munoline/MunOnline";
import EventOrganizer from "./Pages/munfiles/EventOrganizer/EventOrganizer";
import Programme from "./Pages/munfiles/programme/Programme";
import ScholarshipHack from "./Pages/munfiles/scholarshiphacks/ScholarshipHack";
import Ambassador from "./Pages/munfiles/ambassador/Ambassador";
import ELearning from "./Pages/munfiles/e-learning/ELearning";
import Freebook from "./Pages/munfiles/e-book/Freebook";
import YoungWebinar from "./Pages/munfiles/youngwebinar/YoungWebinar";
import PaymentInstruction from "./Pages/munfiles/youngwebinar/PaymentInstruction";
import HowtoParticipate from "./Pages/munfiles/youngwebinar/HowtoParticipate";
import Webdevelopment from "./Pages/munfiles/webdevelopement/Webdevelopment";
import CourseDetails from "./Pages/munfiles/e-learning/CourseDetails";
import OnePlan from "./Pages/newComponents/OnePlan";
import CompusAbasador from "./Pages/munfiles/ambasadors/CompusAbasador";
import UniversityAmbasador from "./Pages/munfiles/ambasadors/UniversityAmbasador";
import SchoolAmbasador from "./Pages/munfiles/ambasadors/SchoolAmbasador";
import StateAmbasador from "./Pages/munfiles/ambasadors/StateAmbasador";
import GraphicDesign from "./Pages/munfiles/webdevelopement/GraphicDesign";
import CourseGraphicDetails from "./Pages/munfiles/webdevelopement/GrapgicCourseDetails";
import Research from "./Pages/munfiles/research/Research";
import ScienceAndTech from "./Pages/munfiles/science-and-tech/ScienceAndTech";
import GlobalIssus from "./Pages/munfiles/global-issues/GlobalIssus";
import ScienceAndTechDetails from "./Pages/munfiles/science-and-tech/ScienceAndTechDetails";
import JoinForm from "./Pages/munfiles/JoinForm";
import Webnar from "./Pages/munfiles/webinar/Webnar";
import { fetchComments } from "../actions/comment";
import NgoJoinForm from "./Pages/munfiles/NgoJoinForm";
import AmbassadorForm from "./Pages/munfiles/AmbassadorForm";
import { getCourses } from "../actions/courses";

 
import DetailsScienceAndT from "./Pages/munfiles/webinar/Details";
import GlobalDetails from "./Pages/munfiles/global-issues/GlobalDetails";
import CourseResource from "./Pages/munfiles/course-resources/CourseResource";
import LoginGoole from "./Pages/munfiles/course-resources/LoginGoogle";
import TrendingDetails from "./Pages/trendingPrograms/TrendingDetails";

function Markup() {
  // const [currentId, setCurrentId] = useState(null);
  const dispatch = useDispatch();
  // const user = JSON.parse(localStorage.getItem("profile"));

  useEffect(() => {
    dispatch(getContacts());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getRegistrations());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getNews());
  }, [dispatch]);
  useEffect(() => {
    dispatch(fetchComments());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCourses());
  }, [dispatch]);

  return (
    <BrowserRouter>
      <div className="page-wraper">
        <Switch>
          <Route path="/" exact component={Index5} />
          <Route path="/index-2" exact component={Index2} />
          <Route path="/index-3" exact component={Index3} />
          <Route path="/index-4" exact component={Index4} />
          <Route path="/index-5" exact component={Index5} />
          <Route path="/index-6" exact component={Index6} />
          <Route path="/index-7" exact component={Index7} />
          <Route path="/about-1" exact component={Aboutus1} />
          <Route path="/about-2" exact component={Aboutus2} />
          <Route path="/error-403" exact component={Error403} />
          <Route path="/error-404" exact component={Error404} />
          <Route path="/error-405" exact component={Error405} />
          <Route path="/coming-soon-1" exact component={ComingSoon1} />
          <Route path="/under-maintenance" exact component={UnderMaintenance} />
          <Route path="/expertise" exact component={Expertise} />
          <Route
            path="/project-management"
            exact
            component={ProjectManagement}
          />
          <Route path="/our-projects" exact component={OurProjects} />
          <Route path="/project-details" exact component={ProjectDetails} />
          <Route path="/team" exact component={Team} />
          <Route path="/faqs" exact component={Faqs} />
          <Route path="/login" exact component={Login} />
          <Route path="/login1" exact component={Login1} />
          <Route path="/register" exact component={Register} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/service" exact component={Service} />
          <Route path="/services-details" exact component={ServicesDetails} />
          <Route path="/blog-standard" exact component={BlogStandard} />
          <Route
            path="/blog-standard-left-sidebar"
            exact
            component={BlogStandardLeftSidebar}
          />
          <Route
            path="/blog-standard-right-sidebar"
            exact
            component={BlogStandardRightSidebar}
          />
          <Route path="/blog-classic-grid" exact component={BlogClassicGrid} />
          <Route
            path="/blog-classic-left-sidebar"
            exact
            component={BlogClassicLeftSidebar}
          />
          <Route
            path="/blog-classic-right-sidebar"
            exact
            component={BlogClassicRightSidebar}
          />
          <Route
            path="/blog-list-left-sidebar"
            exact
            component={BlogListLeftSidebar}
          />
          <Route
            path="/blog-list-right-sidebar"
            exact
            component={BlogListRightSidebar}
          />
          <Route path="/blog-details/:id" exact component={BlogDetails} />
          <Route
            path="/portfolio-full-width"
            exact
            component={ProtfolioFullWidth}
          />
          <Route path="/portfolio-grid-2" exact component={PortfolioGrid2} />
          <Route path="/portfolio-grid-3" exact component={PortfolioGrid3} />
          <Route path="/portfolio-grid-4" exact component={PortfolioGrid4} />
          <Route path="/shop-columns" exact component={ShopColumns} />
          <Route
            path="/shop-columns-sidebar"
            exact
            component={ShopColumnsSidebar}
          />
          <Route path="/shop-cart" exact component={ShopCart} />
          <Route path="/shop-checkout" exact component={ShopCheckout} />
          <Route
            path="/shop-product-details"
            exact
            component={ShopProductDetails}
          />
          <Route path="/shop-wishlist" exact component={ShopWishlist} />
          <Route path="/shop-login" exact component={ShopLogin} />
          <Route path="/shop-register" exact component={ShopRegister} />
          <Route path="/short-icon-box" exact component={ShortIconBox} />
          <Route path="/short-counter" exact component={ShortCounter} />
          <Route path="/short-portfolio" exact component={ShortPortfolio} />
          <Route path="/short-tabs" exact component={ShortTabs} />
          <Route path="/short-team" exact component={ShortTeam} />
          <Route path="/short-testimonial" exact component={ShortTestimonial} />
          <Route path="/short-form" exact component={ShortForm} />
          <Route path="/short-accordions" exact component={ShortAccordions} />
          <Route
            exact
            path={[
              "/students",
              "/contact-zone",
              "/dashboard",
              "/publish-news",
              "/time-table",
            ]}
          >
            <Dashboard />
          </Route>
          <Route
            exact
            path={[
              "/register",
              "/library",
              "/request",
              "/time-Table",
              "/profile",
              "/profile",
              "/information-service",
              "/lesson",
              "/re-registration",
            ]}
          >
            <StudentDashboard />
          </Route>
          <Route exact path="/login" component={Login} />

          <Route exact path="/register1" component={Register1} />
          <Route exact path="/createAcount" component={Membership} />
          <Route exact path="/summary" component={OrderSummary} />
          <Route exact path="/aboutus" component={AboutUs} />
          <Route exact path="/contactus" component={Contactus} />
          <Route exact path="/y2y-on-line" component={MunOnline} />
          <Route exact path="/event-organiser" component={EventOrganizer} />
          <Route exact path="/program" component={Programme} />
          <Route exact path="/scholarship-hacks" component={ScholarshipHack} />
          <Route exact path="/ambassador" component={Ambassador} />
          <Route exact path="/e-learning" component={ELearning} />
          <Route exact path="/free-book" component={Freebook} />
          <Route exact path="/webinar" component={YoungWebinar} />
          <Route exact path="/payment-methode" component={PaymentInstruction} />
          <Route exact path="/ngo-join-form" component={NgoJoinForm} />
          <Route exact path="/ambassadors-form" component={AmbassadorForm} />

          <Route
            exact
            path="/how-to-participate"
            component={HowtoParticipate}
          />
          <Route exact path="/web-developement" component={Webdevelopment} />
          <Route exact path="/graphic-design" component={GraphicDesign} />

          <Route exact path="/read-more-social-program" component={OnePlan} />
          <Route exact path="/graphic-design" component={"d"} />
          <Route exact path="/ccna-cisco" component={"f"} />

          <Route exact path="/compus-ambasador" component={CompusAbasador} />
          <Route
            exact
            path="/university-ambasador"
            component={UniversityAmbasador}
          />
          <Route exact path="/state-ambasador" component={StateAmbasador} />
          <Route exact path="/school-ambasador" component={SchoolAmbasador} />
          <Route exact path="/research" component={Research} />
          <Route exact path="/global-issue" component={GlobalIssus} />
          <Route exact path="/science-and-tech" component={ScienceAndTech} />
          <Route
            exact
            path="/science-and-tec/:id"
            component={DetailsScienceAndT}
          />
          <Route
            exact
            path="/global-issues-details/:id"
            component={GlobalDetails}
          />

          <Route
            exact
            path="/science-and-tech-details/:id"
            component={ScienceAndTechDetails}
          />

          <Route
            path="/sign-in-sudent-dashboard"
            exact
            component={SignInSide}
          />
          <Route path="/course-details/:id" component={CourseDetails} />
          <Route path="/join-our-program" component={JoinForm} />
          <Route path="/y2y-webinar" component={Webnar} />
          <Route path="/y2y-work-shop" component={Webnar} />

          <Route
            path="/course-details-graphic/:id"
            component={CourseGraphicDetails}
          />

          <Route path="/login-course-resourses" component={LoginGoole}/>
          <Route path="/webdev-resources" component={CourseResource}/>
          <Route path="/programDetail/:id" component={TrendingDetails}/>

          
          
        </Switch>
      </div>
      <ScrollToTop />
      <ThemeButton />
    </BrowserRouter>
  );
}

export default Markup;
