import React from "react";
import "./Scholarship.css";
import Footer4 from "../../../Layout/footer4";
import Header3 from "../../../Layout/header3";
import Image from "./Asset-3.png";
function ScholarshipHack() {
  return (
    <>
      <Header3 />
      <div className="sholarship">
        <h1>"2 HARI JAGO STRATEGI RAIH BEASISWA DI KOREA"</h1>
        <h3 style={{ color: "#FFB800" }}>Scholarship Hacks Class</h3>
        <h3>3 - 4 JULI 2022</h3>
        <button className="scholarship__button" type="button">
          DAFTAR SEKARANG
        </button>
      </div>
      <div className="sholarship__benefits">
        <h1 className="text-center">Benefit</h1>
        <div className="sholarship__empty_veiw"></div>

        <div className="scholarship__cards">
          <div className="sholarship__div">
            <img
              className="programme_image__Logo"
              src={Image}
              alt="shocharliship "
            />
          </div>
          <div className="sholarship__div">
            <img
              className="programme_image__Logo"
              src={Image}
              alt="shocharliship "
            />
          </div>
          <div className="sholarship__div">
            <img
              className="programme_image__Logo"
              src={Image}
              alt="shocharliship "
            />
          </div>
          <div className="sholarship__div">
            <img
              className="programme_image__Logo"
              src={Image}
              alt="shocharliship "
            />
          </div>
          <div className="sholarship__div">
            <img
              className="programme_image__Logo"
              src={Image}
              alt="shocharliship "
            />
          </div>
          <div className="sholarship__div">
            <img
              className="programme_image__Logo"
              src={Image}
              alt="shocharliship "
            />
          </div>
        </div>
      </div>

      {/* Profile mentor*/}

      <Footer4 />
    </>
  );
}

export default ScholarshipHack;
