import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import { createStore, compose, applyMiddleware } from "redux";
// import thunk from "redux-thunk";
// import reducers from "./reducers/index";
import { Provider } from "react-redux";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

import store from "./store";

// const store = createStore(reducers, compose(applyMiddleware(thunk)));

ReactDOM.render(
  <Provider store={store}>
    <PayPalScriptProvider deferLoading={true}>
      <App />
    </PayPalScriptProvider>
  </Provider>,

  document.getElementById("root")
);
