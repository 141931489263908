import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
const firebaseConfig = {
  apiKey: "AIzaSyBKxUtPA-g5W30e3rHW42JNaZZBtcYVKFg",
  authDomain: "theyoung2young.firebaseapp.com",
  projectId: "theyoung2young",
  storageBucket: "theyoung2young.appspot.com",
  messagingSenderId: "800900808883",
  appId: "1:800900808883:web:146fd95cac60c6f7f1b0c6",
  measurementId: "G-C5VLHJYT40",
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const database = getDatabase(app);
export default db;
// export default database;
