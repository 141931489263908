import React from "react";
import "./Research.css";
import dream from "./images/dream.gif";
import research from "./images/research.jpeg";
import Footer from "../../../Layout/footer4";
import Header from "../../../Layout/header3";
import Bullet from "./Bullet";
import { Link } from "react-router-dom";
function Research() {
  return (
    <>
      <Header />
      <div className="container">
        <h1 className="text-center">
        Research is not only about acquiring knowledge; it is also about creating knowledge.{" "}
        </h1>
        <p className="text-center">
        A systematic investigation into a problem or situation with the goal of identifying facts and/or opinions that will aid in the solution of the problem or dealing with the situation is defined as research. It is a scholarly investigation that focuses on independent researchers' research objectives and questions. It combines formal, scientific, and systematic methods of gathering, analyzing, and interpreting data in order to gain a better understanding of a phenomenon of interest or concern.
        </p>
        <div className="col-lg-12 col-md-4 col-sm-12">
          <div className="dream__research ">
            <img src={dream} alt="" />
            <div className="dream__text">
              <p>
              Researching allows you to see what is there to see, see where no one else has seen, and discover your imaginations through in-depth study and framing your findings in a way that the rest of the world can understand your work. Research is not a knowledge, but rather a method of creating knowledge.
              </p>
              <p>
              Through in-depth study and framing your findings in a way that the rest of the world can understand your work, researching allows you to see what is there to see, see where no one else has seen, and discover your imaginations. Research is not a knowledge, but rather a method of creating knowledge. 
              </p>
              <p>
              It generates new research ideas and questions, which are then evaluated through peer review using academic discipline standards as a guide. It encourages students to become scholars by discovering new formulations with in-depth knowledge to improve our daily lives, as well as a solution-driven approach for next-generation developments.
              </p>
            </div>
          </div>
          {/* research post */}
          <div className="research__publish">
            <h1 className="text-center">Why research publication?</h1>
            <Bullet p="Assists students in conducting a systematic investigation of a specific topic." />
            <Bullet p="Accumulate scholarly publication experience " />
            <Bullet p="Make contact with academics." />
            <Bullet p="Assists students in identifying problem solutions and gaining in-depth knowledge." />
            <Bullet p="Professionalize their critical thinking experience so you can make sound career decisions." />
            <Bullet p="Aids in obtaining well-paying jobs" />
            <Link to={"/join-our-program"}>
            <button className="btn btn-lg btn-primary mb-10" style={{marginBottom:"10px"}}>Join Now</button>
            </Link>
            {/* cards */}
            {/* <div className=" flex__cards ">
              <div className="research__card">
                <img src={research} alt="research" />
                <div className="research__card__text">
                  <p>
                    laborum numquam blanditiis harum quisquam eius sed odit
                    fugiat iusto fuga praesentium optio, eaque rerum! Provident
                    similique accusantium nemo autem.
                  </p>
                  <div className="research__card__bottom">
                    <span style={{ color: "blue", fontWeight: "bolder" }}>
                      Published by: Alison De Best
                    </span>
                    <button className="button__research">Read More</button>
                  </div>
                </div>
              </div>
              <div className="research__card">
                <img src={research} alt="research" />
                <div className="research__card__text">
                  <p>
                    laborum numquam blanditiis harum quisquam eius sed odit
                    fugiat iusto fuga praesentium optio, eaque rerum! Provident
                    similique accusantium nemo autem.
                  </p>
                  <div className="research__card__bottom">
                    <span style={{ color: "blue", fontWeight: "bolder" }}>
                      Published by: Alison De Best
                    </span>
                    <button className="button__research">Read More</button>
                  </div>
                </div>
              </div>
              <div className="research__card">
                <img src={research} alt="research" />
                <div className="research__card__text">
                  <p>
                    laborum numquam blanditiis harum quisquam eius sed odit
                    fugiat iusto fuga praesentium optio, eaque rerum! Provident
                    similique accusantium nemo autem.
                  </p>
                  <div className="research__card__bottom">
                    <span style={{ color: "blue", fontWeight: "bolder" }}>
                      Published by: Alison De Best
                    </span>
                    <button className="button__research">Read More</button>
                  </div>
                </div>
              </div>
              <div className="research__card">
                <img src={research} alt="research" />
                <div className="research__card__text">
                  <p>
                    laborum numquam blanditiis harum quisquam eius sed odit
                    fugiat iusto fuga praesentium optio, eaque rerum! Provident
                    similique accusantium nemo autem.
                  </p>
                  <div className="research__card__bottom">
                    <span style={{ color: "blue", fontWeight: "bolder" }}>
                      Published by: Alison De Best
                    </span>
                    <button className="button__research">Read More</button>
                  </div>
                </div>
              </div>
              <div className="research__card">
                <img src={research} alt="research" />
                <div className="research__card__text">
                  <p>
                    laborum numquam blanditiis harum quisquam eius sed odit
                    fugiat iusto fuga praesentium optio, eaque rerum! Provident
                    similique accusantium nemo autem.
                  </p>
                  <div className="research__card__bottom">
                    <span style={{ color: "blue", fontWeight: "bolder" }}>
                      Published by: Alison De Best
                    </span>
                    <button className="button__research">Read More</button>
                  </div>
                </div>
              </div>
              <div className="research__card">
                <img src={research} alt="research" />
                <div className="research__card__text">
                  <p>
                    laborum numquam blanditiis harum quisquam eius sed odit
                    fugiat iusto fuga praesentium optio, eaque rerum! Provident
                    similique accusantium nemo autem.
                  </p>
                  <div className="research__card__bottom">
                    <span style={{ color: "blue", fontWeight: "bolder" }}>
                      Published by: Alison De Best
                    </span>
                    <button className="button__research">Read More</button>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Research;
