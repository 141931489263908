import YouTube from "react-youtube";
import React from "react";

function YoutubePlayer (){
  const videoOnReady=(event)=>{
    // access to player in all event handlers via event.target
    event.target.playVideo();
  }
   
    const opts = {
      height: "438",
      width: "702",
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
      },
    };

    return <YouTube videoId="KcWwV_VQuTc" opts={opts} onReady={videoOnReady} className="video" />;
  
}
export default YoutubePlayer;
