import React, { Component } from "react";

import TestimonialStyle2 from "./TestimonialStyle2";

import bg1 from "./../../../images/background/reinvently_insights_3.0.gif";

class ShortTestimonial extends Component {
  render() {
    return (
      <>
        <div className="page-content bg-white">
          {/* <!-- inner page banner --> */}

          {/* <!-- inner page banner END --> */}
          <div className="content-block">
            {/* <!-- Clients Words --> */}
            <div
              className="section-full overlay-primary-middle content-inner bg-img-fix"
              style={{ backgroundImage: "url(" + bg1 + ")" }}
            >
              <div className="container">
                <div className="max-w700 m-auto text-center m-b30 text-white">
                  <h3 className="m-t0">
                    What The Young to Young students are saying
                  </h3>
                  <h6 className="m-t0">
                    Our courses and community have helped 1000+ of The Young to
                    Young students go from zero to getting hired to levelling up
                    their skills and advancing their careers to new heights.
                  </h6>
                </div>
                <div className="section-content m-b30">
                  <TestimonialStyle2 />
                </div>
              </div>
            </div>
            {/* <!-- Clients Words END --> */}
          </div>
          {/* <!-- contact area END --> */}
        </div>
      </>
    );
  }
}
export default ShortTestimonial;
