import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";

const defaultAccordion = [
  {
    title: "1.Why should you consider joining us?",
    text: "The young to young program is for people who are enthusiastic about expanding their knowledge and self-confidence. There will be a variety of activities to enjoy enjoyable sessions and networking with international delegates from all over the world. We allow you to interact with great minds while mixing with international delegates from all over the globe. It is an ideal time for you to prepare for any overseas chances that may arise.",
  },
  {
    title: "2.Is this paid?",
    text: "Yes, it is paid. Few money for a lot of fun.",
  },
  {
    title: "3. Will I be given a diploma?",
    text: "Yes! A publication will be sent to you. Participate and earn an e-certificate E-certificate.",
  },
  {
    title: "4.Can you publish my research papers on your website?",
    text: "Yes! For a limited time, we will display your articles on our website, and they will be published in worldwide scientific journals.",
  },
  {
    title: "5. How can I contact you?",
    text: "You can contact us by whatsapp or email.",
  },
  {
    title: "6. How do you become a volunter?",
    text: "You can volunteer for free and choose your type of volunteer work if you have leadership experience.",
  },
];
const AccordionBlog = () => {
  const [activeDefault, setActiveDefault] = useState(0);
  return (
    <Accordion
      className="accordion dlab-accordion faq-1 box-sort-in m-b30"
      defaultActiveKey="-1"
    >
      {defaultAccordion.map((d, i) => (
        <div className="panel">
          <div className="acod-head">
            <h6 className="acod-title">
              <Accordion.Toggle
                as={Link}
                variant="link"
                className={` ${activeDefault === i ? "" : "collapsed"}`}
                onClick={() => setActiveDefault(activeDefault === i ? -1 : i)}
                eventKey={`${i}`}
              >
                {d.title}
              </Accordion.Toggle>
            </h6>
          </div>

          <Accordion.Collapse eventKey={`${i}`} className="acod-body">
            <div className="acod-content">{d.text}</div>
          </Accordion.Collapse>
        </div>
      ))}
    </Accordion>
  );
};
export { defaultAccordion };
export default AccordionBlog;
