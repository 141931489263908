import React from "react";
import "./CleanUp.css";
import { Link } from "react-router-dom";
function CleanUp() {
  return (
    <div className="cleanUp ">
      <div className="cleanup_pbutton">
        <div className="cleanUp_p">
          <p className=" text-center font-40 text-white" >
            Social Project <br />
            The Young To young
          </p>
        </div>
        <Link to={"./read-more-social-program"}>
          <button className="redbutton ">Read More</button>
        </Link>
      </div>
    </div>
  );
}

export default CleanUp;
