import React from "react";
import "./index.css";
import Footer4 from "../../../Layout/footer4";
import Header3 from "../../../Layout/header3";
import { useSelector } from "react-redux";
import loading from "./loading.gif";
// import axios from "axios";

function CompusAbasador() {
  const courses = useSelector((state) => state.courses);

  // const [data, setData] = useState([]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const result = await axios("https://young2young.herokuapp.com/news");

  //     setData(result.data);
  //   };

  //   fetchData();
  // }, []);

  // <img src={loading} alt="Loading"/>

  return (
    <>
      <Header3 />
    
      <div className="compus__ambasador">
        <h1 className="text-center">Our Compus Ambassadors</h1>
      

  
        <div className="ambasador__compus">
          {/* card  */}
          {courses.map((item, index) => (
            <div key={index} className="compus__ambasador__details">
              <img
                className="ambasador__pic"
                src={item.profilePicture}
                alt=""
              />
              <div className="ambasador__detail__contact">
                <h3>Name: {item.fullName}</h3>
                <p style={{color:"black"}}>
                  Country: {item.country}{" "} City: {item.city}
                </p>
              </div>
            </div>
          ))}
        </div>

      </div>
      <Footer4 />
    </>
  );
}

export default CompusAbasador;
