import React from "react";
import "./index.css"
function Science(props) {
  return (
    <div className="science_x">
      <span>🚀</span>
      <p>{props.p}</p>
    </div>
  );
}

export default Science;
