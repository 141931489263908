import React, { useState } from "react";
import { useDispatch } from "react-redux";
import FileBase from "react-file-base64";

import Footer4 from "../../Layout/footer4";
import Header3 from "../../Layout/header3";
import { createCourse } from "./../../../actions/courses";
const initialState = {
  fullName: "",
  country: "",
  email: "",
  qualification: "",
  school: "",
  city: "",
  phone: "",
  feeback: "",
  profilePicture: "",
};
function AmbassadorForm() {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(initialState);
  console.log(formData);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [termAndCondition, setTermAndCondition] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createCourse(formData));
    setShowSuccessMessage(true);
    setTimeout(() => {
      setShowSuccessMessage(false);
    }, 9000);

    clear();
  };
  const clear = () => {
    setFormData({
      fullName: "",
      country: "",
      email: "",
      qualification: "",
      phone: "",
      school: "",
      feeback: "",
      profilePicture: "",
      city: "",
    });
  };
  return (
    <>
      <Header3 />
      <div style={{ padding: "10px 0" }}>
        <div className="container">
          <form onSubmit={handleSubmit}>
            <p className="text-center">
              This form must be filled out completely by the student, and we are
              delighted to have you on board.
            </p>

            <div className="form-group">
              <label for="name">Full Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                aria-describedby="lastnameHelp"
                placeholder="Full Name"
                name="fullName"
                value={formData.fullName}
                onChange={(e) =>
                  setFormData({ ...formData, fullName: e.target.value })
                }
                required
              />
            </div>

            <div className="form-group">
              <label for="name">Country</label>
              <input
                type="text"
                className="form-control"
                id="name"
                aria-describedby="emailHelp"
                placeholder="Country where the organization is operating"
                name="country"
                value={formData.country}
                onChange={(e) =>
                  setFormData({ ...formData, country: e.target.value })
                }
                required
              />
            </div>
            <div className="form-group">
              <label for="name">City</label>
              <input
                type="text"
                className="form-control"
                id="name"
                aria-describedby="emailHelp"
                placeholder="Current city"
                name="city"
                value={formData.city}
                onChange={(e) =>
                  setFormData({ ...formData, city: e.target.value })
                }
                required
              />
            </div>

            <div className="form-group">
              <label for="name">School / Institution</label>
              <input
                type="text"
                className="form-control"
                id="name"
                aria-describedby="emailHelp"
                placeholder="Name of the school/University/Institution"
                name="school"
                value={formData.school}
                onChange={(e) =>
                  setFormData({ ...formData, school: e.target.value })
                }
                required
              />
            </div>

            <div className="form-group" style={{ border: "3px solid white" }}>
              <label for="name">Profile Picture</label>
              <FileBase
                component="div"
                className="form-control "
                type="file"
                multiple={false}
                onDone={({ base64 }) =>
                  setFormData({ ...formData, profilePicture: base64 })
                }
                required
              />
            </div>

            <div className="form-group">
              <label for="name">Phone Number/WhatsApp</label>
              <input
                type="text"
                className="form-control"
                id="name"
                aria-describedby="lastnameHelp"
                placeholder="Your whatsap number with country code e.g (+212690300054)"
                name="phone"
                value={formData.phone}
                onChange={(e) =>
                  setFormData({ ...formData, phone: e.target.value })
                }
                required
              />
            </div>

            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <label className="input-group-text" for="inputGroupSelect01">
                  Qualifications
                </label>
              </div>
              <select
                className="custom-select"
                id="inputGroupSelect01"
                required
                type="text"
                name="qualification"
                value={formData.qualification}
                onChange={(e) =>
                  setFormData({ ...formData, qualification: e.target.value })
                }
              >
                <option selected>Choose...</option>
                <option value="College">College</option>
                <option value="High School">High School</option>
                <option value="University">University</option>

                <option value="Other">Other</option>
              </select>
            </div>

            <div className="form-group">
              <label for="exampleInputEmail1">Email address</label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter email"
                name="email"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                required
              />
              <small id="emailHelp" className="form-text text-muted" required>
                We'll never share your email with anyone else.
              </small>
            </div>

            <div className="form-group">
              <label for="exampleInputPassword1">Tell us about yourself</label>

              <textarea
                className="form-control"
                id="txtid"
                rows="5"
                cols="50"
                maxlength="200"
                placeholder="Tell us about yourself.............."
                required
                type="text"
                name="feeback"
                value={formData.feeback}
                onChange={(e) =>
                  setFormData({ ...formData, feeback: e.target.value })
                }
              ></textarea>
            </div>

            <div className="form-group form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                required
              />
              <label className="form-check-label" for="exampleCheck1">
                Terms & Conditions
              </label>
              <button
                className="btn btn-sm btn-primary"
                onClick={(e) => setTermAndCondition(true)}
              >
                Read
              </button>
              {termAndCondition ? (
                <>
                  <small
                    id="emailHelp"
                    className="form-text text-muted"
                    required
                  >
                    1. Your information is displayed on our website and social
                    networking
                  </small>
                  <small
                    id="emailHelp"
                    className="form-text text-muted"
                    required
                  >
                    2. Your profile picture will be displayed on our website.
                  </small>
                  <small
                    id="emailHelp"
                    className="form-text text-muted"
                    required
                  >
                    3. If you have provided us with any misleading information,
                    you are accountable.
                  </small>
                </>
              ) : null}
            </div>

            {showSuccessMessage && (
              <>
                <div class="alert alert-success" role="alert">
                  <h3 class="alert-heading">
                    Thank you for signing up for our program.
                  </h3>
                  <p>
                    Dear Ambassador, I'm writing to thank you for registering
                    for the Young to Young Program. We will send you a reminder
                    before the event. Please contact me if you have any
                    questions. The event will bring together creative minds from
                    all over the world.
                  </p>
                  <hr />
                  <p class="mb-0">
                    Thank you once more, and have a wonderful rest of your day.
                    Alison from the Y2Y Administration.
                  </p>
                </div>
              </>
            )}
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
      </div>
      <Footer4 />
    </>
  );
}

export default AmbassadorForm;
