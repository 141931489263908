 
import React, { Component } from "react";
 
// import Header from "./../Layout/header3";
// import Footer from "./../Layout/footer4";
import pic1 from "./../../images/our-team/IMG_0109_filtered-removebg-preview.png";
import pic2 from "./../../images/our-team/swati.png";
import pic3 from "./../../images/our-team/WhatsApp Image 2022-05-04 at 8.02.53 AM.jpeg";
import pic4 from "./../../images/our-team/kauko.png";
import rashmi from "./../../images/our-team/rashmi.png";
import safina from "./../../images/our-team/safina.png";
import lucie from "./../../images/our-team/lucie.png";
import rasak from "./../../images/our-team/rasak.png";

const teamBlog = [
  {
    images: pic3,
    name: "Lingaraj Bhixavarthimath",
    title: "Chief Executive Officer",
    facebook: "https://www.facebook.com",
    linkedin: " https://www.linkedin.com/in/lingaraj-bmath-15b009168",
    twitter: "https://twitter.com/",
  },
  {
    images: pic4,
    name: "Loteleni Kauko Shikulo ",
    title: "General manager",
    facebook: "https://www.facebook.com/loteleni.shikulo.5",
    linkedin: "https://www.linkedin.com/in/loteleni-shikulo-177922182/",
    twitter: "https://twitter.com/k_loteleni",
  },
  {
    images: pic1,
    name: "Alison Magid Juma",
    title: "Chief Technical Officier",
    facebook: "https://www.facebook.com",
    linkedin: "https://www.linkedin.com/in/alison-m-juma-3256011a2/",
    twitter: "https://twitter.com/",
  },

  {
    images: pic2,
    name: "Jean Grey Carpio ",
    title: "Regional Head Director of the Philippines",
    facebook: "https://www.facebook.com",
    linkedin: "https://www.linkedin.com",
    twitter: "https://twitter.com/",

  },
  {
    images: rashmi,
    name: "Rashmi Baral ",
    title: " Regional Head Director of the Nepal",
    facebook: "https://www.facebook.com/profile.php?id=100082910763582",
    linkedin: "https://www.linkedin.com/in/rashmi-baral-6b905b212/",
    twitter: "https://twitter.com/",
    instagram: "https://www.instagram.com/p/CjxPuAnry64/?igshid=MDJmNzVkMjY%3D",
  },
  {
    images: rasak,
    name: "Akorede Rasak ",
    title: "Regional Head Director of the Nigeria",
    facebook: "https://www.facebook.com",
    linkedin: "https://www.linkedin.com",
    twitter: "https://twitter.com/",
  },
  {
    images: safina,
    name: "Safinaxon ",
    title: "Regional Head Director of the Uzbekistan",
    facebook: "https://www.facebook.com",
    linkedin: "https://www.linkedin.com",
    twitter: "https://twitter.com/",
  },
  
  // {
  //   images: "lucie",
  //   name: "Anju Texa USA ",
  //   title: "Regional head of Texa-USA",
  //   facebook: "https://www.facebook.com",
  //   linkedin: "https://www.linkedin.com",
  //   twitter: "https://twitter.com/",
  // },
];

class Team extends Component {
  componentDidMount() {}
  render() {
    return (
      <>
        <div className="page-content bg-white">
          {/* <!-- inner page banner --> */}

          <div className="content-block">
            {/* <!-- Team Section --> */}
            <div className="section-full text-center bg-white content-inner-1">
              <div className="container">
                <div className="section-head text-black text-center">
                  <h2>Our Team</h2>
                  <p>Meet Our Most Loyal Employees, the driving force behind this organization.</p>
                </div>
                <div className="row">
                  {teamBlog.map((data, index) => (
                    <div
                      className="col-lg-3 col-md-6 col-sm-6 m-b5"
                      key={index}
                    >
                      <div className="dlab-box">
                        <div className="dlab-media dlab-img-overlay6 dlab-img-effect radius-sm">
                          <img src={data.images} alt="" />
                          <div className="overlay-bx">
                            <div className="overlay-icon">
                              <ul className="dlab-social-icon">
                                <li>
                                  <a
                                    href={data.facebook}
                                    target="bank"
                                    className="fa text-white fa-facebook"
                                  ></a>
                                </li>
                                <li>
                                   
                                  <a
                                    href={data.twitter}
                                    target="bank"
                                    className="fa text-white fa-twitter"
                                  ></a>
                                </li>
                                <li>
                                  <a
                                    href={data.linkedin}
                                    target="bank"
                                    className="fa text-white fa-linkedin"
                                  ></a>
                                </li>
                                <li>
                                  <a
                                    href={data.instagram}
                                    target="bank"
                                    className="fa text-white fa-instagram"
                                  ></a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="dlab-title-bx p-a10">
                          <h5 className="text-black m-a0">{data.name}</h5>
                          <span className="clearfix">{data.title}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* <!-- Team Section END --> */}
          </div>
          {/* <!-- contact area END --> */}
        </div>
      </>
    );
  }
}
export default Team;
