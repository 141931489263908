import React from "react";
import "./Elearning.css";
import Image1 from "./image-1.jpg";
import Media from "./Media-Partner-PC.jpg";

import AccordionBlog from './Accordation'
import Footer4 from '../../../Layout/footer4'
import Header3 from '../../../Layout/header3'

function ELearning() {
  return (
    <>
    <Header3 />
      <div className="elearning">
        <h2 className="text-center text-white">
          One Step Closer to Your Dream with IGN Global MUN Community!
        </h2>
        <button>I want to join this community</button>
      </div>
      <div className="elearning__overview">
        <h1 className="text-center">OVERVIEW</h1>
        <p>
          Model United Nations is more than just an extracurricular activity.
          Model United Nations is a place for us, for young leaders who are
          striving for knowledge to improve ourselves.
        </p>
        <p>
          We have our own goals, but we have the same purpose. That’s why we are
          a family, we are one community.
        </p>
      </div>
      <div className="elearning__about">
        <p>
          International Global Network is going to provide a platform for you to
          gather with young leaders from all over the world You will have the
          opportunity to share information and ideas, build fruitful networks,
          and grow to be better together with the others.
        </p>
      </div>
      <div className="elearning__benefit">
        <h2 className="text-center">
          Let us introduce you to IGN Global MUN Community!
        </h2>
        <div className="elearning__benefit__item">
          <img
            className="elearning__benefit__left__image"
            src={Image1}
            alt="elearning"
          />

          <div className="elearning__benefit__right">
            <h4>
              IGN Global MUN Community is a special Facebook group where you can
              develop yourself with enthusiastic young leaders from around the
              world. In this group, you will get:
            </h4>

            <p>
              ✅ Virtual gathering with all members. There will be sharing,
              brainstorming, and gaming sessions. We are sure that the gathering
              will be super fun!
            </p>
            <p>
              ✅ Three weekly articles about MUN, self-development tips, and
              international news. Curated carefully by IGN!
            </p>
            <p>
              ✅ Sharing sessions with experts. You will get the chance to talk
              with experts of self-development activities, including MUN!
            </p>
            <p>
              ✅ Entertainment sessions! Compete yourself with other young
              leaders with our special quizzes!
            </p>
            <p>
              ✅ Complete guidance to MUN from Yoga Mahardika! Mahardika is a
              diplomat who paved his career from a MUN. You will get the
              pro-tips about MUN from him!
            </p>
            <p>
              ✅ Special benefits to join IGN’s MUN Conferences. Psst, there
              will be discounts and benefits if you join this community before
              you participate in one of our MUN conferences!
            </p>
          </div>
        </div>
      </div>

      {/* our secteraite */}
      <div className="elearning__ecretariats">
        <h2 className="text-center">Our Secretariat</h2>
        <div className="elearning__empty_div text-center"></div>

        <div className="elearning__flex">
          <div className="elearning__card">
            <img src={Image1} alt="secteriats" />
            <h4>Victor Arribas Martinez</h4>
            <h5 style={{ color: "red" }}>Nairobi</h5>
          </div>
          <div className="elearning__card">
            <img src={Image1} alt="secteriats" />
            <h4>Victor Arribas Martinez</h4>
            <h5 style={{ color: "red" }}>Nairobi</h5>
          </div>
          <div className="elearning__card">
            <img src={Image1} alt="secteriats" />
            <h4>Victor Arribas Martinez</h4>
            <h5 style={{ color: "red" }}>Nairobi</h5>
          </div>
          <div className="elearning__card">
            <img src={Image1} alt="secteriats" />
            <h4>Victor Arribas Martinez</h4>
            <h5 style={{ color: "red" }}>Nairobi</h5>
          </div>
          <div className="elearning__card">
            <img src={Image1} alt="secteriats" />
            <h4>Victor Arribas Martinez</h4>
            <h5 style={{ color: "red" }}>Nairobi</h5>
          </div>
          <div className="elearning__card">
            <img src={Image1} alt="secteriats" />
            <h4>Victor Arribas Martinez</h4>
            <h5 style={{ color: "red" }}>Nairobi</h5>
          </div>
          <div className="elearning__card">
            <img src={Image1} alt="secteriats" />
            <h4>Victor Arribas Martinez</h4>
            <h5 style={{ color: "red" }}>Nairobi</h5>
          </div>
          <div className="elearning__card">
            <img src={Image1} alt="secteriats" />
            <h4>Victor Arribas Martinez</h4>
            <h5 style={{ color: "red" }}>Nairobi</h5>
          </div>
        </div>
      </div>
      {/* join now */}
      <div className="elearning__join">
        <p>
          Get a LIFETIME access to IGN Global MUN Community and enjoy all of the
          BENEFITS with <span style={{ fullWidth: "bolder" }}>ONLY $10!</span>{" "}
        </p>
      </div>
      {/* join now */}
      <div className="elearning__join__now">
        <p>
          If you still have any doubt about MUN, here are some reviews from some
          delegates who have participated in MUN conferences
        </p>
      </div>
      {/* testimonials */}
      <div className="elearning__testimonials">
        <div className="elearning__testimonials__cards">
          <img
            className="testimonials__images"
            src={Image1}
            alt="testimonials"
          />
          <h4>Adolvo Alfarez</h4>
          <h5 style={{ color: "red" }}>UNITED STATES</h5>
          <div className="testimonial__empty__div"></div>
          <p>
            “It was really amazing and organized. My best experience in my
            entire life, and it was my ninth MUN, I really enjoyed it. The chair
            was so good and the tech support during the sessions was the same.”
          </p>
        </div>
        <div className="elearning__testimonials__cards">
          <img
            className="testimonials__images"
            src={Image1}
            alt="testimonials"
          />
          <h4>Adolvo Alfarez</h4>
          <h5 style={{ color: "red" }}>UNITED STATES</h5>
          <div className="testimonial__empty__div"></div>
          <p>
            “It was really amazing and organized. My best experience in my
            entire life, and it was my ninth MUN, I really enjoyed it. The chair
            was so good and the tech support during the sessions was the same.”
          </p>
        </div>{" "}
        <div className="elearning__testimonials__cards">
          <img
            className="testimonials__images"
            src={Image1}
            alt="testimonials"
          />
          <h4>Adolvo Alfarez</h4>
          <h5 style={{ color: "red" }}>UNITED STATES</h5>
          <div className="testimonial__empty__div"></div>
          <p>
            “It was really amazing and organized. My best experience in my
            entire life, and it was my ninth MUN, I really enjoyed it. The chair
            was so good and the tech support during the sessions was the same.”
          </p>
        </div>
        <div className="elearning__testimonials__cards">
          <img
            className="testimonials__images"
            src={Image1}
            alt="testimonials"
          />
          <h4>Adolvo Alfarez</h4>
          <h5 style={{ color: "red" }}>SOUTH AFRICA</h5>
          <div className="testimonial__empty__div"></div>
          <p>
            “It was really amazing and organized. My best experience in my
            entire life, and it was my ninth MUN, I really enjoyed it. The chair
            was so good and the tech support during the sessions was the same.”
          </p>
        </div>
      </div>
      {/* noted */}
      <div className="elearning__noted">
        <h2 className="text-center text-red text-bold">NOTED</h2>
        <p className="text-center">
          10% of the total income will be donated to the whole world, especially
          countries that need donations through social organizations who
          effected by covid 19, will be managed by youth from MUN community in
          these countries
        </p>
      </div>
      {/* elearning media partner */}
      <div className="elearning__asking__image">
        <h1 className="text-center" style={{backgroundColor:"#FFFFFF"}}>
          And here are some of our partner that we has worked with us
        </h1>
        <img className="center" src={Media} alt="media__partner" />
      </div>
      {/* frequence asking question */}
      <div className="elearning__asking__question">
        <h1 className="text-center">Frequent Asked Questions</h1>
        <AccordionBlog />
      </div>
      {/* connect with us */}
      <div className="connect__withus">
        <p>Young's to  Young e-Learning</p>
        <div className="connect__empty__div"></div>
        <p>Connect with us</p>
        <div className="social__media__co">
        <i class="social__media__connect ti-facebook"></i>
        <i class="social__media__connect ti-twitter-alt"></i>
        <i class="social__media__connect ti-instagram"></i> 
        <i class="social__media__connect ti-email" href="https://www.google.com/" target="bank"></i>
   
        </div>
      </div>
      <Footer4 />
    </>
  );
}

export default ELearning;
