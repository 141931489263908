import React from "react";
import "./Ambassador.css";
import Image1 from "./image-1.jpg";
import Footer4 from "../../../Layout/footer4";
import Header3 from "../../../Layout/header3";
function Ambassador() {
  return (
    <>
      <Header3 />
      <div className="ambassador">
        <h1 className="text-center text-white">AMBASSADORS</h1>
      </div>

      <div className="ambassador__about">
        <h1 className="text-center">
          We Are Getting Bigger. We Need More Youth.
        </h1>
        <p className="text-center text-paragraph">
          Hello, Youth Leaders, we are the International Global Network (IGN).
          We believe that every person deserves the opportunity to learn with
          global exposure in this advanced world. We create opportunities for
          everyone so they can grow and be motivated to be the solution for
          global issues.
        </p>
      </div>
      {/* ambassador text paragraph */}
      <div className="ambassador__details">
        <div className="ambassador__text">
          <p className="text-red">
            Have you ever joined Model United Nation? Some of great MUN events
            are crafted in our working space: AYIMUN (Asia Youth International
            MUN), AWMUN (Asia World International MUN), GGMUN (Global Goals
            MUN), etc.
          </p>
          <p className="text-red">
            With the mission of “Connecting youth leaders to the world”, we
            always strive to innovate various programs as strategic platforms
            for youth to develop, explore, and create changes to make the world
            a better place. More events will be crafted in the future, so we
            need more youth leaders like you to be an IGN Ambassador.
          </p>
          <button className="ambassador__button">Apply Now</button>
        </div>

        <div className="ambassador__picture">
          <img src={Image1} alt="pic_here" />
        </div>
      </div>

      <div className="ambassador__details">
        <div className="ambassador__text">
          <h1 className="text-red text-bold">
            IGN Ambassador: Dare Yourself to be Part of Something Huge
          </h1>
          <p className="text-red">
            If you are highly interested in overseas experience and are prepared
            to dedicate yourself to take part in a remarkable conference, you
            are the right person for the position! Being part of the IGN
            Ambassador is an astounding opportunity for gain learning and skills
            development and we encourage undergraduate, graduate and/or young
            professionals from around the world to apply.
          </p>
          <p className="text-red">
            All IGN Ambassadors position will be contracted for one year as our
            local public relation resources for pre-event, and committee during
            the day of event. All IGN Ambassadors require a commitment during
            the time frame prior to the conference, a full time commitment
            during the conference itself, and a commitment after the events and
            other concluding tasks. Only those candidates who are short-listed
            will be invited to continue with the interviewing process.
          </p>
          <button className="ambassador__button">Apply Now</button>
        </div>
        <div className="ambassador__picture">
          <img className="ambassador__pic" src={Image1} alt="pic_here" />
        </div>
      </div>
      {/* ambassador text paragraph end */}

      {/* ambassador description */}
      <div className="ambassador__descriptions">
        <div className="ambassador__intro">
          <h3 className="text-white">
            Benefits You <br />
            Can't Resist
          </h3>
        </div>

        <div className="ambassador__colomun">
          <div className="ambassador__card">
            <img height={100} width={50} src={Image1} alt="pic_here" />
            <div className="details_amber">
              <h2>Networking</h2>
              <p>
                Amazing excellent International experiences and networking for
                you
              </p>
            </div>
          </div>
          <div className="ambassador__card">
            <img height={100} width={50} src={Image1} alt="pic_here" />
            <div className="details_amber">
              <h2>Amazing Incentives</h2>
              <p>Opportunity to Get Perks/Incentives from IGN (T&C Apply).</p>
            </div>
          </div>
          <div className="ambassador__card">
            <img height={100} width={50} src={Image1} alt="pic_here" />
            <div className="details_amber">
              <h2>Official Ambassador</h2>
              <p>
                You will be Official IGN Ambassador and have right to use
                international ID Card.
              </p>
            </div>
          </div>
          <div className="ambassador__card">
            <img height={100} width={50} src={Image1} alt="pic_here" />
            <div className="details_amber">
              <h2>Traveling</h2>
              <p>
                Free Accommodation during Event (Meals 3x and hotel. Exclude
                Flight).
              </p>
            </div>
          </div>
          <div className="ambassador__card">
            <img height={100} width={50} src={Image1} alt="pic_here" />
            <div className="details_amber">
              <h2>International Events</h2>
              <p>
                Participate as Committee at International Events (Max. 2 Events,
                T&C Apply)
              </p>
            </div>
          </div>
          <div className="ambassador__card">
            <img height={100} width={50} src={Image1} alt="pic_here" />
            <div className="details_amber">
              <h2>Global knowledges</h2>
              <p>
                You will also get T-Shaped skills & knowledge of a lean company.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* ambassador description end */}
      {/* ambassador requirement start */}
      <div className="ambassador__required">
        <div className="ambassador__requirement">
          <h1 className="text-red">Requirements</h1>
          <h4>📌 What you should have to join IGN</h4>
          <p className="ambassador__padding__requirement">
            💿 Completed or currently pursuing a university degree (eg. BA,
            B.Ed., BSc., JD, MA, MPP, MBA, etc.)
          </p>
          <p className="ambassador__padding__requirement">
            💿 Excellent interpersonal skills
          </p>
          <p className="ambassador__padding__requirement">
            💿 Quick and enthusiastic learner
          </p>
          <p className="ambassador__padding__requirement">
            💿 Responds well under pressure with strict time limit
          </p>
          <p className="ambassador__padding__requirement">
            💿 Excellent written and verbal communication skills, proficiency in
            English
          </p>
          <p className="ambassador__padding__requirement">
            💿 Demonstrated ability to work independently and collaboratively
          </p>
          <p className="ambassador__padding__requirement">
            💿 Deadline-oriented with great follow-up and reporting skills
            Preferable alumnus of at least one of IGN programs. Here’s the list
            for
          </p>
          <p className="ambassador__padding__requirement">
            💿 IGN Programs so far :
          </p>
          <p className="ambassador__padding__requirement_next__line">
            💿 AYIMUN (Asia Youth International MUN)
          </p>
          <p className="ambassador__padding__requirement_next__line">
            💿 AWMUN (Asia World MUN)
          </p>
          <p className="ambassador__padding__requirement_next__line">
            💿 GGMUN (Global Goals MUN)
          </p>
          <p className="ambassador__padding__requirement_next__line">
            💿 (Korea International Education and Culture)
          </p>
          <p className="ambassador__padding__requirement_next__line">
            💿 Asia Student Summit
          </p>
          <p className="ambassador__padding__requirement_next__line">
            💿 Youtex (Youth Excursion)
          </p>
          <p className="ambassador__padding__requirement_next__line">
            💿 Sasambo Greeneration Camp
          </p>
          <p className="ambassador__padding__requirement_next__line">
            💿 Leaderpreneur Summit.
          </p>
        </div>
        <div className="ambassador__meaningful__job">
          <h1 className="text-red">Meaningful Job Desc</h1>
          <h4>📌 How you will make a better world</h4>
          <p className="ambassador__padding__requirement">
            💿 Writing and producing press release and other documents needed
          </p>
          <p className="ambassador__padding__requirement">
            💿 Interpreting press release and other documents needed into local
            language
          </p>
          <p className="ambassador__padding__requirement">
            💿 Dealing with enquiries from local press, local NGO, and local
            related organization; establishing and maintaining the relationship
          </p>
          <p className="ambassador__padding__requirement">
            💿 Build and maintain a positive public image for International
            Global Network
          </p>
          <p className="ambassador__padding__requirement">
            💿 Actively promote IGN event in the local environment
          </p>
        </div>
      </div>
      {/* ambassador description end */}

      {/* ambassador final page start */}
      <div className="ambassador__final__page">
        <h1 className="text-center text-white"> Get excited to join?</h1>
        <div className="ambassador__final__inside">
          <div className="ambassador__final__page__1">
            <p>Send Your Submission Now</p>
            <p>BEFORE 31 July 2019 (11:59 PM GMT+7)</p>
            <p>Submit Your Cover Letter To :</p>
            <p>youngtoyoung@gmail.com</p>
            <p>With Email Subject:</p>
          </div>
          <div className="ambassador__final__page__2">
            <p>Don't Forget To Include This Items:</p>
            <ul>
              <li>Close Up Photo (In Best Formal Suit)</li>
              <li>Motivation Letter</li>
              <li>Curriculum Vitae</li>
            </ul>
          </div>
        </div>
        <div className="final__page__button">
          <buuton className=" xbutton">APPLY ONLINE</buuton>
          <buuton className=" xbutton">GET MOTIVATIONAL LATER</buuton>
        </div>
      </div>
      {/* ambassador final page end */}
      <Footer4 />
    </>
  );
}

export default Ambassador;
