import React, { useState, useEffect } from "react";
import { trendingP } from "./data";
import { Link } from "react-router-dom";
import "./Trending.css";
import { client } from "../../../utils/sanityClient";
import moment from "moment";
 
function TrendingCards() {
  const [trendingData, setTrendingData] = useState([]);
  console.log(trendingData);

  useEffect(() => {
    const query = `*[_type=="payment"]{
      category,slug,speaker,title,_id,virtual,time, amount,date,image{
        asset->{url}
      },  description
    }
      `;

    client.fetch(query).then((data) => {
      setTrendingData(data);
    });
  }, []);

  return (
    <div className="cardsx">
      {trendingData.map((item) => (
        <div key={item._id} className="cardx">
          <img className="card__image" src={item?.image?.asset?.url} alt={item?.title} />
          <div className="card__details">
            <h6>
              {item?.category}: {item?.title}
            </h6>

            <h6>Speaker: {item?.speaker}</h6>
            <h6>Date: {moment(item?.time).fromNow()}</h6> 
            <p className="dexcription text-white"> 
              {item?.description.substring(0, 60)}...
              <Link className="linkRoute" to={`/programDetail/${item._id}`}>
                Read More
              </Link>
            </p>
          </div>

          <button className="register__button">Register Now</button>
        </div>
      ))}
    </div>
  );
}

export default TrendingCards;
