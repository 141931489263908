import React from "react";
import Footer from "../../../Layout/footer4";
import Header from "../../../Layout/header3";
import datas from "../data";
import "./CourseDetails.css";
// import { blue } from "@mui/material/colors";
// import Card from "./CourseCard.js";
// import { ListItem, ListItemAvatar } from "@mui/material";
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import { PersonPinCircleOutlined } from "@mui/icons-material";
// import { ListItemText } from "@material-ui/core";

function CourseDetails(props) {
  const course = datas.find((x) => x._id === props.match.params.id);

  return (
    <>
      <Header />

      <div className="courseDetails">
        <div className="course__overview">
          <h1 className="text-white">{course.title}</h1>
          <p>{course.courseDescription}</p>
          <span>Instructor: {course.instractor}</span>
        </div>
        <div className="course__fullpicture">
          <img src={course.image} alt="" />
        </div>
      </div>
      <div className="course__overview2">
        <h1 className="text-center">Course overview</h1>
        <p className="text-center">{course.overview}</p>
      </div>
      {/* what you will learn */}
      <div className="course__uwilllearn">
        <h1 className="text-center">What you will Learn</h1>
        <p>{course.whatYouwillLearn}</p>
        <h1 className="text-center">Requirements:</h1>

        <p className="text-center">{course.requirement}</p>
      </div>

      {/* <ListItem key={course._id}
             
          >
            <ListItemAvatar>
              <CheckCircleIcon sx={{ bgcolor: blue[100], color: blue[600] }}>
                <PersonPinCircleOutlined />
              </CheckCircleIcon>
            </ListItemAvatar>
            <ListItemText primary={course.requirement} />
          </ListItem> */}

      {/* don't take my words */}
      {/* <div className="donttake__ourwords">
        <h2 className="text-center">
          Don't just take our words see feedback from our students
        </h2>
        <p className="text-center">
          Our courses and community have helped 1,000s of Zero To Mastery
          students go from zero to getting hired to levelling up their skills
          and advancing their careers to new heights.
        </p>
      </div>
      <div className="card__testimon">
        <Card />
        
        
      </div> */}
      <Footer />
    </>
  );
}

export default CourseDetails;
