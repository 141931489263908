import robots from "./images/science.gif";
import climate from "./images/climate.gif";
import ai from "./images/ai.gif";
import mental from "./images/mental.gif";
import covid from "./images/covid.gif";

const science__and__tech = [
  {
    _id: "44f4f646g44gggd6q6",
    title: "Educational Robotics",
    image: robots,
    p: "is an interdisciplinary learning environment that uses robots and electronic components as the common thread to help children and young people develop skills and competencies. It focuses on STEAM disciplines, but it can also include other subjects like linguistics, geography, and history.",
    p1: "Todays world is experiencing faster technological advancement than ever before, and this trend is expected to continue. Adaptation and the development of new competencies and skills are critical in todays rapidly changing world.",
    p2: " All citizens, young and old, are affected by the advancement of digital technologies and digitalization. Mobile phones, computers, tablets, and other technologies that serve both social and entertainment purposes are now commonplace.",
    p3: "To engage the digital generation in the educational process, everyone must adapt to the changing world, learn, and develop their digital competencies. The confident and critical use of electronic media for work, leisure, and communication is referred to as digital competence",
  },
   
  {
    _id: "44f4f646g44gggd6q8",
    image: ai,
    title: "Artificial intelligence (AI)",
    p: "Artificial intelligence (AI) is a broad field of computer science concerned with developing intelligent machines that can perform tasks that would normally require human intelligence. Reactive Machines, Limited Memory, Theory of Mind, and Self-Awareness are the four types of AI, which are often referred to as a single term. Siri, Alexa, and other smart assistants, self-driving cars, robo-advisors, conversational bots, email spam filters, and Netflix recommendations are all examples of practical AI allocation.",
    question: "Why Talk on AI - in school?",

    p1: "A lot of research is being done on how Ai can be used in educational systems. Not in the sense that it facilitates personalized learning for each student. AI allows for the creation of a customized learning profile and tailor-made training material based on each student's capabilities, interests, and experience on an individual basis, thanks to the hyper-personalization concept enabled by machine learning.",
    p2: "Not only that, AI. Due to the limited number of teachers and costs associated, personalized one-to-one learning is not generally feasible from a societal point of view. Breakthroughs in the field of machine learning offer promising avenues to aid in personalized learning. AI may hence be the ‘holy grail’ in unlocking the potential of one-to-one learning, by enabling applications to offer personalized teaching to each individual student. ",
    p3: "We can expect Artificial Intelligence and machine learning to play a significant role in all educational experiences in the not-too-distant future. AI has begun to demonstrate its benefits and power in a variety of educational settings, and it remains to be seen how the technology will empower and improve overall learning outcomes for everyone.",
    p4: "We can expect Artificial Intelligence and machine learning to play a significant role in all educational experiences in the not-too-distant future. AI has begun to demonstrate its benefits and power in a variety of educational settings, and it remains to be seen how the technology will empower and improve overall learning outcomes for everyone.",
  },
   
  {
    image: covid,
    _id: "44f4f646g44gd6q14",
    title: "Corona Virus",
    p: "Coronaviruses are a group of viruses that infect both animals and humans and belong to the Coronaviridae family. Signs and symptoms include respiratory symptoms and include fever, cough, and shortness of breath. In more severe cases, the infection can cause pneumonia, severe acute respiratory syndrome, and sometimes death. Standard recommendations to prevent the spread of COVID-19 include frequent cleaning of hands using alcohol-based hand rub or soap and water; covering the nose and mouth with a flexed elbow or disposable tissue when coughing and sneezing; and avoiding close contact with anyone that has a fever and cough.",
    p1: "Young people have been disproportionately affected by the pandemic, compared to many other social groups. The pandemic's economic effects and the closure of educational institutions have already had a significant impact on the lives of young people. According to UNESCO, 194 countries had closed schools nationwide by mid-April 2020, affecting nearly 1.6 billion students.",
    p2: "There were still 105 nationwide closures in August 2020, affecting over a billion students. Many educators have worked hard to maintain student learning and well-being in this situation. ",
    p3: "Additionally, COVID-19 has also posed an immense psychosocial impact on young people. Disruption in education and economic opportunities, family stress, social isolation, risk of domestic abuse, and uncertainty about the future have led to reduced well-being of young people globally.",
    p4: "Digital technologies have the potential to open up new doors and provide new ways of learning. They have the power to influence what people learn, how they learn, where and when they learn, and, most importantly, how teachers and students interact. However, the COVID-19 crisis occurred at a time when most educational systems were unprepared to take advantage of digital technology's potential. The nature, scope, and success of these efforts differ by country and economy, but digital technologies have emerged as a critical requirement for success.",
    p5: " “The end of the pandemic may now be in sight, at least in some parts of the world. But its effects on young people will last well beyond it.” ",
    p6:"By 2030, 130 million people may join the ranks of those living in extreme poverty, dealing a significant blow to global efforts to eradicate extreme poverty and hunger. ",
    p7:"However, the pandemic could lead to a new normal, fundamentally altering human interactions, interdependence, trade, and globalization while hastening digitalization and automation.",
    p8:"More than three-quarters of the 128 million vaccine doses administered so far have been administered in just ten countries, which account for 60% of global GDP.",
    p9:"As of today, nearly 130 countries with a combined population of 2.5 billion people have yet to administer a single dose.",
    p10:"As COVID-19 demonstrated, we require global leadership to scale up vaccine production and achieve vaccine equity. Whether we win or lose, we will do so together."
  },
];
export default science__and__tech;
