import React from "react";
import "./webdevelopment.css";
import { Link } from "react-router-dom";
import Footer4 from "../../../Layout/footer4";
import Header3 from "../../../Layout/header3";
import TestimonialCarousel from "../../../Pages/ShortCode/YoungtoyoungTestimonial.js";
import Bulletpoint from "./Bulletpoint";
import BulletPointCancel from "./BulletPointCancel";
import Typewriter from "./Typewritter";
import graphicData from "./graphicDesignData";

function GraphicDesign() {
  // const details = datas.find((x) => x._id === props.match.params.id);
  // console.log(details);

  return (
    <>
      <Header3 />
      <h1 className="text-center text__header">
        Are you Looking for online trainings we had the solution for you
      </h1>
      <p className="text-center text__p">
        The most efficient and supportive way for you to learn in-demand skills,
        get hired, and advance your career.
      </p>

      <div className="webdev">
        {graphicData?.map((x) => (
          <div key={x._id} className="coureName">
            <h1 style={{ textTransform: "uppercase" }}>{x.title}</h1>
            <img src={x.image} alt="courses" />
            <p>
              {x.courseDescription.substring(0, 100)} ...
              <Link to={`course-details-graphic/${x._id}`} className="readmore">
                Read More
              </Link>
            </p>
            <Link to={"/join-our-program"}>
              <button className="coureName__button">Join Now</button>
            </Link>
          </div>
        ))}
      </div>
      {/* <TestimonialCarousel /> */}
      <div className="powerfull__message">
        <div className="powerful__left">
          <h3 className="heading__h3">
            {" "}
            We can help you build your dream career, even if ...
          </h3>
          <p>
            💥 You're completely new to technology and have never written a line
            of code before!
          </p>
          <p>💥 You've struggled with coding in the past.</p>
          <p>💥 You've wondered "am I too old to start something new?"</p>
          <p>
            💥 You're completely overwhelmed and have no idea where to start.
          </p>
        </div>
        <div className="powerful__right__card">
          <h3 style={{ color: "#fff" }}>Sound like you?</h3>
          <p>
            If so, take our Tech Career Path Quiz now to get a personalized,
            step-by-step roadmap to achieving your dream career
          </p>
          <Link to={"#"}>
            <button className="button__powerfull">Creet my career path</button>
          </Link>
        </div>
      </div>

      {/* typewritter */}
      <div className="container text-center">
        <div style={{ paddingTop: "20px" }}>
          <h3>Learn to code</h3>
          <h3 style={{ color: "red" }}>
            <Typewriter />
          </h3>
        </div>
      </div>
      {/* who is this course for */}
      <h3 className="text-center text-green ">Who is this course for?</h3>
      <div className="powerfull__message ">
        <div
          className="powerful__left"
          style={{
            border: "2px solid green",
            borderRadius: "15px",
            padding: "10px",
            marginRight: "10px",
            marginBottom: "10px",
          }}
        >
          <h3 className="heading__h3">You're qualified if you're</h3>
          <Bulletpoint paragraph="You don't want to learn alone. You want to be part of a supportive community of like-minded individuals who work together and help each other learn new skills, advance their careers and want to continue to improve." />
          <Bulletpoint paragraph="You're struggling to make progress using free tutorials that aren't giving you the structure or clear path to achieving your goals." />
          <Bulletpoint paragraph="You feel stuck AND are sick of dreading Monday mornings BUT you're willing to put in the hard work to make a change." />
          <Bulletpoint paragraph="You can't or don't want to spend $8,000+ on a coding bootcamp but you're ready to take the next step in your career." />
        </div>
        <div
          className="powerful__right"
          style={{
            border: "2px solid red",
            borderRadius: "15px",
            padding: "10px",
          }}
        >
          <h3 className="heading__h3">You're disqualified if you're</h3>
          <BulletPointCancel paragraph="You are mostly looking for high-level theory and don't want to get your hands dirty building real projects and learning from your mistakes." />
          <BulletPointCancel paragraph="You're not ready to invest in yourself and are looking for the cheapest way to learn. Want to learn to code for free? Use our free Learn to Code & Get Hired guide." />
          <BulletPointCancel paragraph="You're all take and no give. We believe that helping and teaching others is one of the best and fastest ways to level-up your own skills." />
          <BulletPointCancel paragraph="You're still not sure if you even want to pursue a career in tech. If that's the case we recommend checking out this post, Should I Learn to Code?" />
        </div>
      </div>
      <div className="container text-center">
        <button className="join__nowButton text-center">Join Now!</button>
      </div>
      <Footer4 />
    </>
  );
}

export default GraphicDesign;
