import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import webdev from "./images/webdev.gif";
import graphic from "./images/graphic.gif";
import gen from "./images/gen.gif";
import Footer4 from "../../../Layout/footer4";
import Header3 from "../../../Layout/header3";
import { useDispatch } from "react-redux";
import { Avatar } from "@mui/material";
 
import CountTimer from "./count-down/CountTimer";
function CourseResource() {
  const dispatch = useDispatch();
  const location = useLocation();

  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));

  const history = useHistory();
  const logout = () => {
    dispatch({ type: "LOGOUT" });
    history.push("/");
    setUser(null);
  };

  useEffect(() => {
    const token = user?.token;
    // jwt for manual
    setUser(JSON.parse(localStorage.getItem("profile")));
  }, [location]);

  // if (user) {
  //   history.push("/webdev-resources");
  // } else {
  //   history.push("/");
  // }
  return (
    <>
      <Header3 />
      <div className="container pt-4">
        <div class="row items-center">
          <div class="col-9">
            <p>
              welcome to our course materials You can get resources from our
              store by selecting the category of the course in which you
              enrolled. You must login with your email address and password, but
              we do not currently restrict this.
            </p>
          </div>
          <div
            class="col-3 "
            style={{
              border: "2px solid gray",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Avatar sx={{ width: 70, height: 70 }} />
            <h4>Hello User! </h4>
            
            <button className="btn btn-lg btn-primary" onClick={logout}>
              Logout
            </button>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="card-group">
          {/* <p className="text-center pt-5 pl-2 pr-2">
          welcome to our course materials You can get resources from our store
          by selecting the category of the course in which you enrolled. You
          must login with your email address and password, but we do not
          currently restrict this.
        </p> */}

          <div className="card">
            <img className="card-img-top" src={webdev} alt="Cardimage cap" />
            <div className="card-body">
              <h5 className="card-title">Web Development</h5>
              <p className="card-text">
                Click the button to get access to all of our course resources.
                In the beginning, there are no restrictions on access, but after
                a few weeks, only registered members will have access.
              </p>
            </div>
            <div className="card-footer">
              <small className="text-muted">Last updated an hour ago </small>
              <Link
                to={{
                  pathname:
                    "https://drive.google.com/drive/folders/1FSXTIk7Ykd6KKBNZ7g4jItrK0vR3JqDy?usp=sharing",
                }}
                target="bank"
              >
                <small className="btn btn-lg btn-primary">Get Resource</small>
              </Link>
            </div>
          </div>
          <div className="card">
            <img className="card-img-top" src={graphic} alt="Cardimage cap" />
            <div className="card-body">
              <h5 className="card-title">Graphic Design</h5>
              <p className="card-text">
                Click the button to get access to all of our course resources.
                In the beginning, there are no restrictions on access, but after
                a few weeks, only registered members will have access.
              </p>
            </div>
            <div className="card-footer">
              <small className="text-muted">Last updated an hour ago </small>
              <Link
                to={{
                  pathname:
                    "https://drive.google.com/drive/folders/1tGOflPLJA1jOP1O0I2RIUlrLacDrBe7o?usp=sharing",
                }}
                target="bank"
              >
                <small className="btn btn-lg btn-primary">Get Resource</small>
              </Link>
            </div>
          </div>
          <div className="card">
            <img className="card-img-top" src={gen} alt="Cardimage cap" />
            <div className="card-body">
              <h5 className="card-title">General Program</h5>
              <p className="card-text">
                Click the button to get access to all of our course resources.
                In the beginning, there are no restrictions on access, but after
                a few weeks, only registered members will have access.
              </p>
            </div>
            <div className="card-footer">
              <small className="text-muted">Last updated an hour ago </small>
              <Link
                to={{
                  pathname: "www.youtube.com",
                }}
                target="bank"
              >
                <small className="btn btn-lg btn-primary">Get Resource</small>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer4 />
    </>
  );
}

export default CourseResource;
