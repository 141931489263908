import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Footer4 from "../../Layout/footer4";
import Header3 from "../../Layout/header3";
import DailBoxJoinForm from "./DailBoxJoinForm.js";
import gif from "./gif.gif";
import { createContact } from "./../../../actions/contacts";
import Toastify from "./advertisement/ReactToastify";
const initialState = {
  firstName: "",
  lastName: "",
  country: "",
  email: "",
  program: "",
  phone: "",
  feeback: "",
  englishLevel: "",
  school: "",
  gender: "",
  age: "",
};
function JoinForm() {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const [formData, setFormData] = useState(initialState);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const submitFormData = () => {
    // e.preventDefault();
    dispatch(createContact(formData));
    setShowSuccessMessage(true);
    setTimeout(() => {
      setShowSuccessMessage(false);
    }, 9000);

    clear();
  };
  const clear = () => {
    setFormData({
      firstName: "",
      lastName: "",
      country: "",
      email: "",
      program: "",
      phone: "",
      feeback: "",
      englishLevel: "",
      school: "",
      gender: "",
      age: "",
    });
  };
  return (
    <>
      <Header3 />
      <div style={{ padding: "10px 0" }}>
        <div className="container">
          <div className="row">
            <div
              className="card col col-lg-12 col-sm-12"
              style={{ width: "18rem" }}
            >
              <img src={gif} className="card-img-top" alt="..." />
              <div className="card-body">
                {/* <h5 className="card-title">Card title</h5> */}
                <p>
                  Please click the button below to see the price before filling
                  out the form
                </p>
                <DailBoxJoinForm />
                <Toastify />
              </div>
            </div>
          </div>

          <form onSubmit={(e)=>handleSubmit(submitFormData(e.preventDefault()))}>
            <p className="text-center">
              Before attempting to complete the Online Application Form, the
              candidate must have the following: A legitimate WhatsApp number,
              valid email address, and filling out the form correctly.
            </p>

            <div className="form-group">
              <label for="name">First Name</label>
              <input
                autoFocus
                type="text"
                className="form-control"
                id="name"
                required
                aria-describedby="emailHelp"
                placeholder="First Name (given name)"
                name="firstName"
                {...register("name", {
                  required: "Please enter name!",
                  minLength: {
                    value: 3,
                    message: "name is more than 5 characters",
                  },
                })}
                value={formData.firstName}
                onChange={(e) =>
                  setFormData({ ...formData, firstName: e.target.value })
                }
              />
              {errors.name && (
                <div className="text-red">{errors.name.message}</div>
              )}
            </div>
            <div className="form-group">
              <label for="name">Last Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                required
                aria-describedby="lastnameHelp"
                placeholder="Last Name (family name)"
                name="lastName"
                {...register("lastName", {
                  required: "Please enter Last Name!",
                  minLength: {
                    value: 2,
                    message: "name is more than 5 characters",
                  },
                })}
                value={formData.lastName}
                onChange={(e) =>
                  setFormData({ ...formData, lastName: e.target.value })
                }
              />
              {errors.lastName && (
                <div className="text-red">{errors.lastName.message}</div>
              )}
            </div>

            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <label className="input-group-text" for="inputGroupSelect01">
                  Gender
                </label>
              </div>
              <select
                className="custom-select"
                id="inputGroupSelect01"
                required
                type="text"
                name="gender"
                value={formData.gender}
                onChange={(e) =>
                  setFormData({ ...formData, gender: e.target.value })
                }
              >
                <option selected>Choose...</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
            </div>

            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <label className="input-group-text" for="inputGroupSelect01">
                  English Level
                </label>
              </div>
              <select
                className="custom-select"
                id="inputGroupSelect01"
                required
                type="text"
                name="englishLevel"
                value={formData.englishLevel}
                onChange={(e) =>
                  setFormData({ ...formData, englishLevel: e.target.value })
                }
              >
                <option selected>Choose...</option>
                <option value="Good">Good</option>
                <option value="Very Good">Very Good</option>
                <option value="Fair">Fair</option>
                <option value="Excellent">Excellent</option>
              </select>
            </div>

            <div className="form-group">
              <label for="country">Country</label>
              <input
                type="text"
                className="form-control"
                id="country"
                required
                aria-describedby="lastnameHelp"
                placeholder="Your Country "
                name="country"
                {...register("country", {
                  required: "Please enter your Valid country!",
                })}
                value={formData.country}
                onChange={(e) =>
                  setFormData({ ...formData, country: e.target.value })
                }
              />
              {errors.country && (
                <div className="text-red">{errors.country.message}</div>
              )}
            </div>
            <div className="form-group">
              <label for="school">School/University/Institution</label>
              <input
                type="text"
                className="form-control"
                id="school"
                aria-describedby="lastnameHelp"
                required
                placeholder="Name of the school"
                name="school"
                {...register("school", {
                  required: "Please enter Valid school name or institution!",
                  minLength: {
                    value: 2,
                    message: "School/Institution must be more than 5 characters.",
                  },
                })}
                value={formData.school}
                onChange={(e) =>
                  setFormData({ ...formData, school: e.target.value })
                }
              />
              {errors.school && (
                <div className="text-red">{errors.school.message}</div>
              )}
            </div>

            <div className="form-group">
              <label for="phone">Phone Number/WhatsApp</label>
              <input
                type="text"
                className="form-control"
                id="phone"
                aria-describedby="lastnameHelp" required
                placeholder="Your whatsap number with country code e.g (+212690300054)"
                name="phone"
                {...register("phone", {
                  required: "Please enter valid Number starting with +",
                  
                })}
                value={formData.phone}
                onChange={(e) =>
                  setFormData({ ...formData, phone: e.target.value })
                }
              />
              {errors.phone && (
                <div className="text-red">{errors.phone.message}</div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="email">Email address</label>
              <input
                type="email"
                className="form-control"
                aria-describedby="emailHelp"
                placeholder="Enter email"
                required
                name="email"
                id="email"
                {...register("email", {
                  required: "Please enter Valid Email!",
                  pattern: {
                    value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/i,
                    message: "Please enter valid Email",
                  },
                })}
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
              />
              {errors.email && (
                <div className="text-red">{errors.email.message}</div>
              )}
              <small id="emailHelp" className="form-text text-muted" required>
                We'll never share your email with anyone else.
              </small>
            </div>

            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <label className="input-group-text" for="inputGroupSelect01">
                  Programes
                </label>
              </div>
              <select
                className="custom-select"
                id="inputGroupSelect01"
                required
                type="text"
                name="program"
                value={formData.program}
                onChange={(e) =>
                  setFormData({ ...formData, program: e.target.value })
                }
              >
                <option selected>Choose...</option>
                <option value="Mental Health">Mental Health</option>
                <option value="webinar">webinar</option>
                <option value="WorkShop">WorkShop</option>
                <option value="Research">Research</option>
                <option value="Talk on Science & Tech">
                  Talk on Science & Tech
                </option>
                <option value="Ambassador">Ambassador</option>
                <option value="Social Program">Social Program</option>
                <option value="Web Development">Web Development</option>
                <option value="Graphic Design">Graphic Design</option>
              </select>
            </div>

            <div className="form-group">
              <label for="exampleInputPassword1">Why you should join us?</label>

              <textarea
                className="form-control"
                id="txtid"
                rows="5"
                cols="50"
                maxlength="200"
                placeholder=" Why you should join us?"
                required
                type="text"
                name="feeback"
                value={formData.feeback}
                onChange={(e) =>
                  setFormData({ ...formData, feeback: e.target.value })
                }
              ></textarea>
            </div>

            <div className="form-group form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                required
              />
              <label className="form-check-label" for="exampleCheck1">
                Agreed
              </label>
            </div>
            {showSuccessMessage && (
              <>
                <div className="alert alert-success" role="alert">
                  <h3 className="alert-heading">
                    Thank you for registering to our Program
                  </h3>
                  <p>
                    Dear Delegate, I am reaching out to thank you for
                    registering to the young to Young Program. We will send you
                    a reminder before the event. Please feel free to get in
                    touch. The event is to gather creative mind across the
                    world.
                  </p>
                  <hr />
                  <p className="mb-0">
                    Thank you again, and have great rest of the day, Alison from
                    the Y2Y Admin.
                  </p>
                </div>
              </>
            )}
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
      </div>
      <Footer4 />
    </>
  );
}

export default JoinForm;
