import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "./../../Layout/header3";
import Footer5 from "./../../Layout/footer4";
import PageTitle from "./../../Layout/PageTitle";
import Sidebar from "./../../Element/Sidebar";
import bnr from "./../../../images/banner/bnr5.jpg";
import { createComment } from "./../../../actions/comment";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Avatar } from "@material-ui/core";
import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";

const initialState = { name: "", email: "", comment: "", website: "" };

function BlogDetails(props) {
  const comments = useSelector((state) => state.comments);

  const news = useSelector((state) => state.news);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(initialState);
  const post = news.find((x) => x._id === props.match.params.id);
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createComment(formData));
    clear();
  };
  const clear = () => {
    setFormData({
      name: "",
      email: "",
      comment: "",
      website: "",
    });
  };
  return (
    <>
      <Header />

      <div className="page-content bg-white">
        <div
          className="dlab-bnr-inr dlab-bnr-inr-sm overlay-primary bg-pt"
          style={{ backgroundImage: "url(" + bnr + ")" }}
        >
          <PageTitle motherMenu="Blog Details" activeMenu="Blog Details" />
        </div>
        <div className="content-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-xl-9">
                <div className="blog-post blog-single">
                  <div className="dlab-post-title ">
                    <h4 className="post-title m-t0">
                      <Link to={"#"}>{post.title1}</Link>
                    </h4>
                  </div>
                  <div className="dlab-post-meta m-b20">
                    <ul className="d-flex align-items-center">
                      <li className="post-date">
                        {" "}
                        <i className="fa fa-calendar"></i>
                        <strong>
                          {moment(post.createdAt).format("llll")}
                        </strong>{" "}
                      </li>
                      <li className="post-author">
                        <i className="fa fa-user"></i>By:
                        <Link to={"#"}>{post.author}</Link>{" "}
                      </li>
                      <li className="post-comment">
                        <i className="fa fa-comments"></i>
                        {/* <Link to={"#"}>0 Comments</Link> */}
                      </li>
                    </ul>
                  </div>
                  <div className="dlab-post-media dlab-img-effect zoom-slow">
                    <Link to={"#"}>
                      <img src={post.image} alt="" />
                    </Link>
                  </div>
                  <div className="dlab-post-text">
                    <p>{post.paragraph1}</p>
                    <p>{post.paragraph2}</p>
                    <blockquote>{post.paragraph3}</blockquote>
                  </div>
                  <div className="dlab-post-tags clear">
                    <div className="post-tags">
                      <Link to={"#"}>Child </Link>
                      <Link to={"#"}>Eduction </Link>
                      <Link to={"#"}>Money </Link>
                      <Link to={"#"}>Ambassadors </Link>
                    </div>
                  </div>
                  <div className="dlab-divider bg-gray-dark op4">
                    <i className="icon-dot c-square"></i>
                  </div>
                  <div className="share-details-btn">
                    <ul>
                      <li>
                        <h5 className="m-a0">Share Post</h5>
                      </li>

                      <li>
                        <FacebookShareButton
                          url={`https://www.theyoungtoyoung.com/blog-details/${post._id}`}
                        >
                          <Link
                            to={"#"}
                            className="site-button facebook button-sm"
                          >
                            <i className="fa fa-facebook"></i> Facebook
                          </Link>
                        </FacebookShareButton>
                      </li>

                      <li>
                      <EmailShareButton url={`https://www.theyoungtoyoung.com/blog-details/${post._id}`}>

                        <Link
                          to={"#"}
                          className="site-button google-plus button-sm"
                        >
                          <i className="fa fa-google-plus"></i> Google Plus
                        </Link>
                      </EmailShareButton>
                      </li>
                      <li>
                        <Link
                          to={"#"}
                          className="site-button linkedin button-sm"
                        >
                          <i className="fa fa-linkedin"></i> Linkedin
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={"#"}
                          className="site-button instagram button-sm"
                        >
                          <i className="fa fa-instagram"></i> Instagram
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={"#"}
                          className="site-button twitter button-sm"
                        >
                          <i className="fa fa-twitter"></i> Twitter
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={"#"}
                          className="site-button whatsapp button-sm"
                        >
                          <i className="fa fa-whatsapp"></i> Whatsapp
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clear" id="comment-list">
                  <div className="comments-area" id="comments">
                    <h2 className="comments-title">Comments</h2>
                    <div className="clearfix m-b20">
                      {/* ol here */}
                      <ol className="comment-list">
                        {comments
                          .slice(0)
                          .reverse()
                          .map((item) => (
                            <li key={item._id} className="comment">
                              <div className="comment-body">
                                <div className="comment-author vcard">
                                  <Avatar className="avatar photo" />
                                  <cite className="fn">{item.name}</cite>{" "}
                                  <span className="says">says:</span>
                                </div>
                                <div className="comment-meta">
                                  {" "}
                                  <Link to={"#"}>
                                    {moment(item.createdAt).fromNow()}
                                  </Link>{" "}
                                </div>
                                <p>{item.comment}</p>
                                <div className="reply">
                                  <a
                                    href="#form-comment"
                                    className="comment-reply-link"
                                  >
                                    Reply
                                  </a>
                                </div>
                              </div>
                            </li>
                          ))}
                      </ol>

                      {/* none ol */}
                      <div className="comment-respond" id="form-comment">
                        <h4 className="comment-reply-title" id="reply-title">
                          Leave a Reply
                          <small>
                            <Link
                              to={"#"}
                              className="d-none"
                              id="cancel-comment-reply-link"
                              rel="nofollow"
                            >
                              Cancel reply
                            </Link>
                          </small>
                        </h4>
                        <form
                          className="comment-form"
                          id="commentform"
                          onSubmit={handleSubmit}
                        >
                          <p className="comment-form-author">
                            <label for="author">
                              Name <span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              placeholder="Name"
                              id="author"
                              name="name"
                              value={formData.name}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  name: e.target.value,
                                })
                              }
                            />
                          </p>
                          <p className="comment-form-email">
                            <label for="email">
                              Email <span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              placeholder="Email"
                              id="email"
                              name="email"
                              value={formData.email}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  email: e.target.value,
                                })
                              }
                            />
                          </p>
                          <p className="comment-form-url">
                            <label for="url">Website</label>
                            <input
                              type="text"
                              placeholder="Website"
                              id="url"
                              name="website"
                              value={formData.website}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  website: e.target.value,
                                })
                              }
                            />
                          </p>
                          <p className="comment-form-comment">
                            <label for="comment">Comment</label>
                            <textarea
                              rows="8"
                              placeholder="Comment"
                              id="comment"
                              name="comment"
                              value={formData.comment}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  comment: e.target.value,
                                })
                              }
                            ></textarea>
                          </p>
                          <p className="form-submit">
                            <input
                              type="submit"
                              value="Post Comment"
                              className="submit"
                              id="submit"
                              name="submit"
                            />
                          </p>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-xl-3 sticky-top">
                <Sidebar />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer5 />
    </>
  );
}

export default BlogDetails;
