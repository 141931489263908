import React from "react";
import "./Freebook.css";
import country from "./ali-cv.png";
function Card() {
  return (
    <>
      {/* testimonial */}
      <div className="freebook__testimonial">
        <h2 className="text-center">
          See what some of our students are saying about Y2Y FreeBook
        </h2>
        <div className="testimonial__card">
          <img
            className="testimonial__imagecountry"
            src={country}
            alt="card__testimonial"
          />
          <div className="testimonial__card__detail">
            <h4>Alison De Best</h4>
            <div className="testimonial__star">
              <i class="social__media__connect-1 ti-star"></i>
              <i class="social__media__connect-1 ti-star"></i>
              <i class="social__media__connect-1 ti-star"></i>
              <i class="social__media__connect-1 ti-star"></i>
              <i class="social__media__connect-1 ti-star"></i>
            </div>
            <p style={{ color: "#9162FF" }}>Morocco</p>
            <p>
              The bottom line is that there is no way to understand the world
              better than to put yourself in the shoes of other countries. From
              the moment you set foot in a United Nations convention, you are a
              diplomat and you must represent your country's interests in the
              global community. If you want to make a change for the world, Y2Y
              is the perfect opportunity to participate, to become enthusiastic,
              and to understand the world around you. The skills you acquire in
              resolving conflict, public speaking, diplomacy and the
              relationships you build will be with you throughout your life.
            </p>
          </div>
        </div>
        <div className="testimonial__card">
          <img
            className="testimonial__imagecountry"
            src={country}
            alt="card__testimonial"
          />
          <div className="testimonial__card__detail">
            <h4>Alison De Best</h4>
            <div className="testimonial__star">
              <i class="social__media__connect-1 ti-star"></i>
              <i class="social__media__connect-1 ti-star"></i>
              <i class="social__media__connect-1 ti-star"></i>
              <i class="social__media__connect-1 ti-star"></i>
              <i class="social__media__connect-1 ti-star"></i>
            </div>
            <p style={{ color: "#9162FF" }}>Morocco</p>
            <p>
              I have been studied International Law in my current university
              with the will that I want to contribute to international peace and
              security in the future. However, I hesitated to take a step
              forward because of lack of my confidence and experiences.
              Recently, I am doing internship in the law firm in Bangkok with
              taking a year off of my university in Japan. Of course, this is a
              more challenging environment than ever for me, however, I think I
              need to take another step to chase my dream which is working for
              an international organization. Apply for MUN and becoming a part
              of Y2Y will be the most challenging thing in my life until now,
              however, I believe this experience is the one I should challenge.
            </p>
          </div>
        </div>
        <div className="testimonial__card">
          <img
            className="testimonial__imagecountry"
            src={country}
            alt="card__testimonial"
          />
          <div className="testimonial__card__detail">
            <h4>Alison De Best</h4>
            <div className="testimonial__star">
              <i class="social__media__connect-1 ti-star"></i>
              <i class="social__media__connect-1 ti-star"></i>
              <i class="social__media__connect-1 ti-star"></i>
              <i class="social__media__connect-1 ti-star"></i>
              <i class="social__media__connect-1 ti-star"></i>
            </div>
            <p style={{ color: "#9162FF" }}>Morocco</p>
            <p>
              I have been studied International Law in my current university
              with the will that I want to contribute to international peace and
              security in the future. However, I hesitated to take a step
              forward because of lack of my confidence and experiences.
              Recently, I am doing internship in the law firm in Bangkok with
              taking a year off of my university in Japan. Of course, this is a
              more challenging environment than ever for me, however, I think I
              need to take another step to chase my dream which is working for
              an international organization. Apply for MUN and becoming a part
              of Y2Y will be the most challenging thing in my life until now,
              however, I believe this experience is the one I should challenge.
            </p>
          </div>
        </div>
        <div className="testimonial__card">
          <img
            className="testimonial__imagecountry"
            src={country}
            alt="card__testimonial"
          />
          <div className="testimonial__card__detail">
            <h4>Alison De Best</h4>
            <div className="testimonial__star">
              <i class="social__media__connect-1 ti-star"></i>
              <i class="social__media__connect-1 ti-star"></i>
              <i class="social__media__connect-1 ti-star"></i>
              <i class="social__media__connect-1 ti-star"></i>
              <i class="social__media__connect-1 ti-star"></i>
            </div>
            <p style={{ color: "#9162FF" }}>Morocco</p>
            <p>
              I have been studied International Law in my current university
              with the will that I want to contribute to international peace and
              security in the future. However, I hesitated to take a step
              forward because of lack of my confidence and experiences.
              Recently, I am doing internship in the law firm in Bangkok with
              taking a year off of my university in Japan. Of course, this is a
              more challenging environment than ever for me, however, I think I
              need to take another step to chase my dream which is working for
              an international organization. Apply for MUN and becoming a part
              of Y2Y will be the most challenging thing in my life until now,
              however, I believe this experience is the one I should challenge.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Card;
