import React from 'react'
import image  from "./image-1.jpg"
 
import './EventOrganizer.css'
import PortfolioCarousel from '../../../Element/PortfolioCarousel'
import Footer4 from '../../../Layout/footer4'
import Header3 from '../../../Layout/header3'
function EventOrganizer() {
  return (
      <>
      <Header3 />
    <div className="event__organizer">
    <div className="event__organizer__content" >
    <h1 className="text-white">INTERNATIONAL GLOBAL NETWORK</h1>
    <p>Jadikan Event dan Program Anda Berkesan Hingga Jadi Buah Pembicaraan</p>
    <p>42 event terlaksana | 133 negara berpartisipasi</p>
    <button className="button" type="submit">Pelajeri landjut</button>
    </div>
    </div>

    <>
    <h2 className="text-center " style={{ paddingTop:"25px"}}>Ini Karya Kami</h2>
    <PortfolioCarousel />
    </>


<div className="event__organizer-about">
<div className="event-organiser-right">

<div className="event__organizer-image">
<img  className="event__organizer_image" src={image} alt="ali" />
<img  className="event__organizer_image" src={image} alt="ali" />
</div>

<div  className="event__organizer__ontop">

<img  className="event__organizer_image" src={image} alt="ali" />
</div>

</div>

<div className="event-organiser-left">
<h2>Apakah Penting Membuat Event yang Berkesan Untuk Perusahaan Anda?</h2>
<p>Rasa-rasanya, semua orang yang menginginkan citra baik perusahaan pasti menginginkan kualitas terbaik pada tiap acara dan program yang dibuat. Untuk hal itu, kami sepakat dengan Anda karena setiap orang yang hadir pada acara dan program yang Anda buat layak mendapatkan yang terbaik.</p>
<p>Bayangkan, jika event dan program yang Anda buat benar benar berkesan dan memuaskan untuk mereka, Anda dan perusahaan akan menjadi buah bibir di mana mana.</p>
<p>Dalam 5 tahun terakhir, kami membuat beragam event untuk orang-orang dari banyak negara. Mayoritas acara yang kami buat didesain untuk pemuda yang punya potensi untuk menjadi pemimpin di masa depan.</p>
<p>Dunia MICE ini merupakan hal yang sangat kami sukai karena sejauh ini 90% peserta dari setiap acara yang kami buat puas dan punya kesan positif. Terlebih sebagian besar dari mereka berlanjut menjadi perpanjangan tangan dari value yang kami bawa pada setiap acara. Tak sedikit juga yang diliput oleh media lokal di negara masing masing.</p>
</div>
</div>

    <div className="event-organiser-section-3">
    <h1 className="text-center">Kami Siap Bekerjasama Membuat Event yang Luar Biasa</h1>
   <p className="text-center">Bagi kami kolaborasi adalah hal penting dalam menghasilkan acara yang luar biasa. Anda punya tujuan dan keinginan yang hebat sedangkan kami punya talent yang berpengalaman untuk mewujudkannya. 5 tahun perjalanan dalam membuat beragam event internasional membuat kami selalu menyertakan elemen penting ini dalam setiap acara:</p>
   <div className="event__organizer-section-programme">
<div className="event__organizer-display-flex">
<div className="event__organizer-section-program-object">
Avatar
<p>Kreativitas</p>
</div>
<div className="event__organizer-section-program-object">
Avatar
<p>Kreativitas</p>
</div>
<div className="event__organizer-section-program-object">
Avatar
<p>Kreativitas</p>
</div>
</div>
<div className="event__organizer-display-flex">
<div className="event__organizer-section-program-object">
Avatar
<p>
Kru Profesional</p>
</div>
<div className="event__organizer-section-program-object">
Avatar
<p>
Detail Terhadap Hal Sekecil Apapun</p>
</div>
<div className="event__organizer-section-program-object">
Avatar
<p>
Layanan Prima untuk Seluruh Stake Holder</p>
</div>
</div>
   
   </div> 
   </div>
    <div className="event__organizer-whatsapp">
    <p>Buat event yang berkesan dan berdampak bersama kami. Bersama, kita jadikan mereka yang hadir bangga terhadap Anda dan Perusahaan.
    </p>
    <p>Klik tombol di bawah untuk terhubung dengan manajemen kami.</p>
    <button className="button" type="submit">Saya Tertarik</button>
    
    <p>INDONESIA OFFICE:
    Level 23, Penthouse Plaza Marein, Jl. Jend. Sudirman No.Kav. 76 – 78, RT.14/RW.1, Bend. Hilir, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 12910</p>
    </div>
    <Footer4 />
    </>)
}

export default EventOrganizer