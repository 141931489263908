import frontend from "./images/ff.gif";
import backend from "./images/cloud1.gif";
import backendx from "./images/ab286b43dad5d7d542199f4365821e3d.gif";
import mernstack from "./images/1_JsClH04ugmN_ZdDNZs1Rkw.gif";
import webdesigner from "./images/website.gif";
import coaching from "./images/Market-Research-Credible-Markets.gif";

const data = [
  {
    _id: "jdjd5sd5s65q65s5560",
    title: "Front-end Development",
    image: frontend,
    requirement:
      "Willingness to learn, vscode or Atom (Text Editor) Free, chrome or Firefox Developer Edition (Web Browser) Free and learn Front End Web Development from Scratch",
    courseDescription:
      "Become an expert React JS Developer. Learn HTML, CSS, JavaScript, ES6, React JS, jQuery and bootstrap",
    overview:
      "This course is a complete guide to become an expert front-end web developer. It contains all the tools and technologies that you should be proficient in work as a React Developer.We will go through the journey of learning HTML5, CSS3, Javascript, ES6, jQuery, and React JS.Each of these course modules has a project which you can work on.Along with the project problem statement video, you will also find the solution video for that project. It is advised to first try working on the project by yourself",
    whatYouwillLearn: [
      "Program websites with JavaScript, ",
      "Learn to style and add animations to bring those basic web pages to life using CSS3",
      "Learn the Javascript language from basic to advanced along with the Next Gen JS – ES6",
      "Learn to create responsive and interactive web pages using Javascript and jQuery",
      "Create user-friendly, beautiful, light and fast websites using React JS",
      "Become an expert front end developer using ReactJs",
      "Learn to create basic web pages using HTML5 ",
      "Build a Pipboy using Bootstrap and ",
      "Style web pages using CSS.",
    ],
    instractor: "Alison De Best",
  },
  {
    _id: "jdjd5sd5s65q65s5211",
    title: "Back-end Development",
    image: backend,
    courseDescription:
      "Boost your Node, Express, SQL, PostgreSQL and Backend Development Skills! NodeJS, Databases, Express, JS, ES6, + more!",
    overview:
      "Become an in demand software engineer by taking this course on Node, SQL, PostgreSQL, and backend web development. As one of the most popular web development stacks today, learning Node, SQL, and PostgreSQL is a must. Knowing these languages and frameworks will open doors and jobs for you, In this project-based course, you’ll learn how to build Node, SQL, and PostgreSQL applications by building three full projects. You’ll discover some techniques widely used in the industry today. And you’ll find out how to use notable libraries like Express and Pg, You will skyrocket to the top of the talent pool because you can build backend web applications. The backend is the heart of services and products. So knowing how to write the backend is the other highly crucial skill looked for by top software companies like Google, Facebook, Microsoft, and more.",
    whatYouwillLearn: [
      "Build full Node and PostgreSQL applications, ",
      "Apply various web requests to their custom backend applications, ",
      "Discuss the backend Web Development with fellow software engineers, ",
      "Use and build Node middleware, for error handling as well as parsing incoming data, ",
      "Extend Node applications with third-party libraries, Have an understanding of the Node event loop and Use advanced es6 concepts to write more concise code",
    ],
    requirement:
      "Having a basic understanding of JavaScript and/or NodeJS and Having basic knowledge of TypeScript is recommended, but not required",
    instractor: "Alison De Best",
  },
  {
    _id: "jdjd5sd5s65q65s4212",
    title: "Full-Stack Development",
    image: backendx,
    requirement: [
      "No programming experience needed. I'll teach you everything you need to know",
      "A computer with access to the internet",
      "No paid software required I'll walk you through step-by-step how to get all the software installed and set up",
    ],
    courseDescription:
      "Become a Full-Stack Web Developer with just ONE course. HTML, CSS, Javascript, Node, React, MongoDB, Web3 and DApps",
    overview:
      "Welcome to the Complete Web Development Bootcamp, the only course you need to learn to code and become a full-stack web developer. With 150,000+ ratings and a 4.8 average, my Web Development course is one of the most high rated course in our platform,At 65+ hours, this Web Development course is without a doubt the most comprehensive web development course available online. Even if you have zero programming experience, this course will take you from beginner to mastery. Here's why: The course has been updated to be 2022 ready and you'll be learning the latest tools and technologies used at large companies such as Apple, Google and Netflix, This course doesn't cut any corners, there are beautiful animated explanation videos and tens of real-world projects which you will get to build,The curriculum was developed over a period of four years, with comprehensive student testing, and many more",
    whatYouwillLearn: [
      "You will build 16 web development projects for your portfolio, ready to apply for junior developer jobs. You will master both front and back-end development, becoming a full-stack developer by the end of the course.Build fully-fledged websites and web apps for your startup or business. Master frontend development with React,Learn professional developer best practices,You will learn the latest technologies, including Javascript, React, Node and even Web3 development, After the course you will be able to build ANY website you want, Work as a freelance web developer, and Master backend development with Node",
    ],
    instractor: "Alison De Best",
  },
  {
    _id: "jdjd5sd5ss5s65s4213",
    title: "MERN-Stack Development",
    image: mernstack,
    requirement:
      "Good Grasp of Javascript, Solid Understanding of ES6, React Basics, and Node and Express Basics",
    overview:
      "What's up everybody, this is John from Coding Addict and welcome to my MERN Stack course . During the course we will build full stack application (Jobify) from scratch, using MongoDB, Express, React and Node.js",
    courseDescription:
      "Build Full Stack Application from Scratch with MongoDB, Express, React and NodeJS",
    whatYouwillLearn: [
      "Learn how to build big full stack app from scratch,",
      " Learn how to connect Front-End application (React) with Backend application (Express, MongoDB, NodeJS),",
      "Learn how to implement React Hooks, Async/Await, React Router 6, Axios ,",
      " Learn how to deploy MERN app on Heroku,",
      " Learn best practices of Front-End and Server applications",
      " Learn how to implement JWT for authentication and authorization ,",
      " and Learn how to implement ES6 in Node",
    ],
    instractor: "Jobster Gangé",
  },
  {
    _id: "jdjd5sd5rr5s65s4214",
    title: "web designer",
    image: webdesigner,
    overview:
      "Just updated with all modern Design tools and best practices for 2022! Join a live online community of over 600,000+ students and a course taught by industry experts that have actually worked both in Silicon Valley and Toronto for top companies. A great Designer is becoming harder and harder to find and it isn't rare to find designers make $160,000+ salaries now because it is such a valuable skill. We will teach you how to get there, Using the latest best practices in Web Design and Mobile Design as well as User Interface and User Experience Design (UI/UX), this course focuses on efficiently getting you from zero to a point where you can get hired or win freelance contracts. We will use in demand tools like Figma to show you a full workflow from start to finish. Graduates of Andrei’s courses are now working at Google, Tesla, Amazon, Apple, IBM, JP Morgan, Facebook, + other top tech companies, The course also includes 100+ assets and premium design templates that you can keep and use to customize for all your future projects. We guarantee you this is the most comprehensive online resource on Design skills.",
    courseDescription:
      "Complete Web & Mobile Designer in 2022: UI/UX, Figma, +more",
    whatYouwillLearn: [
      "Build beautifully designed web and mobile projects for your customers using modern tools used by top companies in 2022, ",
      " Includes 100+ assets and premium design templates that you can keep and use to customize for all your future projects",
      " Get hired as a Designer or become a freelancer that can work from anywhere and for anyone. Designers are in high demand!, ",
      " Master Figma for your design needs then learn to convert your designs into a live HTML an CSS website",
      " Learn to design for all types of devices using Figma and other tools used by some of the top designers in the world",
      " Master both Web and Mobile design principles and how to go from sketching to fully fledged high fidelity designs that will wow customers",
      " Learn to be a designer as well as a web developer at the same time (a rare combination of skills that is in high demand)!",
    ],
    requirement:
      "No requirements. You don't have to be creative or good with math. Those are Design and Development myths.",
    instractor: "Grace John D",
  },
  {
    _id: "jdjd5sd5rr5s65s42s5",
    title: "Coaching",
    image: coaching,
    overview:
      "This unparalleled Life Coaching Certificate Course includes an easy-to-understand curriculum designed to support the development of effective communication skills, wise perspectives, and the ways in which transformational changes can be made to people's beliefs, behaviors, attitudes, careers, and daily interactions. Perhaps you are considering life coaching training for the first time or are looking to update your existing skills by learning a psychology-based coaching approach. If you choose to take this course, you will learn a highly-actionable and comprehensive life coaching process that can serve as the foundation for a meaningful and rewarding career as a life coach. During the training, students will learn how to establish and articulate their personal or career goals, as well as demonstrate the ability to translate those goals into tangible outcomes. Identifying and solving client problems and identifying new growth opportunities require a clear understanding of their goals along with crystal clear actions to fulfill those goals",
    courseDescription:
      "A comprehensive online training that delivers in-depth understanding of the key elements of the Life Coaching profession",
    whatYouwillLearn: [
      "A time-proven life coaching methodology that’s been practiced on 2000+ people, ",
      "Develop an enhanced sense of social awareness and improve your people skills ",
      " Lead people into heightened states of innovation, creativity and personal effectiveness, ",
      "The art of making difficult decisions (or how to help others make difficult decisions) ",
      "Facilitate change by assisting individuals to break their cycles of ineffective behavior ",
      "Communication that establishes a sense of confidence, trust and rapport with others, ",
      "How to coach people in solving their problems and creating growth opportunities ",
    ],
    requirement:
      "No previous Life Coaching experience is required to benefit from this course. Students are encouraged to take notes (for self-reflection purposes only). Students can access this training from anywhere with an internet connection. An MP3 version is also accessible for those who like to learn 'on the go. Access your Life Coaching course from a tablet, smartphone or desktop. Additional PDF materials are provided to enhance your learning experience.",
    instractor: "Dima John J",
  },
];

export default data;
