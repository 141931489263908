import React, { useEffect, useState } from "react";
import "./Trending.css";
import { trendingP } from "./data";
import { useParams } from "react-router-dom";
// import Header3 from "./../Layout/header3";
// import Footer4 from "./../Layout/footer4";
import Header3 from "./../../Layout/header3";
import Footer4 from "./../../Layout/footer4";
import { client } from "../../../utils/sanityClient";
function TrendingDetails() {
  let { id } = useParams();
  const [trendingData, setTrendingData] = useState([]);
 

  useEffect(() => {
    const query = `*[_id=="${id}" ]{
      category,slug,speaker,title,_id,virtual,time, amount,date,image{
        asset->{url}
      },  description
    }
      `;

    client.fetch(query).then((data) => {
      setTrendingData(data);
    });
  }, [id]);
  const item = trendingP.find((item) => item._id === id);
  return (
    <>
<Header3 />
    <div className="trendingDetails">
    {trendingData.map((item)=>(
      <div className="tendDetailContent" key={item._id}>
        {" "}
        <div className="trendD__image">
          <img className="trendDetails__image" src={item?.image?.asset?.url} alt="" />
        </div>
        <div className="trendDContent">
          <h5>
            {item?.category}: {item?.title}
          </h5>
          <h5>Speaker: {item?.speaker}</h5>
          <h5>Date: {item?.time}</h5>
          <h5>Amount $:{item?.amount}</h5>
          <h5>Virtual: {item?.virtual}</h5>
          <p className="trendDp text-white">{item?.description}</p>
        </div>
      </div>
    ))}
      
      <div className="trendButtonmDetail">
        <button className="register__buttond">Register Now</button>
      </div>
    </div>
<Footer4 />
    </>
  );
}

export default TrendingDetails;
