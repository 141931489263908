import React from "react";

function Giphy() {
  return (
    <>
      <div
        style={{
          width: "100%",
          height: "0",
          paddingBottom: "100%",
          position: "relative",
        }}
      >
        <iframe
          src="https://giphy.com/embed/kHZu4LDtvpY63RT1He"
          width="100%"
          height="100%"
          style={{ position: "absolute" }}
          frameBorder="0"
          className="giphy-embed"
          allowFullScreen
        ></iframe>
      </div>
      <p>
        <a href="https://giphy.com/gifs/leroypatterson-kHZu4LDtvpY63RT1He">
           
        </a>
      </p>
    </>
  );
}

export default Giphy;
