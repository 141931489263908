import React, { Component } from "react";
import { Link } from "react-router-dom";

class HeaderMenu extends Component {
  render() {
    return (
      <>
        <ul className="nav navbar-nav">
          <li className="active">
            <Link to={"/"}>HOME</Link>
          </li>
          <li>
            <Link to={"./payment-methode"}>Payment Instruction</Link>
          </li>
          <li>
            <Link to={"./how-to-participate"}>How to Participate</Link>
          </li>

          <li>
            <Link to={"/login1"} className="dez-page">
              LOGIN
            </Link>
          </li>
        </ul>
      </>
    );
  }
}
export default HeaderMenu;
