import React, { Component } from "react";
import { Link } from "react-router-dom";

class HeaderMenu extends Component {
  render() {
    return (
      <>
        <ul className="nav navbar-nav">
          <li className="active">
            <Link to={"/"}>HOME</Link>
          </li>
          <li>
            <Link to={"/y2y-on-line"}>Y2Y ONLINE</Link>
          </li>

          <li>
            <Link to={"#"}>PROGRAMMES</Link>
            <span className="ti-angle-down" />
            <ul className="sub-menu left">
              <li>
                <Link className="dez-page">
                  The Y2Y
                  <span className="ti-angle-right" />
                </Link>
                <ul className="sub-menu right">
                  <li className="dez-page">
                    <Link to={"/y2y-work-shop"}>WORKSHOP</Link>
                  </li>
                  <li className="dez-page">
                    <Link to={"/y2y-webinar"}>Y2Y WEBINARS</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to={"/research"} className="dez-page">
                  RESEARCH{" "}
                </Link>
              </li>

              <li>
                <Link to={"/science-and-tech"} className="dez-page">
                  TALK ON SCIENCE & TECH{" "}
                </Link>
              </li>
              <li>
                <Link to={"/global-issue"} className="dez-page">
                  TALK ON GLOBAL ISSUES{" "}
                </Link>
              </li>
              <li>
                <Link className="dez-page">
                  E-LEARNING <span className="ti-angle-right" />
                </Link>

                <ul className="sub-menu right">
                  <li className="dez-page">
                    <Link to={"/web-developement"}>WEB DEVELOPMENT</Link>
                  </li>
                  <li className="dez-page">
                    <Link to={"/graphic-design"}>GRAPHIC DESIGN</Link>
                  </li>
                  <li className="dez-page">
                    <Link to={"/under-maintenance"}>CCNA-CISCO</Link>
                  </li>
                  <li className="dez-page">
                    <Link to={"/under-maintenance"}>DIGITAL MARKETING</Link>
                  </li>
                  <li>
                    <Link to={"/webdev-resources"} className="dez-page">
                      COURSE RESOURCES{" "}
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link className="dez-page">
                  SOCIAL PROGRAMS <span className="ti-angle-right" />
                </Link>

                <ul className="sub-menu right">
                  <li className="dez-page">
                    <Link to={"/read-more-social-program"}>
                      JOIN OUR PROGRAMS
                    </Link>
                  </li>
                  <li className="dez-page">
                    <Link to={"/ngo-join-form"}>WE JOIN YOUR PROGRAMS</Link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            {" "}
            <Link to={"/blog-classic-right-sidebar"}>BLOG</Link>
            <span className="ti-angle-down" />
            <ul className="sub-menu right">
              <li>
                <Link to={"/blog-classic-right-sidebar"} className="dez-page">
                  Y2Y MEDIA
                </Link>
              </li>
              <li>
                <Link to={"/blog-classic-right-sidebar"} className="dez-page">
                  ALUMNI STORY{" "}
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to={"#"} className="dez-page">
              ABOUT US
              <span className="ti-angle-down" />
            </Link>
            <ul className="sub-menu down">
              <li>
                <Link to={"/aboutus"} className="dez-page">
                  ABOUT
                </Link>
              </li>
              <li>
                <Link to={"/contactus"} className="dez-page">
                  OUR TEAM
                </Link>
              </li>
            </ul>
          </li>
          {/* <li>
            <Link to={"#"}>AMBASSADORS</Link>
            <span className="ti-angle-down" />
            <ul className="sub-menu">
              <li>
                <Link to={"/ambassadors-form"} className="dez-page">
                  REGISTRATION FORM{" "}
                </Link>
              </li>
              <li>
                <Link to={"/compus-ambasador"} className="dez-page">
                  COMPUS AMBASSADORS{" "}
                </Link>
              </li>

              <li>
                <Link to={"/school-ambasador"} className="dez-page">
                  SCHOOL AMBASSADORS{" "}
                </Link>
              </li>
              <li>
                <Link to={"/university-ambasador"} className="dez-page">
                  UNIVERSITY AMBASSADORS{" "}
                </Link>
              </li>
            </ul>
          </li> */}
          <li>
            <Link to={"/login1"} className="dez-page">
              LOGIN
            </Link>
          </li>
        </ul>
      </>
    );
  }
}
export default HeaderMenu;
