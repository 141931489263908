import React from "react";
import { Link } from "react-router-dom";
// import Card from "../science-and-tech/Card";
import { data } from "./data";
import "./index.css";
import Footer4 from "../../../Layout/footer4";
import Header3 from "../../../Layout/header3";
function Webnar() {
  return (
    <>
      <Header3 /><div className="free__join">
      <div className="webinar__y2y">
        {data.map((x) => (
          <div key={x._id} className="webinar__card">
            <img src={x.image} alt="webinar__cards" />
            <div className="action__button">
              <h5>{x.title}</h5>
              <p>
                {x.p.substring(0, 95)}...
                <Link to={`/science-and-tec/${x._id}`}>
                  <span
                    className="action__button__read__more"
                    style={{ color: "#025AA5" }}
                  >
                    Read More
                  </span>
                </Link>
              </p>
            </div>
            <Link to={"/join-our-program"}>
              <button className="btn-lg btn-primary button__webinar">
                Join Now
              </button>
            </Link>
          </div>
        ))}
      </div>
      </div>
      {/* <h5 style={{ border: "1.5px solid red" }} className="text-center">
        Explore More programs
      </h5>
      <div className="cards__science">
        <Card />
      </div> */}
      <Footer4 />
    </>
  );
}

export default Webnar;
