import * as React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { List, ListItem } from "@mui/material";
import Header3 from "./Header";
import logo from "./images/Untitled-1 copy.png";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function PaymentInstruction() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <>
      <Header3 />
      <div className="participate">
        <h1 className="text-center text-white">Payment Instruction</h1>
      </div>
      <Box sx={{ bgcolor: "background.paper", width: "100%" }}>
        <AppBar position="static">
          <Tabs
            style={{ backgroundColor: "red" }}
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Credit Card" {...a11yProps(0)} />
            <Tab label="Debit Card" {...a11yProps(1)} />
            <Tab label="Manual Direct Transfer" {...a11yProps(2)} />
            <Tab label="ATM/Bank Transfer" {...a11yProps(3)} />
            <Tab label="Paypal" {...a11yProps(3)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <List>
              <ListItem>1. Click "Register"</ListItem>
              <ListItem>
                2. Fill the Registration Form and click "SUBMIT"
              </ListItem>
              <ListItem>
                3. Fill the Billing Details, choose Credit Card (All Countries)
                & ATM/Bank Transfer (Indonesian Banks Only) and click "Place
                Order"
              </ListItem>
              <ListItem>
                4. Choose Credit Card as your payment method. The currency will
                be automatically converted to IDR. Don’t sweat about it, the
                amount will be the same as it is.
              </ListItem>
              <ListItem>
                5. Input your Card Number, Expiry Date, and CVV Code.
              </ListItem>
              <ListItem>6. Click “Pay Now”.</ListItem>
              <ListItem>
                7. You will be directed to your payment review. Click “Pay”;
                there will be a passcode sent to your number.
              </ListItem>
              <ListItem>
                8. Input the passcode to complete your payment!.
              </ListItem>
              <ListItem>
                9. You will receive an email stating your payment status.
              </ListItem>
            </List>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <ListItem>
              If you wished to make your payment using your Debit Card, you can
              follow the instruction for Credit Card as both have the same
              method. But, it is important for you to know whether your Bank
              allowed its card to be used in Online Payment or not, because if
              it is not allowed, your payment will be facing multiple failure
              attempts. We strongly recommend you to use other payment methods
              instead.
            </ListItem>
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <ListItem>1. Click "Register"</ListItem>
            <ListItem>
              2. Fill the Registration Form and click "SUBMIT"
            </ListItem>
            <ListItem>
              3. Fill the Billing Details, choose Manual Direct Transfer
              (USD/IDR Only) and click "Place Order"
            </ListItem>
            4. Send your payment to one of these accounts (choose based on your
            payment preferences):
            <ListItem>. USD: (Account USD)</ListItem>
            <ListItem>. IDR: (Account IDR)</ListItem>
            <ListItem>
              5. Do not forget to send your payment receipt, order ID, complete
              name, and email address to payment.young2young@gmail.com along
              with the subject: “MUN 101 PAYMENT DONE”.
            </ListItem>
          </TabPanel>
          <TabPanel value={value} index={3} dir={theme.direction}>
            <ListItem>1. Click "JOIN NOW"</ListItem>
            <ListItem>
              2. Fill the Registration Form and click "SUBMIT"
            </ListItem>
            <ListItem>
              3. Fill the Billing Details, choose Credit Card (All Countries) &
              ATM/Bank Transfer (Moroccan Banks Only) and click "Place Order"
            </ListItem>
            <ListItem>
              4. The currency will be automatically converted to IDR. Don’t
              sweat about it, the amount will be the same as it is.
            </ListItem>
            <ListItem>5. Choose one of your Bank preferences.</ListItem>
            <ListItem>
              6. You will be directed to your payment review. Click “Pay”; there
              will be a passcode sent to your number.
            </ListItem>
            <ListItem>
              7. Input the passcode when you are processing your payment in any
              ATM near you.
            </ListItem>
            <ListItem>
              8. You will receive an email stating your payment status.
            </ListItem>
          </TabPanel>
          <TabPanel value={value} index={4} dir={theme.direction}>
            <ListItem>1. Click "JOIN NOW"</ListItem>
            <ListItem>
              2. Fill the Registration Form and click "SUBMIT"
            </ListItem>
            <ListItem>
              3. Fill the Billing Details, choose PayPal and click "Place Order"
            </ListItem>
            <ListItem>
              4. Login with your PayPal account to proceed your payment.
            </ListItem>
            <ListItem>
              5. Choose “PayPal Balance (USD)”, then click “Continue”.
            </ListItem>
            <ListItem>
              6. Please wait while your payment is being processed. PayPal
              system will re-confirm your payment. We strongly recommend you to
              re-check your payment details before you click “Pay Now” button.
              If the information is set and correct, you may proceed your
              payment by clicking “Pay Now”.
            </ListItem>
            <ListItem component="div">
              7. Do not forget to send your payment receipt, order ID, complete
              name, and email address to{" "}
              <srong style={{ fontWeight: "bold !important" }}>
                payment@young2young@gmail.com
              </srong>{" "}
              along with the subject: Y2Y 101 PAYMENT DONE”. If you ask your
              relatives to pay on your behalf, you have to write down their
              details too!
            </ListItem>
          </TabPanel>
        </SwipeableViews>
      </Box>
      {/* webinarfooter */}
      <div className="webinar__final__work">
        <div className="webinar__footer">
          <div className="webinar_logo">
            <img src={logo} alt="footer" />
          </div>
          <div className="webinar__info p-10 text-center">
            <p>POWERED BY YOUNG TO YOUNG NETWORK INDIA</p>
            <p>+212698487054</p>
            <p>young2young.com</p>
          </div>
          <div className="social__media webinar__social__media">
            <i className="social__media ti-youtube m-r10" />
            <i className="social__media ti-instagram m-r10" />
            <i className="social__media ti-facebook m-r10" />
            <i className="social__media ti-themify-favicon-alt m-r10" />
          </div>
        </div>
      </div>
      {/* fixed div */}
      <div className="fixed__div__backgrnd">
        <div className="fixed__div">
          <div className="fixed__div__pay">
            <h2 className="">PAYMENT DEADLINE: </h2>
            <h2 className="">30 APRIL 2020</h2>
          </div>
          <div className="fixed__div__midile">
            <p className="p-b0 p-r5">Starts from </p>
            <p
              className="p-b0 p-r5"
              style={{ textDecoration: "line-through", color: "red" }}
            >
              {" "}
              USD 30
            </p>
            <p
              className="p-b0"
              style={{ fontWeight: "bolder", color: "black", fontSize: "2rem" }}
            >
              {" "}
              USD 10
            </p>
          </div>
          <button className="webinar__button">Join Now</button>
        </div>
      </div>
    </>
  );
}
