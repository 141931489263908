import innovation from "./image/innovation.png"
import techschool from "./image/techschool.jpg"
import webinar from "./image/webinar.jpeg"
import ai from "./image/ai.jpeg"
import layoff from "./image/layoff.png"
import education from "./image/education.png"
export const trendingP = [
  {
    category: "Webinar",
    _id: "32ds23ds2d2",
    image: webinar,
    title: "Dynamic landscape for techies ",
    speaker: "Rasak Akorede",
    time: "4pm(ISP)",
    virtual: "Zoom",
    date: "12th Febraury 2023",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    category: "Conference",
    _id: "32ds23ds2582",
    image:techschool,
    title: "Tech in School ",
    speaker: "Guest",
    time: "4pm(ISP)",
    virtual: "Zoom",
    date: "12th Febraury 2023",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  }, {
    category: "Webinar",
    _id: "32ds23ds2d2",
    image: ai,
    title: "The Future of AI ",
    speaker: "Alison De Best",
    time: "4pm(ISP)",
    virtual: "Zoom",
    date: "12th Febraury 2023",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    category: "Conference",
    _id: "32ds23ds2582df",
    image:education,
    title: "Education & School ",
    speaker: "Guest",
    time: "4pm(ISP)",
    virtual: "Zoom",
    date: "12th Febraury 2023",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },{
    category: "Research",
    _id: "32ds23ds2sfdsd2",
    image: layoff,
    title: "Layoff IT",
    speaker: "Guest",
    time: "4pm(ISP)",
    virtual: "Zoom",
    date: "12th Febraury 2023",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    category: "Research",
    _id: "32ds23dfsfs2582",
    image:innovation,
    title: "Innovation & Practice ",
    speaker: "Guest",
    time: "4pm(ISP)",
    virtual: "Zoom",
    date: "12th Febraury 2023",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
];
