import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header3 from "./../Layout/header3";
import Footer4 from "./../Layout/footer4";
import "./homepages.css";
// import services1 from "./../../images/our-services/pic1.jpg";
// import services2 from "./../../images/our-services/pic2.jpg";
// import ExploreCarousel from "./../Element/ExploreCarousel";
// import Index5Carousel from "./../Element/Index5Carousel";
// import ClientCarousel from "./../Element/ClientCarousel";
// import CountUp from "react-countup";
import bg17 from "./../../images/background/bg17.jpg";
// import bg3 from "./../../images/background/bg3.png";
// import about11 from "./../../images/about/pic11.png";
// import about13 from "./../../images/about/pic13.png";
// import about14 from "./../../images/about/pic14.PNG";
import aboutAnimated from "./../../images/about/SRM-connected-world.gif";
// import bg18 from "./../../images/background/bg18.jpg";
// import venu1 from "./../../images/about/venu-1.jpg";
// import venu2 from "./../../images/about/venu-2.jpg";

// import bnr2 from "./../../images/background/bg1.jpg";
// import TestimonialCarousel from "../Element/TestimonialCarousel";
// import PreviousPrograme from "../Element/PreviousProgram";
// import Join from "./Join";
// import JoinSoftSkill from "./JoinSoftSkill";

// import MasterSkill from "./MassterSkill";
// import Benefit from "./Benefit";
import Connect from "./Connect";
import Homepage from "./Homepage";
// import MediaCoverage from "../Element/MediaCoverage";
import MediaPartner from "../Element/MediaPartner";
import CoVenue from "../Element/CoVenue";
import CoHotel from "../Element/CoHotel";
import SupportedBy from "../Element/SupportedBy";
// import MunParthner from "../Element/MunParthner";
// import Alumni from "./newComponents/Alumni";
import "./newComponents/Alumni.css";
import CleanUp from "./newComponents/CleanUp";
import FirebaseData from "../../cms/firebase";
import ComingSoon from "./munfiles/coming-soon/ComingSoon";
import Aboutus1 from "./Aboutus/Aboutus1";
import TrendingProgram from "./trendingPrograms/TrendingProgram";
// import {GetData} from "./munfiles/GetData";

// import OnePlan from "./newComponents/OnePlan";

// const counterBlog = [
//   {
//     icon: <i className="ti-bag m-r10" />,
//     num: "15",
//     title: "Conferences",
//   },
//   {
//     icon: <i className="ti-user m-r10" />,
//     num: "20000",
//     title: " Registrants",
//   },
//   {
//     icon: <i className="flaticon-users m-r10" />,
//     num: "50",
//     title: "Countries",
//   },
//   {
//     icon: <i className="ti-mobile m-r10" />,
//     num: "200",
//     title: "Alumni",
//   },
// ];

class Index5 extends Component {
  componentDidMount() {
    var i = 0;

    // Placeholder Animation Start
    var inputSelector = document.querySelectorAll("input, textarea");

    for (i = 0; i < inputSelector.length; i++) {
      inputSelector[i].addEventListener("focus", function (event) {
        return this.parentElement.parentElement.classList.add("focused");
      });
    }

    for (i = 0; i < inputSelector.length; i++) {
      inputSelector[i].addEventListener("blur", function (event) {
        var inputValue = this.value;
        if (inputValue === "") {
          this.parentElement.parentElement.classList.remove("filled");
          this.parentElement.parentElement.classList.remove("focused");
        } else {
          this.parentElement.parentElement.classList.add("filled");
        }
      });
    }
  }

  render() {
    return (
      <>
        <Header3 />

        <div className="page-content bg-white rubik-font">
          <Homepage />
          <div style={{ marginTop: "0px" }} className="content-block">
            {/* <!-- About Us --> */}
            <div
              className="section-full industry-service"
              style={{ backgroundImage: "url(" + bg17 + ")" }}
            >
              <div className="container">
                <div className="row d-flex align-items-center">
                  <div className="col-lg-7 col-md-12">
                    <img src={aboutAnimated} className="radius-sm" alt="" />
                  </div>
                  <div className="col-lg-5 col-md-12 m-b30">
                    <h2 className="box-title m-t0 m-b20 font-40">
                      <span className="font-weight-400 text-center">
                        About Us
                      </span>
                    </h2>
                    <h4 className="m-b20" style={{ color: "#1D29A8" }}>
                      Why we are here?
                    </h4>
                    <p className="m-b20 about__us__text" style={{color:"black"}}>
                     The Young-to-Young aims to connect passionate delegates with talented mentors all over the world, form a mutual partnership with global youth leaders, and implement socially driven local government education programmes that teach youths how to make informed decisions about issues affecting their future. 

                    </p>
  <p className="m-b20 about__us__text" style={{color:"black"}}>
                  Its mission is to assist youths in becoming successful in the modern world by enrolling them in the necessary modern technology skill development that will help them become self-employed, making impossible things possible through the actualization of each youth's dreams. 
    
                    </p>
  <p className="m-b20 about__us__text" style={{color:"black"}}>
                      Furthermore, it aims to improve the world's future leaders by initiating success-guaranteed fearless steps towards development and assisting the young generation in becoming "research-oriented" to engineer innovative, sustainable decisions for the world's social, economic, and political governance.

                    </p>
                    <Link to={"/aboutus"}>
                      <button className="alumni_buttonred  button-md align-items-center ">
                        Read More
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Future Us End --> */}
{/* trending Program */}
<TrendingProgram />
{/* code for u */}
            <Aboutus1 /> 
            {/* <Alumni /> */}
            <CleanUp />
            {/* <OnePlan /> */}
            {/* <!-- afraid end here--> */}
            {/* 
            <div
              className="section-full content-inner overlay-primary bg-img-fix text-white"
              style={{ backgroundImage: "url(" + bnr2 + ")" }}
            >
              <div className="container">
                <div className="row">
                  {counterBlog.map((data, index) => (
                    <div className="col-lg-3 col-md-6 col-sm-6 p-a30">
                      <h2 className="m-t0 m-b10 font-weight-400 font-45">
                        {data.icon}
                        <span className="counter">
                          <CountUp end={data.num} duration={4} />
                        </span>
                        +
                      </h2>
                      <h4 className="font-weight-300 m-t0">{data.title}</h4>
                    </div>
                  ))}
                </div>
              </div>
            </div> */}

            {/* <!-- Why Chose Us End --> */}

            {/* <!-- Our Portfolio --> */}

            {/* <!-- Our Portfolio END --> */}

            {/* <div className="container">
              <p className="text-center font-40 p-t20">Our Partners</p>
              <h5>Companies partners</h5>
              <FirebaseData />
              <CoHotel />
            </div> */}
            <div className="container">
              <h5 style={{paddingTop:"20px"}}>Our Partners</h5>
              <CoVenue />
            </div>
            {/* <div className="container">
              <h5>Supported by schools/ universities</h5>
              <SupportedBy />
            </div> */}
            {/* <div className="container">
              <h5>MUN Partner</h5>
              <MunParthner />
            </div> */}
            {/* <div className="container">
              <h5>Media Partner</h5>
              <MediaPartner />
            </div> */}

            {/* <div className="container">
              <h5>Media Coverage</h5>
              <MediaCoverage />
            </div> */}
            {/* <!-- connect --> */}
            <div className="">{/* <ComingSoon /> */}</div>

            <Connect />
          </div>
          {/* <!-- connect END --> */}
        </div>

        <Footer4 />
      </>
    );
  }
}
export default Index5;
