import React from "react";
import "./BenefitCards.css";
import onlinewebinar from "./../images/benefit/onlinewebinar.gif";
import certificate from "./../images/benefit/certification.gif";
import networking from "./../images/benefit/networking.gif";
import teamMember from "./../images/benefit/teamMember.gif";
import tech from "./../images/benefit/image_processing20200511-23377-1ixcsgt.gif";
import leadership from "./../images/benefit/science and tech.gif";
import noSelection from "./../images/benefit/200.gif";
import news from "./../images/benefit/news.gif";

const benefitsdetails = [
  { title: " Online webinar learnings", image: onlinewebinar },
  {
    title: "E- Certificates as the participant and achievement",
    image: certificate,
  },
  { title: " Chance to be a team member", image: teamMember },
  { title: " International networking", image: networking },
  {
    title: " Stimulations conference on leadership, science and tech",
    image: leadership,
  },
  {
    title: " You can be a partner for your social and science projects",
    image: tech,
  },
  { title: " Join programs without any selection process", image: noSelection },
  {
    title: " We help outstanding delegates to honour featured in many aspects",
    image: news,
  },
];
function BenefitCards() {
  return (
    <div className="benefitCard">
      <h2 className="text-center text-red" style={{color:"#000", paddingTop:"15px"}}>Benefit of Joining Us</h2>
      <div className="benefitCards">
        {benefitsdetails.map((x) => (
          <div className="benefitCard__details">
            <img className="benefit__images" alt="x-pic" src={x.image} />
            <p>{x.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default BenefitCards;
