import React from "react";
import oneplan from "./oneplan.png";
import "./OnePlan.css";
import Header from "../../Layout/header3";
import Footer from "../../Layout/footer4";
import { Link } from "react-router-dom";

function OnePlan() {
  return (
    <>
      <Header />
      <div className="oneplan">
        <div className="oneplan__quarantine">
          <div className="heading__plan">
            <h1>#GardeningInQuarantine</h1>
          </div>
          <p>
            With the global pause on all but the tech industry, we saw slightly
            clearer canals, slightly clearer skies, and a drop in carbon
            emissions as vehicles and flights, some of the worlds largest
            emitters, were barely functional, we called it a recovery phase for
            earth and it should that we could in fact still reverse the effects
            of climate change and what better way to encourage individual action
            than planting while home in quarantine.
          </p>
        </div>

        <div className="oneplan__second">
          <div>
            <h1>2020</h1>
            <p>Launched, April</p>
          </div>
          <div>
            <h1>220k+</h1>
            <p>Social Media Reach in 3 Weeks</p>
          </div>
        </div>

        <div className="oneplan__third">
          <p className=" text-center " style={{ padding: "25px 0 0 0" }}>
            Data Gathered with Social Listening Tools, mention.com, Facebook
            Analytics & Google Forms
          </p>
          <div className="oneplan__divpic">
            <div className="paragraph__plan">
              <p>A SILVER LINING DURING A PANDEMIC</p>
              <h1>One Plant by One Family</h1>
              <p className="paragraph__oneplan">
                Educating the public on the benefits of trees through social
                media video and engaging individuals with responsibility for
                their plants through an 'eco-pledge'. Reaching over 12 countries
                and co-pioneered by Indian changemaker, Lingaraj Bmath,
                #OnePlantOneFamily has now morphed into a tree planting marathon
                series launching world environment day 2023
              </p>
              <Link to={"/join-our-program"}>
                <button className="oneplan__button">Take Action</button>
              </Link>
            </div>

            <img className="oneplan__images" src={oneplan} alt="oneplan" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default OnePlan;
