import React from "react";
import Counter from "./Counter";
import ExpertCarousel from "./ExpertCarousel";
import "./index.css";
import YoutubePlayer from "./Youtuber";
import Footer4 from "../../../Layout/footer4";
import Header3 from "./Header";
import Agender from "./Agender";
import logo from "./images/Untitled-1 copy.png";
import certificate from "./images/certificate-of-appreciation-design-template-c46b6dc447f94b068a04728bd695cc4c_screen.jpg";
import TestimonialCarousel from "./TestimonialCarousel";
function YoungWebinar() {
  return (
    <>
      <Header3 />
      <div className="young__webinar">
        <div className="webinar">
          <h1 className="text-center text-white">Young 2 Young 101 WEBINAR</h1>
          <h3 className="text-center text-white">
            ONLINE CLASS The Easiest Way to Start Every Y2Y
          </h3>
          <p className="text-center text-white">
            BATCH 7 THE CLASS WILL BE STARTED ON 9 & 16 MAY 2020
          </p>
          <button className="webinar__button">I want to know More</button>
          <button className="webinar__button">Download Flyer</button>
        </div>
      </div>
      {/* in association with  */}
      <div className="young__webinar__inassociation"></div>
      <div className="webinar__beneficial">
        <h2 className="text-center">Why MUN is Beneficial For You?</h2>
        <div className="young__webinar__beneficial">
          <div className="beneficial__webinar">
            <h1>✅</h1>
            <h4>Problem Solving</h4>
          </div>
          <div className="beneficial__webinar">
            <h1>✅</h1>
            <h4>Critical Thinking</h4>
          </div>
          <div className="beneficial__webinar">
            <h1>✅</h1>
            <h4>Decision Making</h4>
          </div>
          <div className="beneficial__webinar">
            <h1>✅</h1>
            <h4>Negociation</h4>
          </div>
        </div>
        <p className="text-center beneficial__text">
          The skills above are the prominent skills that needed to become a
          great leader and a change-maker.
        </p>
        <p className="text-center beneficial__text">
          You can get those skills can you get by participating in Model United
          Nations (MUN).
        </p>
      </div>
      <div className="beneficial__video">
        <YoutubePlayer />
        <div className="beneficial__video__text">
          <h3>What is MUN 101 Webinar?</h3>
          <p>
            MUN 101 Webinar is an international online class about Model United
            Nations (MUN). It will help you to learn and gain a deep
            understanding of everything you need to know about MUN or before you
            joining any MUN conference.
          </p>
          <button className="webinar__button">Join Now</button>
        </div>
      </div>
      {/* counter */}
      <div className="counter__young__webinar">
        <h2 className="text-center text-white">Our Students</h2>
        <Counter />
      </div>
      {/* what you will get */}
      <div className="whatuwill__container">
        <h2 className="text-center p-t30">What Will You Study?</h2>
        <div className="row add__space">
          <div className="youwill__get">
            <i className="ti-rocket m-r10 icon__adding" />

            <h5>Introduction to MUN</h5>
            <p>✅ MUN Procedures</p>
            <p>✅ MUN Terminologies</p>
            <p>✅ Roles in MUN</p>
          </div>
          <div className="youwill__get">
            <i className="ti-write m-r10 icon__adding" />
            <h5>Writing Skill in MUN</h5>
            <p>✅ Research</p>
            <p>✅ Writing Structure</p>
            <p>✅ Position Paper</p>
          </div>
          <div className="youwill__get">
            <i className="ti-microphone m-r10 icon__adding" />
            <h5>Speaking Skill in MUN</h5>
            <p>✅ Speech Delivery Structure</p>
            <p>✅ Diplomacy & Negotiation</p>
            <p>✅ Opening Speech</p>
          </div>
        </div>
      </div>
      {/* our mentors */}
      <div className="our__mentors">
        <h2 className="text-center">Our Expert Mentors</h2>
        <ExpertCarousel />
      </div>
      <div className="what__uwill__study__container ">
        <h2 className="text-center">What you will get</h2>
        <div className="row what__uwill__study">
          <div className="what_uwillget">
            <img src={certificate} alt="uwillget" className="uwill_get" />
            <p>☑ E-Certificate</p>
          </div>
          <div className="what_uwillget">
            <img src={certificate} alt="uwillget" className="uwill_get" />
            <p>☑ E-Certificate</p>
          </div>{" "}
          <div className="what_uwillget">
            <img src={certificate} alt="uwillget" className="uwill_get" />
            <p>☑ E-Certificate</p>
          </div>{" "}
          <div className="what_uwillget">
            <img src={certificate} alt="uwillget" className="uwill_get" />
            <p>☑ Y2Y Lesson Video Series</p>
          </div>{" "}
          <div className="what_uwillget">
            <img src={certificate} alt="uwillget" className="uwill_get" />
            <p>☑ E-Y2Y Syllabus</p>
          </div>{" "}
          <div className="what_uwillget">
            <img src={certificate} alt="uwillget" className="uwill_get" />
            <p>☑ Interactive Live Session</p>
          </div>
          <div className="what_uwillget">
            <img src={certificate} alt="uwillget" className="uwill_get" />
            <p>☑ Realtime Feedbacks</p>
          </div>
          <div className="what_uwillget">
            <img src={certificate} alt="uwillget" className="uwill_get" />
            <p>☑ Meeting New People</p>
          </div>
          <div className="what_uwillget">
            <img src={certificate} alt="uwillget" className="uwill_get" />
            <p>☑ Many More</p>
          </div>
        </div>
      </div>
      {/* what student aresaying abt us */}
      <div className="testimonial__backgrnd">
        <div className="testimonial">
          <h2 className="text-center">What Students Are Saying About Y2Y</h2>
          <TestimonialCarousel />
        </div>
      </div>
      {/* limited for 700 studens */}
      <div className="limited__students">
        <p className="text-center text-white">
          Limited For 700 Young Leaders Only
        </p>
        <button className="limited__button">Join Now</button>
      </div>
      {/* Agender */}
      <div className="webinar__Agender">
        <h2 className="text-center">Agender</h2>
        <Agender />
      </div>

      {/* webinarfooter */}
      <div className="webinar__final__work">
        <div className="webinar__footer">
          <div className="webinar_logo">
            <img src={logo} alt="footer" />
          </div>
          <div className="webinar__info p-10 text-center">
            <p>POWERED BY YOUNG TO YOUNG NETWORK INDIA</p>
            <p>+212698487054</p>
            <p>young2young.com</p>
          </div>
          <div className="social__media webinar__social__media">
            <i className="social__media ti-youtube m-r10" />
            <i className="social__media ti-instagram m-r10" />
            <i className="social__media ti-facebook m-r10" />
            <i className="social__media ti-themify-favicon-alt m-r10" />
          </div>
        </div>
      </div>
      {/* fixed div */}
      <div className="fixed__div__backgrnd">
        <div className="fixed__div">
          <div className="fixed__div__pay">
            <h2 className="">PAYMENT DEADLINE: </h2>
            <h2 className="">30 APRIL 2020</h2>
          </div>
          <div className="fixed__div__midile">
            <p className="p-b0 p-r5">Starts from </p>
            <p
              className="p-b0 p-r5"
              style={{ textDecoration: "line-through", color: "red" }}
            >
              {" "}
              USD 30
            </p>
            <p
              className="p-b0"
              style={{ fontWeight: "bolder", color: "black", fontSize: "2rem" }}
            >
              {" "}
              USD 10
            </p>
          </div>
          <button className="webinar__button">Join Now</button>
        </div>
      </div>
       
    </>
  );
}

export default YoungWebinar;
