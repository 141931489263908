import React from "react";
import { useState, useEffect } from "react";
import { IconButton } from "@mui/material";
import { collection, addDoc, getDocs } from "firebase/firestore";

import "./liveChat.css";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import CloseIcon from "@mui/icons-material/Close";
import db from "./firebase";
export default function ThemeButton() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [message, setMessage] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [showSucess, setShowSucess] = useState(false);
  const [contacts, setContacts] = useState([]);
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const docRef = await addDoc(collection(db, "contacts"), {
        name: name,
        email: email,
        phone: phone,
        message: message,
        country: country,
      });
      console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }

    setShowForm(false);
    setShowSucess(true);
    setTimeout(() => {
      setShowSucess(false);
    }, 5000);
  };

  return (
    <>
      {showForm ? (
        <div className="live__chart">
          <div className="card ">
            <div className="card-title header__livechat">
              <IconButton>
                <CloseFullscreenIcon
                  color="primary"
                  onClick={(e) => setShowForm(false)}
                />
              </IconButton>
              <IconButton>
                <CloseIcon
                  color="primary"
                  onClick={(e) => setShowForm(false)}
                />
              </IconButton>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
               
                  <input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    className="form-control"
                    aria-describedby="emailHelp"
                    placeholder="Full Name"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    type="text"
                    className="form-control"
                    placeholder="Country"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    type="text"
                    className="form-control"
                    placeholder="Whatsap e.g +212....."
                    required
                  />
                </div>
                <div className="mb-3">
                  <textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    type="text"
                    rows="4"
                    cols="50"
                    className="form-control"
                    placeholder="Type your Message here"
                    required
                  />
                </div>
                <div className="mb-3 form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                  />
                  <label className="form-check-label" for="exampleCheck1">
                    Check me out
                  </label>
                </div>
                <button type="submit" className="btn btn-primary">
                  Send
                </button>
              </form>
            </div>
          </div>{" "}
        </div>
      ) : null}
      <div
        onClick={(e) => setShowForm(true)}
        className="bt-support-now theme-btn"
      >
        <i className="ti-comment-alt"></i>
        <span>Live Chat</span>
        {showSucess ? (
          <div class="alert alert-success" role="alert">
            Thank you! Your message was successful sent.
          </div>
        ) : null}
      </div>
    </>
  );
}
