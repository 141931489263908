import React from "react";
import { Link } from "react-router-dom";
import "./index.css";
import science__and__tech from "./data";
function Card() {
  return (
    <>
      <div className="row">
        <div className="cards_display">
          {science__and__tech.map((x) => (
            <div className="card">
              <img src={x.image} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">{x.title}</h5>
                <p className="card-text">
                  {x.p.substring(0, 100)}
                  ...<Link to={`science-and-tech-details/${x._id}`}><span style={{ color:"#0275D8"}}>Read More</span>
                  </Link></p>
                <Link to={`./join-our-program/${x._id}`}>
                  <button className="btn-lg btn-primary">Join Now</button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Card;
