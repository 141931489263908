import React from "react";
// import BulletPoint from "./BulletPoint";
import Card from "./Card";
import science__and__tech from "./data";
import Footer4 from "../../../Layout/footer4";
import Header3 from "../../../Layout/header3";
import { Link } from "react-router-dom";
import BulletPoint from "../science-and-tech/BulletPoint";
 

function GlobalDetails(props) {
  const scienceTech = science__and__tech.find(
    (x) => x._id === props.match.params.id
  );

  return (
    <>
      <Header3 />
      <div className="tech__details">
        <div>
          <h5>{scienceTech.title}</h5>
          <p>{scienceTech.p}</p>
          <Link to="/join-our-program">
            <button className="btn btn-lg btn-primary">Join Now</button>
          </Link>
        </div>
        <img src={scienceTech.image} alt="tech_detail" />
      </div>
      <div className="tech__pragraph">
        <h5>{scienceTech.question}</h5>
        <div>
          <BulletPoint p={scienceTech.p1} />
          <BulletPoint p={scienceTech.p2} />
          <BulletPoint p={scienceTech.p3} />
          <BulletPoint p={scienceTech.p4} />
          <BulletPoint p={scienceTech.p5} />
          <BulletPoint p={scienceTech.p6} />
          <BulletPoint p={scienceTech.p7} />
          <BulletPoint p={scienceTech.p8} />
          <BulletPoint p={scienceTech.p9} />
          <BulletPoint p={scienceTech.p10} />
        </div>
      </div>

      <Footer4 />
    </>
  );
}

export default GlobalDetails;
