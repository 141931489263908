import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Toastify() {
  //   const notify = () => toast.success("New user has just joined");
  const notify = toast.success("New delegate has joined few minutes ago.", {
    position: toast.POSITION.TOP_LEFT,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    autoClose: 9000,newestOnTop:false
  });

  return (
    <div>
      {/* <button onClick={notify
}>

Notify!</button> */}
      <ToastContainer limit={1} style={{marginTop:"130px"}} />
    </div>
  );
}
export default Toastify;
