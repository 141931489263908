import React, { useState } from "react";
 
import Footer4 from "../../../Layout/footer4";
import Header3 from "../../../Layout/header3";
 
import { useDispatch } from "react-redux";
import { signin, signup } from "../../../../actions/user";
import { useHistory } from "react-router-dom";
import Googlesignin from "react-google-login";
import ProgressBar from "../../ProgressBar";
 
import { ButtonBase } from "@mui/material";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
};
function LoginGoole() {
  const [formData, setFormData] = useState(initialState);
  const [isSignUp, setIsSignUp] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isSignUp) {
      dispatch(signup(formData, history));
      // history.push("/webdev-resources");

    } else {
      dispatch(signin(formData, history));
      // history.push("/webdev-resources");

    }
    setShowProgress(true);
    setTimeout(() => {
      setShowProgress(false);
    }, 8000);
  };

  const success = (res) => {
    const result = res?.profileObj;
    const token = res?.tokenId;
    try {
      dispatch({ type: "AUTH", data: { result, token } });
      history.push("/webdev-resources");
    } catch (error) {
      console.log(error);
    }
  };
  const failure = () => {
    console.log("Sign in was unsuccessful. Please try again later");
  };
  const switchMode = () => {
    setIsSignUp((isSignUp) => !isSignUp);
  };

  return (
    <>
      <Header3 />

      <div className="container">
        <div className="sec-title text-center">
          <h1 className="text-center">{isSignUp ? "Sign Up" : "Sign In"}</h1>
        </div>

        {/* <!-- Signup Form--> */}
        <div className="contact-form">
          <form onSubmit={handleSubmit}>
            <div className="row clearfix">
              {isSignUp && (
                <>
                  <div className="col-md-6 form-group flex">
                    <label>Enter your first name</label>
                    <input
                      type="text"
                      name="firstName"
                      className="form-control"
                      placeholder="Enter first name here......"
                      value={formData.firstName}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          firstName: e.target.value,
                        })
                      }
                      required
                    />
                    <i className="ti-user"></i>
                  </div>

                  <div className="col-md-6 form-group">
                    <label>Enter last name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="lastName"
                      placeholder="Enter lasst name here......"
                      value={formData.lastName}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          lastName: e.target.value,
                        })
                      }
                      required
                    />
                    <i className="ti-user"></i>
                  </div>
                </>
              )}
              <div className="col-md-6 form-group">
                <label>Enter email</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="Enter your email here......"
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  required
                />
                <i className="ti-clip"></i>
              </div>

              <div className="col-md-6 form-group">
                <label>Enter Password</label>
                <input
                  className="form-control"
                  type="password"
                  name="password"
                  placeholder="Enter your password here......"
                  value={formData.password}
                  onChange={(e) =>
                    setFormData({ ...formData, password: e.target.value })
                  }
                  required
                />
                <i className="ti-lock"></i>
              </div>

              {isSignUp && (
                <>
                  <div className="col-md-6 form-group">
                    <label>Enter Confirm Password</label>
                    <input
                      className="form-control"
                      type="password"
                      name="confirmPassword"
                      placeholder="Enter your password here......"
                      value={formData.confirmPassword}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          confirmPassword: e.target.value,
                        })
                      }
                      required
                    />
                    <i className="ti-lock"></i>
                  </div>
                </>
              )}
              {showProgress && <ProgressBar />}
              <div className="col-md-12 form-group">
              <ButtonBase onClick={switchMode} style={{color:"blue"}}>
                {isSignUp
                  ? "Already have an account? Sign In"
                  : "Don't have an account? Sign Up"}
              </ButtonBase>
              </div>
              

              <div className="col-md-12 form-group">
                <button className="btn btn-lg btn-primary" type="submit">
                  <span className="btn-title">
                    {isSignUp ? "Sign Up" : "Sign In"}
                  </span>
                </button>
               &nbsp;&nbsp;&nbsp;
                <Googlesignin
                clientId={process.env.REACT_APP_CLIENT_ID}
                onSuccess={success}
                onFailure={failure}
                cookies="single_host_origin"
              />
              </div>

             
            </div>
          </form>
        </div>
      </div>

      <Footer4 />
    </>
  );
}

export default LoginGoole;
