import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import FileBase from "react-file-base64";
import { useDispatch } from "react-redux";
import { createNews } from "../../actions/news";
const initialState = {
  bio: "",
  author: "",
  title1: "",
  paragraph1: "",
  paragraph2: "",
  paragraph3: "",
  category: "",
  image: "",
  authorImage: "",
};
function PublishNews() {
  const [news, setNews] = useState(initialState);

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createNews(news));
    clear();
    console.log(news);
  };
  const clear = () => {
    setNews({
      bio: "",
      author: "",
      title1: "",
      paragraph1: "",
      paragraph2: "",
      paragraph3: "",
      category: "",
      image: "",
      authorImage: "",
    });
  };
  return (
    <Box sx={{ minWidth: 275 }}>
      <Typography variant="h5" component="div">
        Publish news
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          "& > :not(style)": { m: 1, width: "55ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          autoFocus
          type="text"
          id="outlined-basic"
          label="Headline"
          variant="standard"
          required
          fullwidth
          name="title1"
          value={news.title1}
          onChange={(e) => setNews({ ...news, title1: e.target.value })}
        />
        <TextField
          required
          id="outlined-basic"
          label="First Paragraph"
          variant="standard"
          fullwidth
          type="text"
          name="paragraph1"
          value={news.paragraph1}
          onChange={(e) => setNews({ ...news, paragraph1: e.target.value })}
        />
        <TextField
          required
          type="text"
          id="outlined-basic"
          label="Second Paragraph"
          variant="standard"
          fullwidth
          name="paragraph2"
          value={news.paragraph2}
          onChange={(e) => setNews({ ...news, paragraph2: e.target.value })}
        />
        <TextField
          type="text"
          id="outlined-basic"
          label="Third Paragraph"
          variant="standard"
          fullwidth
          name="paragraph3"
          value={news.paragraph3}
          onChange={(e) => setNews({ ...news, paragraph3: e.target.value })}
          required
        />
        <TextField
          type="text"
          id="outlined-basic"
          label="Category"
          variant="standard"
          fullWidth
          name="category"
          value={news.category}
          onChange={(e) => setNews({ ...news, category: e.target.value })}
          required
        />
        <TextField
          type="text"
          required
          id="outlined-basic"
          label="Author Name"
          variant="standard"
          fullWidth
          name="author"
          value={news.author}
          onChange={(e) => setNews({ ...news, author: e.target.value })}
        />
        <div className="col-md-12 form-group">
          News Image
          <FileBase
            type="file"
            multiple={false}
            onDone={({ base64 }) => setNews({ ...news, image: base64 })}
          />
         
        </div>
        <div className="col-md-12 form-group">
          Author Image
          <FileBase
            type="file"
            multiple={false}
            onDone={({ base64 }) => setNews({ ...news, authorImage: base64 })}
          />
         
        </div>
         
        <TextField
          required
          type="text"
          id="outlined-basic"
          label="Bio"
          variant="standard"
          fullwidth
          name="bio"
          value={news.bio}
          onChange={(e) => setNews({ ...news, bio: e.target.value })}
        />
        <Button
          onClick={handleSubmit}
          sx={{ minWidth: 275 }}
          variant="contained"
          fullwidth
          color="primary"
          type="submit"
        >
          Publish
        </Button>
        <Button
          sx={{ minWidth: 275 }}
          onClick={clear}
          variant="contained"
          fullWidth
          color="secondary"
          type="submit"
        >
          Clear
        </Button>
      </Box>
    </Box>
  );
}

export default PublishNews;
