import React from 'react';

import AccordionBlog from './../../../Element/AccordionBlog';
import VideoPopup from './../../../Element/VideoPopup';

import pattern from './../../../../images/pattern/pic1.jpg';
import about from './../../../../images/about/pic10.jpg';

function FrequentAskQuestion() {
  return (
    <>
      <div className="page-content bg-white">
        {/* <!-- inner page banner --> */}

        {/* <!-- inner page banner END --> */}
        {/* <!-- contact area --> */}
        <div className="content-block">
          {/* <!-- Your Faq --> */}
          <div
            className="section-full overlay-white-middle content-inner"
            style={{ backgroundImage: 'url(' + pattern + ')' }}
          >
            <div className="container">
              <div className="section-head text-black text-center">
                <h3>Do you have Questions?</h3>
                <div className="dlab-separator bg-primary"></div>
                <p>Frequently Asked Questions</p>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 m-b30">
                  <div className="faq-video">
                    <VideoPopup />
                    <img src={about} alt="" className="img-cover radius-sm" />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 m-b30">
                  <AccordionBlog />
                </div>
              </div>

              {/* <!-- Faq Info END --> */}
            </div>
          </div>
          {/* <!-- Your Faq End --> */}
        </div>
        {/* <!-- contact area END --> */}
      </div>
    </>
  );
}

export default FrequentAskQuestion;
