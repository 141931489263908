import React from "react";
import { Link } from "react-router-dom";
import CountTimer from "../course-resources/count-down/CountTimer";
import joshua from "./images/joshua.jpg";
import xc from "./images/xc.jpg";
import xy from "./images/xy.jpg";
const webdev = [
  {
    p: "We're glad you've found the Complete Web Development Bootcamp,the one and only program you'll need to learn to code and become a full-stack web developer",
  },
];

function ComingSoon() {
  return (
    <div classNameName="container">
      <h1 className="text-center pt-3">Comming Soon</h1>
      {/* <CountTimer /> */}
      <div className="row row-cols-1 row-cols-md-4 lg-3">
        <div className="col">
          <div className="card ">
            <img src={joshua} className="card-img-top " alt="..." />
            <div className="card-body">
              <h5 className="card-title">Founder:LearnBlue.Org</h5>
              <p className="card-text">
                Joshua Gabriel Oluwaseyi Is A Nigerian Climate Activist,
                Creative Consultant, Internationally Acclaimed Social
                Entrepreneur, Inspirational Speaker, Brand Curation And
                Management Consultant With Years Of Experience Building
                Start-Ups Across B2B E-Commerce, Agritech And B2C PR Agencies,
                Nonprofit, And Social Enterprises.
              </p>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card">
            <img src={xc} className="card-img-top" alt="..." />
            <div className="card-body">
              <p className="card-text">
                "Green Revolution: What Young People Can do to Help"
              </p>
              <ul className="list-group">
                <li className="list-group-item active">
                  Programme scheduled 1 hour
                </li>
                <li className="list-group-item">Date: 17/July/2022</li>
                <li className="list-group-item">01:00 pm UTC 05:30 pm IST</li>
              </ul>
              <Link to={"/join-our-program"}>
                <button className="btn btn-lg btn-primary mt-2">
                  Register Now
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
            <img src={xy} class="card-img-top" alt="..." />
            <div class="card-body">
              <h5 class="card-title">Front-End Developement</h5>
              {webdev.map((x) => (
                <p class="card-text">
                  {x.p.substring(0, 101)} ...
                  <Link to={"/course-details/jdjd5sd5s65q65s5560"}>
                    {" "}
                    <span style={{ color: "#0275D8" }}>Read More</span>
                  </Link>
                </p>
              ))}

              <p className="card-text">
                No programming experience needed - I'll teach you everything you
                need to know.
              </p>
              <Link to={"/join-our-program"}>
                <button className="btn btn-lg btn-primary  mb-0">
                  Register Now
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ComingSoon;
