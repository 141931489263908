import React from "react";

import "./webdevelopment.css";

import CancelIcon from "@mui/icons-material/Cancel";

function BulletPointCancel({ paragraph }) {
  return (
    <div className="bulletPoint">
      <CancelIcon style={{ color: "#FF0000", marginRight: "10px" }} />
      <p>{paragraph}</p>
    </div>
  );
}

export default BulletPointCancel;
