import React from "react";
import "./index.css";
import science__and_tech from "./images/homepage-banner-animated-2.gif";
import Science from "./Science";
import Card from "./Card";
import Footer4 from "../../../Layout/footer4";
import Header3 from "../../../Layout/header3";
function ScienceAndTech() {
  return (
    <>
      <Header3 />
      <div className="science__tech ">
        <h1 className="text-center">
          <mask style={{ backgroundColor: "#3396D1" }}>
          Science begins with research, while technology begins with experimentation. So let's talk about science and technology that can make our lives easier.
          </mask>
        </h1>
        <p className="text-center">
        As we all know, science improves the imagination and technology brings action, so the two work together to create world-wide innovation. Curiosity, diversity, and collaboration are essential. It is a platform for discussing science and technology, as well as a network of inspiring and connecting scientists from around the world.
        </p>
      </div>
      <div className="science__tech_fp">
        <img src={science__and_tech} alt="tech_pic" />
        <div className="fp__content__pg">
          <Science
            p="Science and technology discussions foster a forward-thinking mindset, encourage critical thinking, and foster collaborations."
          />
          <Science p="Technology begins with an experiment, whereas science begins with research.  " />
          <Science
            p="As we all know, science improves the imagination and technology brings action, so it's a perfect combination for world growth and innovation."
          />
        </div>
      </div>
      <div className="cards__science">
        <Card />
      </div>
      <Footer4 />
    </>
  );
}

export default ScienceAndTech;
