import climate from "./images/climate.gif";
import mental from "./images/mental.gif";
import covid from "./images/covid.gif";

const globalData = [
  {
    _id: "44f4f646g44gggd6q7",
    image: climate,
    title: "Climate Change",
    p: "Climate change is triggered by a shift in the earth's energy balance, which determines how much of the sun's energy enters the earth (and its atmosphere) and is released back into space.The earth gains energy as we reduce the amount of solar energy reflected into space, just as people gain weight when their calorie intake and expenditure are out of balance.Human activities have released massive amounts of greenhouse gases (GHG) into the atmosphere since the Industrial Revolution began over 200 years ago. Instead of letting the sun's energy and heat reflect into space, these GHG act as a greenhouse (or a blanket or a car windshield) to trap it. When GHG concentrations are too high, too much heat is trapped, and the earth's temperature rises beyond its natural range. ",
    p1: "Climate change is causing five critical global environmental changes:7",
    p2: "Warming temperature of the earth’s surface and the oceans: ",
    p3: "Changes in the global water cycle (‘hydrologic’ cycle)",
    p4: "Declining glaciers and snowpack: Across the globe, nearly all glaciers are decreasing in area, volume and mass. One billion people living in river watersheds fed by glaciers and snowmelt are thus impacted",
    p5: "Sea level rise: Warmer water expands, so as oceans warm the increased volume of water is causing sea level rise. Melting glaciers and snowpack also contribute to rising seas.",
    p6: "Ocean acidification: Oceans absorb about 25% of emitted CO2 from the atmosphere, leading to acidification of seawater. These global changes result in what we experience as changes in our local weather and climate:",
    p7: "Greater variability, with “wetter wets”, “drier dries” and “hotter hots” ° More frequent and severe extreme heat events ° More severe droughts ° More intense precipitation, such as severe rains, winter storms and hurricanes ",
    p8: "Higher average temperatures and longer frost-free seasons ",
    p9: "Longer wildfire seasons and worse wildfires",
    p10: "Loss of snowpack and earlier spring runoff ",
    p11: "Recurrent coastal flooding with high tides and storm surge",
  },

  {
    _id: "44f4f646g44gggd6q9",
    image: mental,
    title: "Mental Health",
    p: "Cognitive, behavioral, and emotional well-being are all included in mental health. It all comes down to how people think, feel, and act. The term mental health is sometimes used to refer to the absence of a mental disorder. Mental illness can have a negative impact on daily life, relationships, and physical health. Mental health issues can be caused by a variety of factors, including personal experiences, interpersonal relationships, and physical factors.",
    p1: "Mental health issues can be caused by a variety of factors, including personal experiences, interpersonal relationships, and physical factors.",
    p2: "Taking care of one's mental health can help them enjoy life more. This entails striking a balance between daily activities, responsibilities, and psychological resilience efforts.",
    p3: "Stress, depression, and anxiety can all affect a person's mental health and disrupt their routine.",
    p4: "Despite the widespread use of the term mental health, many conditions that doctors recognize as psychological disorders have physical roots.",
    p5: "The terms mental health and mental illness are defined in this article. We also go over the most common mental disorders, their early warning signs, and how to treat them.",
    p6:"Equipping students with strong Social Emotional Learning (SEL) skills is one of the best ways to empower them to be aware of their own mental health. Thus, talking about mental health will help recognize and react to their own mental health concerns, as well as create perspective and practice empathy, which helps form stronger relationships and better communication.",
    p7:"Equipping students with strong Social Emotional Learning (SEL) skills is one of the best ways to empower them to be aware of their own mental health. Thus, talking about mental health will help recognize and react to their own mental health concerns, as well as create perspective and practice empathy, which helps form stronger relationships and better communication.",
    p9:"Encourage mental health communication and the introduction of coping methods such as mindfulness and therapy to help students feel confident in owning their mental health struggles.",
    p8:"Reducing mental health stigma allows students to openly experience and communicate their mental health concerns, resulting in a better understanding of their overall mental well-being.",
    p10:"Youths can become more aware of their own mental wellbeing and learn about supporting the mental health of those around them by learning to identify and react to their emotions in a productive and healthy way.",

  },
  {
    image: covid,
    _id: "44f4f646g44gd6q14",
    title: "Corona Virus",
    p: "Coronaviruses are a group of viruses that infect both animals and humans and belong to the Coronaviridae family. Signs and symptoms include respiratory symptoms and include fever, cough, and shortness of breath. In more severe cases, the infection can cause pneumonia, severe acute respiratory syndrome, and sometimes death. Standard recommendations to prevent the spread of COVID-19 include frequent cleaning of hands using alcohol-based hand rub or soap and water; covering the nose and mouth with a flexed elbow or disposable tissue when coughing and sneezing; and avoiding close contact with anyone that has a fever and cough.",
    p1: "Young people have been disproportionately affected by the pandemic, compared to many other social groups. The pandemic's economic effects and the closure of educational institutions have already had a significant impact on the lives of young people. According to UNESCO, 194 countries had closed schools nationwide by mid-April 2020, affecting nearly 1.6 billion students.",
    p2: "There were still 105 nationwide closures in August 2020, affecting over a billion students. Many educators have worked hard to maintain student learning and well-being in this situation. ",
    p3: "Additionally, COVID-19 has also posed an immense psychosocial impact on young people. Disruption in education and economic opportunities, family stress, social isolation, risk of domestic abuse, and uncertainty about the future have led to reduced well-being of young people globally.",
    p4: "Digital technologies have the potential to open up new doors and provide new ways of learning. They have the power to influence what people learn, how they learn, where and when they learn, and, most importantly, how teachers and students interact. However, the COVID-19 crisis occurred at a time when most educational systems were unprepared to take advantage of digital technology's potential. The nature, scope, and success of these efforts differ by country and economy, but digital technologies have emerged as a critical requirement for success.",
    p5: " “The end of the pandemic may now be in sight, at least in some parts of the world. But its effects on young people will last well beyond it.” ",
    p6: "By 2030, 130 million people may join the ranks of those living in extreme poverty, dealing a significant blow to global efforts to eradicate extreme poverty and hunger. ",
    p7: "However, the pandemic could lead to a new normal, fundamentally altering human interactions, interdependence, trade, and globalization while hastening digitalization and automation.",
    p8: "More than three-quarters of the 128 million vaccine doses administered so far have been administered in just ten countries, which account for 60% of global GDP.",
    p9: "As of today, nearly 130 countries with a combined population of 2.5 billion people have yet to administer a single dose.",
    p10:"As COVID-19 demonstrated, we require global leadership to scale up vaccine production and achieve vaccine equity. Whether we win or lose, we will do so together."
  },
];
export default globalData;
