import React from 'react';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import bnr2 from './../../images/background/bg1.jpg';

const counterBlog = [
  {
    icon: <i className="ti-bag m-r10" />,
    num: '15',
    title: 'Conferences',
  },
  {
    icon: <i className="ti-user m-r10" />,
    num: '20000',
    title: ' Registrants',
  },
  {
    icon: <i className="flaticon-users m-r10" />,
    num: '50',
    title: 'Countries',
  },
  {
    icon: <i className="ti-mobile m-r10" />,
    num: '200',
    title: 'Alumni',
  },
];
function Connect() {
  return (
    <div
      className="section-full content-inner overlay-primary bg-img-fix text-white"
      style={{ backgroundImage: 'url(' + bnr2 + ')' }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-6 p-a30">
            <h1 className="m-t0 m-b10 font-weight-700 font-45 text-center">
              Connect  and  Impact
            </h1>
            <p className="text-white text-center" style={{ fontSize: '20px' }}>
              Join us! To create, stimulate and impact
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Connect;
