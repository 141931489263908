import React from 'react'
import image1 from "./Asia-Student-Summit.jpg"
import "./Program.css"
import Footer4 from '../../../Layout/footer4'
import Header3 from '../../../Layout/header3'
function Programme() {
  return (
    <>
    <Header3 />
    <div className="program">
    <h1 className="text-center ">IGN EVENTS </h1>
    <div className="empty_div"></div>
    <p className="text-center">“Connecting Youth Leaders to The World”</p>
    </div>
    {/* // other event */}
    <div className="program__event">
    <h1 className="text-center">OUR OTHER EVENTS</h1>
    <p className="text-center">Official Events of IGN</p>

    <div className="program__event__wrap">
    <div className="program__event__image">
  <div className="programme__image">  
  <img   className="programme_images" src={image1} alt="here"/>
  </div>
    <p >Asia Student Summit (A.S.S)</p>
    <h4>Seoul, South Korea</h4>
    </div><div className="program__event__image">
    <div className="programme__image">  
    <img   className="programme_images" src={image1} alt="here"/>
    </div>
      <p >Asia Student Summit (A.S.S)</p>
      <h4>Seoul, South Korea</h4>
      </div>
      <div className="program__event__image">
      <div className="programme__image">  
      <img   className="programme_images" src={image1} alt="here"/>
      </div>
        <p >Asia Student Summit (A.S.S)</p>
        <h4>Seoul, South Korea</h4>
        </div>
        <div className="program__event__image">
      <div className="programme__image">  
      <img   className="programme_images" src={image1} alt="here"/>
      </div>
        <p >Asia Student Summit (A.S.S)</p>
        <h4>Seoul, South Korea</h4>
        </div>
        <div className="program__event__image">
      <div className="programme__image">  
      <img   className="programme_images" src={image1} alt="here"/>
      </div>
        <p >Asia Student Summit (A.S.S)</p>
        <h4>Seoul, South Korea</h4>
        </div>
        <div className="program__event__image">
      <div className="programme__image">  
      <img   className="programme_images" src={image1} alt="here"/>
      </div>
        <p >Asia Student Summit (A.S.S)</p>
        <h4>Seoul, South Korea</h4>
        </div><div className="program__event__image">
        <div className="programme__image">  
        <img   className="programme_images" src={image1} alt="here"/>
        </div>
          <p >Asia Student Summit (A.S.S)</p>
          <h4>Seoul, South Korea</h4>
          </div><div className="program__event__image">
          <div className="programme__image">  
          <img   className="programme_images" src={image1} alt="here"/>
          </div>
            <p >Asia Student Summit (A.S.S)</p>
            <h4>Seoul, South Korea</h4>
            </div>
    </div>
    
    </div>
    <Footer4 />
    </>
  )
}

export default Programme