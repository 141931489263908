import React, { Component } from "react";
  import { Link } from 'react-router-dom';
// import Header from "./../../Layout/Header1";
// import Footer5 from "./../../Layout/footer5";
// import PageTitle from "./../../Layout/PageTitle";
// import SectionCounter from "./../../Element/SectionCounter";

//Images
// import about9 from "./../../../images/about/pic9.jpg";
import bg2 from "./../../../images/background/bg-map.jpg";
import services1 from "./../../../images/our-services/pic1.jpg";
import services2 from "./../../../images/our-services/pic2.jpg";

// import bnr from "./../../../images/banner/bnr2.jpg";

class Aboutus1 extends Component {
  render() {
    return (
      <>
        <div className="page-content bg-white">
          <div className="content-block">
            {/* Counetr section */}

            {/* Counetr section End*/}
            <VisionBlog />
          </div>
        </div>
      </>
    );
  }
}
function VisionBlog() {
  return (
    <>
      <div
        className="section-ful our-about-info content-inner-1 "
        style={{
          backgroundImage: "url(" + bg2 + ")",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container">
          <div className="section-head text-center">
            <h2 className="box-title m-tb0">
              Welcome to Code4you!
              <span className="bg-primary"></span>
            </h2>
            <p>
            Code4you is sponsored by The Young to Young with the collaboration of Youths Impact Centre to educate and impact students throughout Africa and other parts of the world on codes. It’s an online and offline tutor workshop with young entrepreneurs and coding expertise.
            </p>
          </div>
          <div className=" row dzseth  m-b30">
            <div className="col-lg-6 col-md-12 m-b30 about-img ">
              <img src={services1} data-tilt alt="" />
            </div>
            <div className="col-lg-6 col-md-12 m-b30 dis-tbl text-justify">
              <div className="dis-tbl-cell" >
                {/* <h3 className="box-title">
                  Mision<span className="bg-primary"></span>
                </h3> */}
                <p className="font-16" style={{color:"black"}}>
                  It’s up to our young change makers to create change makers. This project is under the leadership of Lingaraj Bmath, founder and CEO of Young to Young, and Abeedah (co-founder and president of Youth Impact Centre, Africa), and our key players’ expertise is Alison, Sophie, and Ismail.
                </p>
                <p className="font-16" style={{color:"black"}}>
                  They provide a broad, balanced, and challenging curriculum to allow all students to reach their full potential.
                </p>
                <p className="font-16" style={{color:"black"}}>
                  Students in our programme came from various African countries, so we provided adequate resources and directed them to some external resources where they could continue to study after classes
                </p>
 
              </div>
            </div>
          </div>
          <div className="row dzseth column-reverse">
            <div className="col-lg-6 col-md-12 dis-tbl text-justify">
              <div className="dis-tbl-cell">
                {/* <h3 className="box-title">
                  Vision<span className="bg-primary"></span>
                </h3> */}
                <p className="font-16" style={{color:"black"}}>
                  This project will cost you only your passion, dedication, and ability to impact the world.
                </p>
                <p className="font-16" style={{color:"black"}}>
                 This project wants to reach 1000 people in 3 months. We do provide an incredible benefit in that the top 15 students will have the opportunity to write a case study mentorship, and the top 50 students will receive free registration for our upcoming programme called "Talk on Science and Technology.
                </p>
                <p className="font-16" style={{color:"black"}}>
                  We believe in you and spreading this project throughout your community. Come on, let’s move together. Join us today for better change. It’s your passion and our mission. Join us today.
                </p>
<div style={{display:"flex"}}>
                  
 <div className="" style={{display:"flex" , alignItems:"center", marginRight:"20px"}}>

   <Link to={"/join-our-program"}
                      className="dez-page site-button primary" >
                      Ambassador 
                    </Link>
</div>
 <div className="" style={{display:"flex" ,alignItems:"center"}}>

   <Link to={"/join-our-program"}
                      className="dez-page site-button primary" >
                      Registration 
                    </Link>
</div>
  </div>

              </div>
            </div>
            <div className="col-lg-6 col-md-12 about-img ">
              <img src={services2} data-tilt alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export { VisionBlog };
export default Aboutus1;
