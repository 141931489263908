import React from "react";
import Footer4 from "../../Layout/footer4";
import Header3 from "../../Layout/header3";
import about from "./about.gif";
import "./Aboutus.css";
function AboutUs() {
  return (
    <>
      <Header3 />
      <div className="container">
        <h1 className="text-center" style={{ color: "#0000E9" , paddingTop:"15px"}}>
        Who are we, exactly?
        </h1>
        <div className="about__uss">
          <img className="about__us__images" alt="" src={about} />
          <div className="about__uss__text__block">
            <p className="" style={{ fontSize: "1prem", color:"black" }}>
            The Young-to-Young aims to connect passionate delegates with talented mentors all over the world, form a mutual partnership with global youth leaders, and implement socially driven local government education programmes that teach youths how to make informed decisions about issues affecting their future. 

            </p>
            <p style={{ fontSize: "1prem",color:"black" }}>
              Its mission is to assist youths in becoming successful in the modern world by enrolling them in the necessary modern technology skill development that will help them become self-employed, making impossible things possible through the actualization of each youth's dreams. 

            </p>
            <p style={{ fontSize: "1prem",color:"black" }}>
              {" "}
              Furthermore, it aims to improve the world's future leaders by initiating success-guaranteed fearless steps towards development and assisting the young generation in becoming "research-oriented" to engineer innovative, sustainable decisions for the world's social, economic, and political governance.

            </p>
          </div>
        </div>
      </div>
      <div className="aboutus" style={{ backgroundColor: "#1043F9" }}>
        <div className="container ">
          <div className="row2 ">
            <div className="messsion__text">
              <h2 className="text-white">Mission</h2>
              <h2 className="text-white">Vision</h2>
            </div>

            <div className="mission__text__paragraph">
              <p style={{ fontSize: "1.5rem"}}>
                Connecting young people all over the world to foster the
                development of young leaders, researchers, scientists, and a
                variety of other diverse advance contributions to the world.
              </p>
              <p style={{ fontSize: "1.5rem"}}>
                To develop successful trailblazers who thrive in a world of
                digital solutions.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <h1  className="text-center" style={{ padding: "20px 0px", color:"#0000E9" }}>
          The Young to Young believes in
        </h1>

        <div
          className="aboutus__3pillars"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#F7F7F7",
            textAlign: "center",
          }}
        >
          <div style={{ padding: "10px" }}>
            <h3>Create an idea </h3>
            <p style={{ textAlign: "center" ,color:"black"}}>
              Create an opportunity for you to collaborate with us on a vision
              of your dreams and stand out among thousands.
            </p>
          </div>
          <div style={{ padding: "10px" }}>
            <h3>Stimulate an idea</h3>
            <p style={{ textAlign: "center" ,color:"black"}}>
              The Young to Young program encourages young people to explore the
              international young community and inspire others by utilizing
              their unique abilities.
            </p>
          </div>
          <div style={{ padding: "10px" }}>
            <h3>Impact the result</h3>
            <p style={{ textAlign: "center",color:"black" }}>
              The Young to Young strives for positive outcomes in which each young
              person's unique abilities are recognized and utilized.
            </p>
          </div>
        </div>
      </div>
      <Footer4 />
    </>
  );
}

export default AboutUs;
