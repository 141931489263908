import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import GoogleMaps from 'simple-react-google-maps';
import Header4 from './../Layout/header4';
import Footer5 from './../Layout/footer5';
import LatestBlogSlider from './../Element/LatestBlogSlider';

//Images

import slider9 from './../../images/main-slider/slide9.png';
import bgslider9 from './../../images/main-slider/slide9.jpg';
import bg19 from './../../images/background/bg19.jpg';
import bg1 from './../../images/background/bg1.jpg';
import bnr1 from './../../images/background/bg1.jpg';
import services1 from './../../images/our-services/pic1.jpg';
import services2 from './../../images/our-services/pic2.jpg';

import test1 from './../../images/testimonials/pic1.jpg';
import test2 from './../../images/testimonials/pic2.jpg';
import test3 from './../../images/testimonials/pic3.jpg';
import Index7ClientCarousel from '../Element/Index7ClientCarousel';
import TestimonialStyle2 from './ShortCode/TestimonialStyle2';
import HomeTab from '../Element/HomeTab';

const expertBox = [
  { image: test1 },
  { image: test2 },
  { image: test3 },
  { image: test2 },
  { image: test3 },
  { image: test1 },
];

const Index6 = (props) => {
  let resizeMargin = () => {
    var screenWidth = window.innerWidth;
    if (screenWidth >= 1280) {
      var elmnt = document.querySelector('.container');
      var containerSize = elmnt.offsetWidth;

      var getMargin = (screenWidth - containerSize) / 2;

      var setResizeMarginButton =
        document.getElementsByClassName('setResizeMargin');
      var class_len =
        setResizeMarginButton !== null ? setResizeMarginButton.length : 0;
      for (var i = 0; i < class_len; i++) {
        setResizeMarginButton[i].setAttribute(
          'style',
          'margin-left:' + getMargin + 'px'
        );
      }
    }
  };

  useEffect(() => {
    resizeMargin();
  }, []);

  var i = 0;

  // Placeholder Animation Start
  var inputSelector = document.querySelectorAll('input, textarea');

  for (i = 0; i < inputSelector.length; i++) {
    inputSelector[i].addEventListener('focus', function (event) {
      return this.parentElement.parentElement.classList.add('focused');
    });
  }

  for (i = 0; i < inputSelector.length; i++) {
    inputSelector[i].addEventListener('blur', function (event) {
      var inputValue = this.value;
      if (inputValue === '') {
        this.parentElement.parentElement.classList.remove('filled');
        this.parentElement.parentElement.classList.remove('focused');
      } else {
        this.parentElement.parentElement.classList.add('filled');
      }
    });
  }

  return (
    <>
      <Header4 />

      <div className="page-content bg-white rubik-font">
        <div
          className="home-banner-2"
          style={{ backgroundImage: 'url(' + bgslider9 + ')' }}
        >
          <div className="container">
            <div className="home-bnr-inner-2 row align-items-center">
              <div className="home-bnr-content-2 col-md-6">
                <h4 className="sub-title">We’re Awesome & Professionals</h4>
                <h2 className="dz-title text-primary">
                  <span>In Teaching Strategy</span>
                  <br />
                  <strong>Start Now by</strong>
                </h2>
                <p>
                  Giving your child a great head start in life.
                  <br />
                  Every child deserves a quality <br />
                  and a well rouded-education in their informative year
                  <br />
                </p>
                <Link to={'#'} className="site-button button-md radius-xl">
                  Explore Now
                </Link>
              </div>
              <div className="col-md-6">
                <div className="curve-img">
                  <img src={slider9} alt="" className="slide-img-curve" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="section-full content-inner-2 overlay-primary choseus-tabs  bg-img-fix"
          style={{ backgroundImage: 'url(' + bnr1 + ')' }}
        >
          <div className="container">
            <div className="section-head text-white text-center">
              <h2 className="box-title m-tb0">
                Why Choose Us<span className="bg-primary"></span>
              </h2>
              <p>
                We seek to aid the development of children in our care, to
                become outstanding scholars, athletes, artists and global
                citizens, by instilling in them the spirit of excellence.
              </p>
            </div>
          </div>
          <HomeTab />
        </div>
        <>
          <div
            className="section-ful our-about-info content-inner-1 "
            style={{
              // backgroundImage: 'url(' + bg2 + ')',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div className="container">
              <div className="section-head text-center">
                <h2 className="box-title m-tb0">
                  Mission And Vision<span className="bg-primary"></span>
                </h2>
                <p>
                  {' '}
                  Learning hand in hand – partners in education. Our aim is to
                  set high standards and expectations to help all members of the
                  school community to achieve the best in everything they do and
                  to work towards reaching their full potential. To fulfil this
                  we will:
                </p>
              </div>
              <div className=" row dzseth  m-b30">
                <div className="col-lg-6 col-md-12 m-b30 about-img ">
                  <img src={services1} data-tilt alt="" />
                </div>
                <div className="col-lg-6 col-md-12 m-b30 dis-tbl text-justify">
                  <div className="dis-tbl-cell">
                    <h3 className="box-title">
                      Mision<span className="bg-primary"></span>
                    </h3>
                    <p className="font-16">
                      Maintain a safe, open and friendly environment within
                      which pupils are encouraged to develop a love of learning
                      and to become confident, independent and lifelong learners
                    </p>
                    <p className="font-16">
                      Provide a broad, balanced and challenging curriculum to
                      enable all pupils to work to the best of their ability
                    </p>
                    <p className="font-16">
                      Children in our school come from different cultural
                      backgrounds; they have different religions and speak
                      different languages. We celebrate and value this
                      diversity. We encourage the children to be proud of their
                      own culture, religion and language and to show respect for
                      those of others.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row dzseth column-reverse">
                <div className="col-lg-6 col-md-12 dis-tbl text-justify">
                  <div className="dis-tbl-cell">
                    <h3 className="box-title">
                      Vision<span className="bg-primary"></span>
                    </h3>
                    <p className="font-16">
                      Our school aims to be the centre for a meaningful child
                      centred, fun-based curriculum that builds like skills,
                      innovation, independence, collaboration, confidence, and
                      resilience to support each child in achieving their full
                      potential and to make a smooth transition to school and
                      society.
                    </p>
                    <p className="font-16">
                      Provide equal opportunities for all our children and
                      encourage them to be proud of their own culture, religion
                      and language and to show respect for those of others.
                    </p>
                    <p className="font-16">
                      Work together in a positive partnership with parents and
                      governors and to contribute to the quality and development
                      of our local community.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 about-img ">
                  <img src={services2} data-tilt alt="" />
                </div>
              </div>
            </div>
          </div>
        </>
        <div className="content-block">
          {/* <!-- Customer Review  --> */}
          <div
            className="section-full content-inner-2 bg-gray bg-img-fix overlay-primary"
            style={{ backgroundImage: 'url(' + bg1 + ')' }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center section-head text-white">
                  <h2 className="font-weight-700 text-white m-b0">
                    What People are saying about our School?
                  </h2>
                  <p className="m-b0">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <TestimonialStyle2 />
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Customer Review END --> */}
          {/* <!-- Our Recent Blog Posts --> */}
          <div className="section-full bg-white content-inner">
            <div className="container">
              <div className="max-w600 m-auto text-center m-b30">
                <h6 className="m-t0">Publish what you think</h6>
                <h2 className="m-t0">Latest Blogs</h2>
              </div>
              <LatestBlogSlider />
            </div>
          </div>
          {/* <!-- Our Recent Blog Posts END --> */}
          {/* <!-- Our Team --> */}
          <div className="section-full content-inner-2 our-team-bx bg-primary">
            <div className="container">
              <div className="row">
                <div className="section-head text-white col-lg-12 text-center">
                  <h5 className="title-small">Our Team</h5>
                  <h2 className="title-head m-b0">Top experts mates</h2>
                </div>
              </div>
              <div className="row">
                {expertBox.map((data, index) => (
                  <div className="col-lg-4 col-md-6 col-sm-6" key={index}>
                    <div className="experts-box text-white p-a20">
                      <div className="clearfix experts-info-bx">
                        <div className="experts-img">
                          <img src={data.image} alt="" />
                        </div>
                        <div className="experts-info">
                          <h5 className="experts-name">David Mawa</h5>
                          <h6 className="experts-service">Founder & C.E.O</h6>
                        </div>
                      </div>
                      <p className="m-b10">
                        E.g Fresh Graduate Master in Business Administration
                        from Makerere University-Ugandan
                      </p>
                      <ul className="dez-social-icon">
                        <li>
                          <Link
                            to={'#'}
                            className="fa text-white fa-facebook"
                          ></Link>
                        </li>
                        <li>
                          <Link
                            to={'#'}
                            className="fa text-white fa-google-plus"
                          ></Link>
                        </li>
                        <li>
                          <Link
                            to={'#'}
                            className="fa text-white fa-linkedin"
                          ></Link>
                        </li>
                        <li>
                          <Link
                            to={'#'}
                            className="fa text-white fa-twitter"
                          ></Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          S{/* contact form and map here */}
          {/* <!-- Our Portfolio --> */}
          <div
            className="section-full p-tb15 our-support content-inner-2"
            style={{
              backgroundImage: 'url(' + bg19 + ')',
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100%',
              backgroundPosition: 'bottom',
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-lg-12 section-head text-center">
                  <h2 className="m-b0 font-40">
                    <span className="font-weight-400">Contact</span> Us
                  </h2>
                  <p className="m-b0">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the.
                  </p>
                </div>
              </div>
              <div className="support-box-form bg-primary">
                <div className="row m-lr0">
                  <div className="col-lg-6 p-lr0 d-flex">
                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d57803.76927259502!2d75.78311389999999!3d25.110810700000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1540556893926" style={{width: "100%", border: "0"}} className="d-flex align-items-stretch" allowfullscreen></iframe> */}
                    <GoogleMaps
                      apiKey={'AIzaSyDrAU41UTBlcEDNJgEtdlFLZeUBNBuHhzM'}
                      style={{ minHeight: '100%', width: '100%' }}
                      zoom={6}
                      center={{
                        lng: 31.570843449950317,
                        lat: 4.861605317445261,
                      }}
                      markers={{
                        lng: 31.570843449950317,
                        lat: 4.861605317445261,
                      }} //optional
                    />
                  </div>
                  <div className="col-lg-6">
                    <div className="support-form">
                      <div className="support-title text-white m-b30">
                        <h6 className="text-uppercase font-weight-500 m-b10">
                          Support
                        </h6>
                        <h2 className="font-40 font-weight-400 m-tb0">
                          Need Help?
                        </h2>
                        <p className="font-14">
                          Contact our customer support team if you have any
                          questions.
                        </p>
                      </div>
                      <div className="dezPlaceAni">
                        <div className="dzFormMsg"></div>
                        <form method="post" className="" action="">
                          <input type="hidden" value="Contact" name="dzToDo" />
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                              <div className="form-group">
                                <div className="input-group">
                                  <label>Your Name</label>
                                  <input
                                    name="dzName"
                                    type="text"
                                    required
                                    className="form-control"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                              <div className="form-group">
                                <div className="input-group">
                                  <label>Phone</label>
                                  <input
                                    name="dzOther[Phone]"
                                    type="text"
                                    required
                                    className="form-control"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                              <div className="form-group">
                                <div className="input-group">
                                  <label>Your Email Address</label>
                                  <input
                                    name="dzEmail"
                                    type="email"
                                    className="form-control"
                                    required
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                              <div className="form-group">
                                <div className="input-group">
                                  <label>Your Message...</label>
                                  <textarea
                                    name="dzMessage"
                                    rows="4"
                                    className="form-control"
                                    required
                                    placeholder=""
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12">
                              <button
                                name="submit"
                                type="submit"
                                value="Submit"
                                className="site-button white button-md m-t10"
                              >
                                Submit Now
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* download from our official line*/}
          <div className="container m-t80 ">
            <div className="section-head title-bx text-center">
              <h2>Download Our Mobile App</h2>
            </div>
            <div className="app-btn-bx text-center">
              <Link to={'#'} className="site-button radius-xl m-lr5 ">
                <i className="fa fa-apple"></i>IOS
              </Link>
              <Link to={'#'} className="site-button radius-xl m-lr5">
                <i className="fa fa-android"></i>Android
              </Link>
            </div>
          </div>
          {/* <!-- Contact Us END --> */}
        </div>
        {/* <!-- contact area END --> */}
        <div
          style={{ marginTop: '6px' }}
          className="section-full bg-primary-dark p-tb15"
        >
          <div className="container">
            <Index7ClientCarousel />
          </div>
        </div>
      </div>

      <Footer5 />
    </>
  );
};
export default Index6;
