import React from "react";
import "./index.css";

import globalData from "./data";
import { Link } from "react-router-dom";

function Card() {
  return (
    <div className="cardx">
      {globalData.map((x) => (
        <div key={x._id} className="card__global">
          <img src={x.image} alt="card_images" />
          <div>
            <h3>{x.title}</h3>
            <p>
              {x.p.substring(0,100)}..<Link to={`/global-issues-details/${x._id}`}>
              <span style={{ color:"#0275D8"}}>Read More</span>
              </Link>
            </p>
            <Link to={"/join-our-program"}>
            <button className="btn-lg btn-primary">Join Now</button>
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Card;
