import React from "react";
import "./index.css";
import solving from "./images/solving.gif";
import global from "./images/global.gif";
import Card from "./Card";
import Footer from "../../../Layout/footer4";
import Header from "../../../Layout/header3";
import ShortTestimonial from "../../ShortCode/YoungtoyoungTestimonial";
function GlobalIssus() {
  return (
    <>
      <Header />
      <div className="global__issue">
        <h1 style={{ color: "#1043f9" }}>
          A global issue is something that people all over the world are
          concerned about. As a global society, we are confronted with a variety
          of issues.
        </h1>
        <img src={global} alt="global__issues" />
      </div>
      <div className="global__next__page">
        <h3 className="text-center " style={{ color: "red" }}>
          {" "}
          Deforestation, poaching, and pollution are all contributing to serious
          issues like global warming and climate change.
        </h3>
        <p
          className=""
          style={{ width: "90%", margin: "auto", fontSize: "1.2rem" }}
        >
          Discrimination based on gender, caste, creed, or race, as well as
          terrorism, are among the others. Many people around the world still
          lack equal access to resources and are unable to afford a basic
          standard of living. Despite the advancement of human society, many
          parts of the world are still experiencing severe social and political
          unrest.
        </p>{" "}
        <br />
        <p
          className=""
          style={{ width: "90%", margin: "auto", fontSize: "1.2rem" }}
        >
          In today's world, the value of global awareness is immeasurable. When
          global awareness has become such a pressing concern, or the Earth Will
          Soon Fall Apart It's also the "First Step Towards Change" and a way to
          get a head start on improving society, saving the planet, and
          achieving a balance between progress and preservation. Progress is the
          focus of our existence as human society advances. We have made
          enormous technological and social advancements, making us the most
          advanced species on the planet.
        </p>
        <br />
        <p
          className=""
          style={{ width: "90%", margin: "auto", fontSize: "1.2rem" }}
        >
          However, if we begin by raising global awareness among students and
          instilling honorable values in them, we will have a head start.
          According to research, students who learn about global issues are more
          than twice as likely to recognize the importance of taking personal
          social action. This encourages awareness and critical thinking in
          order to be responsible and actionable citizens, making motivation to
          take the first steps easier. Students can start with small steps with
          proper education and encouragement. The importance of each step's
          long-term benefits must always be emphasized. We have made numerous
          technological and social advancements that have elevated us to the
          status of the most advanced species on the planet.
        </p>
      </div>

      <div className="second">
        <div className="global__second__page">
          <img src={solving} alt="summit" />
          <div className="second__page__text__global">
            <h3 className="text-center" style={{ color: "#000" }}>
              This will encourage students to take the initiative to carry out
              these positive actions.
            </h3>
            <p>
              Students can use their understanding of these pressing problems to
              come up with more and more innovative inventions and ideas as they
              mature and become responsible for the future of mankind.
            </p>
            <p>
              All of this demonstrates that humans are still far from realizing
              their full potential, and we still have a long way to go. However,
              unless we start focusing on the importance of global awareness, we
              will never be able to achieve everything we are capable of.
            </p>
          </div>
        </div>
      </div>
      {/* our program */}
      <div className="our__program__global">
        <h1 className="text-center" style={{ color: "#000" }}>
          Join Our Programes
        </h1>
        {/* <p className="text-center">
          Ut possimus qui ut temporibus culpa velit eveniet modi omnis est
          adipisci expedita at voluptas atque vitae autem.
        </p> */}
        <div className="cards_from__card">
          <Card />
        </div>
      </div>
      {/* <ShortTestimonial /> */}
      <Footer />
    </>
  );
}

export default GlobalIssus;
