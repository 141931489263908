import { PropaneSharp } from '@mui/icons-material'
import React from 'react'

function BulletPoint(props) {
  return (
    <div className="point__bullet">
 
        <span>🚀</span>
        <p>{props.p}</p>
    </div>
  )
}

export default BulletPoint