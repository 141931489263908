import YouTube from "react-youtube";
import React from "react";
import "./index.css";
function YoutubePlayer (){
  const videoOnReady=(event)=>{
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }
   
    const opts = {
      height: "310",
      width: "550",
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
      },
    };

    return <YouTube videoId="fDZExIBh1YU" opts={opts} onReady={videoOnReady} className="video" />;
  
}
export default YoutubePlayer;
