import React from 'react'
import "./Trending.css"
import TrendingCards from './TrendingCards'

function TrendingProgram() {
  return (
    <>

    <div className='trending'>
    <div className="trendinggrid">
       <h2 className='text-center  trending__title'>Trending Programes</h2>
       <div className="trending__cards">
       <TrendingCards />
       </div>
    </div>
    </div>
    
    </>
  )
}

export default TrendingProgram