import React from 'react';
import Footer4 from '../../Layout/footer4';
import Header3 from '../../Layout/header3';
import logo from './../../../images/logo-black.png';
import Team from './../Team'
import './index.css';
function Contactus() {
  return (
    <>
      <Header3 />
      {/* <div className="container ">
        <h1 className="text-center">Contact Us</h1>
        <div className="contact__details">
          <div className="contact__detail-image">
            <img src={logo} alt="logo" />
          </div>
          <div className="contact__detail">
            {' '}
            <h1>Email:</h1>
            <p>young2young@gmail.com</p>
          </div>
        </div>
      </div> */}
      

      
      <Team />
      <Footer4 />
    </>
  );
}

export default Contactus;
