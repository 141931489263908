import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';

const defaultAccordion = [
  {
    title: '1.Tell me more about this program',
    text: 'MUN Academy Webinar is a program intended for those who are interested in learning MUN and be its first-timers in any conferences out there. There will be so many fun sessions and simulations allowing you to have a fun learning experience while mingling with international youths from around the world, which is why this is the best platform to learn MUN and a place where you can get yourself ready for any international opportunities so far. Learning from scratch with MUN Academy is the right choice to start your MUN journey!',
  },
  {
    title: '2.Is this a paid program?',
    text: 'Yes, this is a paid program. With only $5 and get ready to unlock your new potentials of learning the basics of MUN and improve your speech and negotiation skills!',
  },
  {
    title: '3. How can I join this program?',
    text: 'You only need to fill in the registration form on this page, and then you will be redirected to the payment page to secure your seat in the webinar.',
  },
  {
    title: '4. How much does the program cost?',
    text: 'Only $5. As already mentioned before, with only $5 and get ready to unlock your new potentials of learning the basics of MUN and improve your speech and negotiation skills.',
  },
  {
    title: '5. What benefits will I get by joining this program?',
    text: 'By joining this program, you will get:Webinar Learning About Model United Nations 1)E-Certificate as the participant 2) Free Access to 3 E-books of “Mastering Your First Model United Nations” 3) Become a member of the International Community From Many Countries Across the World 4)International networking and friendship 5) MUN 101 - All You Need To Know About MUN 6) MUN Simulation - Speech and Negotiation MUN 7) Interactive Q&A sessions',
  },
  {
    title: 'Is there any certificate provided?',
    text: 'Yes, there will be a certificate provided.',
  },
  {
    title: 'Where will the program be held?',
    text: 'This is an online-based program. It will be held virtually using Zoom.',
  },
  {
    title: 'I have completed the payment, what should I do now?',
    text: 'Your payment will be automatically confirmed within 1x24 hours after you have made the payment. However if you are in Indonesia and using Bank Transfer method of payment, you need to send us the proof of payment so that we can confirm the payment.',
  },
];
const AccordionBlog = () => {
  const [activeDefault, setActiveDefault] = useState(0);
  return (
    <Accordion
      className="accordion dlab-accordion faq-1 box-sort-in m-b30"
      defaultActiveKey="-1"
    >
      {defaultAccordion.map((d, i) => (
        <div className="panel">
          <div className="acod-head">
            <h6 className="acod-title">
              <Accordion.Toggle
                as={Link}
                variant="link"
                className={` ${activeDefault === i ? '' : 'collapsed'}`}
                onClick={() => setActiveDefault(activeDefault === i ? -1 : i)}
                eventKey={`${i}`}
              >
                {d.title}
              </Accordion.Toggle>
            </h6>
          </div>

          <Accordion.Collapse eventKey={`${i}`} className="acod-body">
            <div className="acod-content">{d.text}</div>
          </Accordion.Collapse>
        </div>
      ))}
    </Accordion>
  );
};
export { defaultAccordion };
export default AccordionBlog;
