import * as React from "react";
import Link from "@mui/material/Link";
import Avatar from "@mui/material/Avatar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "./Title";
import moment from "moment";
import Box from "@mui/material/Box";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { deleteContact } from "../../../actions/contacts";

import { useLocation } from "react-router-dom";
import TimeTable from "../TimeTable";
import PublishNews from "../PublishNews";
import { IconButton } from "@mui/material";

function createData(id, date, name, shipTo, paymentMethod, amount) {
  return { id, date, name, shipTo, paymentMethod, amount };
}

const rows = [
  createData(
    0,
    "12 march, 2022",
    "Lingaraj Bhixavarthimath",
    "Chief Executive Officer",
    "New Dheli, India",
    "+918050399715"
  ),
  createData(
    1,
    "16 March, 2022",
    "Alison Magid Juma",
    "Chief Technical Officer",
    "Rabat, Morocco",
    "+212698487054"
  ),
  createData(
    2,
    "10 April, 2022",
    "Loteleni Kauko Shikulo",
    "Chief Networking Officer",
    "Windhoek, Namibia",
    "+264814667320"
  ),
  createData(
    3,
    "20 April, 2022",
    "Nana",
    "General Manager",
    "Accra, Ghana",
    "+233246345839"
  ),
];

function preventDefault(event) {
  event.preventDefault();
}

export default function Orders({ setContactId }) {
  const courses = useSelector((state) => state.courses);
  const contacts = useSelector((state) => state.contacts);
  const dispatch = useDispatch();
  const location = useLocation();
  switch (location.pathname) {
    case "/dashboard":
      return (
        <React.Fragment>
          <Title>Board of Directors</Title>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Position</TableCell>
                <TableCell>Address</TableCell>
                <TableCell align="right">Contact</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.date}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.shipTo}</TableCell>
                  <TableCell>{row.paymentMethod}</TableCell>
                  <TableCell align="right">{`${row.amount}`}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Link
            color="primary"
            href="#"
            onClick={preventDefault}
            sx={{ mt: 3 }}
          >
            See more
          </Link>
        </React.Fragment>
      );
    // students case
    case "/students":
      return (
        <React.Fragment>
          <Title>List of the students Attending course</Title>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Country</TableCell>
                <TableCell>City</TableCell>
                <TableCell>School</TableCell>
                <TableCell>Email</TableCell>
                <TableCell align="right">Phone</TableCell>
                
              </TableRow>
            </TableHead>
            <TableBody>
       
     

              {courses.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{moment(row.updatedAt).fromNow()}</TableCell>
                  <TableCell>{row.FullName}</TableCell>
                  <TableCell>{row.country}</TableCell>
                  <TableCell>{row.city}</TableCell>
                  <TableCell>{row.school}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell align="right">{`${row.phone}`}</TableCell>
                  <Avatar src={row.profilePicture} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Link
            color="primary"
            href="#"
            onClick={preventDefault}
            sx={{ mt: 3 }}
          >
            See more
          </Link>
        </React.Fragment>
      );
    // contact zones
    case "/contact-zone":
      return (
        <Box sx={{ minWidth: 275 }}>
          {contacts.slice(0).reverse().map((contact) => (
            <div
              key={contact._id}
              style={{ border: "1px solid gray", margin: "5px" }}
            >
              <CardContent>
                <Typography variant="h5" component="div">
                  Time:&nbsp;{moment(contact.updatedAt).fromNow()}
                </Typography>
                <Typography variant="h5" component="div">
                  First Name:&nbsp;{contact.firstName}
                </Typography>
                <Typography
                  sx={{ mb: 1.5 }}
                  variant="h5"
                  color="text.secondary"
                >
                  Last Name:&nbsp;{contact.lastName}
                </Typography>
                <Typography variant="h6">
                  Whatsap Number:&nbsp;{contact.phone}
                  <br />
                </Typography>
                <Typography variant="h6" >
                  Email:&nbsp;<span style={{color:"blue"}}>{contact.email}</span>
                </Typography>
                <Typography variant="h6">
                  Country:&nbsp;{contact.country}
                  <br />
                </Typography>
                <Typography variant="h6">
                  Gender:&nbsp;{contact.gender}
                  <br />
                </Typography>
                <Typography variant="h6">
                  School/University/Institution:&nbsp;{contact.school}
                  <br />
                </Typography>

                <Typography variant="h6">
                  English-Level:&nbsp;{contact.englishLevel}
                  <br />
                </Typography>
                <Typography variant="h6">
                  FeedBack:&nbsp;<span style={{fontStyle: 'italic'}}>{contact.feeback}</span>
                  <br />
                </Typography>
                <Typography variant="h6">
                  Joining Program:&nbsp;{contact.program}
                  <br />
                </Typography>
                <Typography variant="h6">
                  Id #:&nbsp;{contact._id}
                  <br />
                </Typography>
              </CardContent>
              <CardActions>
                <IconButton
                  onClick={() => dispatch(deleteContact(contact._id))}
                >
                  <DeleteForeverIcon />
                </IconButton>
              </CardActions>
            </div>
          ))}
        </Box>
      );
    // publish news
    case "/publish-news":
      return <PublishNews />;
    // time table
    case "/time-table":
      return <TimeTable />;
    // default case
    default:
      return "/dashboard";
  }
}
