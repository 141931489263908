import { List, ListItem } from "@mui/material";
import React from "react";
import Header3 from "./Header";
import logo from "./images/Untitled-1 copy.png";
import "./index.css";
function HowtoParticipate() {
  return (
    <>
      <Header3 />
      <div className="participate">
        <h1 className="text-center text-white">HOW TO PARTICIPATE</h1>
      </div>
      {/* steps */}
      <div className="participate__instruction">
        <h4 className="text-center p-t20">
          Here is the simple way to participate in MUN 101 Webinar:
        </h4>
        <List>
          <ListItem>
            1. Download Application Zoom Cloud Meetings in your Mobile
            Phone/Laptop.
          </ListItem>
          <ListItem>
            1. Download Application Zoom Cloud Meetings in your Mobile
            Phone/Laptop.
          </ListItem>
          <ListItem>
            2. Click Join a Meeting once you’ve successfully downloaded the
            application.
          </ListItem>
          <ListItem>
            3. Input the Meeting ID number (it will be shared to you a day
            before the Live Session scheduled) and your Real Name (set your real
            name in the Zoom account as your username/account).
          </ListItem>
          <ListItem>
            4. Input the Password (it will be shared to you a day before the
            Live Session scheduled)
          </ListItem>
          <ListItem>
            5. Next, you can choose whether you want to join the meeting “with”
            or “without Video” (turn on/off camera).
          </ListItem>
          <ListItem>
            6. Make sure you click Join With Computer Audio and test your mic
            and audio whether it worked or not if it not worked please chat the
            host/moderator in charge privately during the session (Tech IGN)
          </ListItem>
          <ListItem>7. Enjoy the rest of live session. :)</ListItem>
        </List>
      </div>
      {/* webinarfooter */}
      <div className="webinar__final__work">
        <div className="webinar__footer">
          <div className="webinar_logo">
            <img src={logo} alt="footer" />
          </div>
          <div className="webinar__info p-10 text-center">
            <p>POWERED BY YOUNG TO YOUNG NETWORK INDIA</p>
            <p>+212698487054</p>
            <p>young2young.com</p>
          </div>
          <div className="social__media webinar__social__media">
            <i className="social__media ti-youtube m-r10" />
            <i className="social__media ti-instagram m-r10" />
            <i className="social__media ti-facebook m-r10" />
            <i className="social__media ti-themify-favicon-alt m-r10" />
          </div>
        </div>
      </div>
      {/* fixed div */}
      <div className="fixed__div__backgrnd">
        <div className="fixed__div">
          <div className="fixed__div__pay">
            <h2 className="">PAYMENT DEADLINE: </h2>
            <h2 className="">30 APRIL 2020</h2>
          </div>
          <div className="fixed__div__midile">
            <p className="p-b0 p-r5">Starts from </p>
            <p
              className="p-b0 p-r5"
              style={{ textDecoration: "line-through", color: "red",  }}
            >
              {" "}
              USD 30
            </p>
            <p
              className="p-b0"
              style={{ fontWeight: "bolder", color: "black", fontSize: "2rem" }}
            >
              {" "}
              USD 10
            </p>
          </div>
          <button className="webinar__button">Join Now</button>
        </div>
      </div>
    </>
  );
}

export default HowtoParticipate;
