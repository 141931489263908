import React from 'react'
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import "./webdevelopment.css"
function Bulletpoint({paragraph}) {
  return (
    <div className="bulletPoint" >
<CheckBoxIcon style={{ color:"#008000", marginRight:"10px"}} />
<p>{paragraph}</p>
    </div>
  )
}

export default Bulletpoint