import React from "react";
import "./index.css";
import alison from "./IMG_0109_filtered-removebg-preview.png";
import Footer4 from "../../../Layout/footer4";
import Header3 from "../../../Layout/header3";
function UniversityAbasador() {
  return (
    <>
      <Header3 />
      <div className="compus__ambasador">
        <h1 className="text-center">Our University Ambasadors</h1>
        <div className="ambasador__compus">
          {/* cards start */}
          
          {/* <div className="compus__ambasador__details">
            <img className="ambasador__pic" src={alison} alt="" />
            <div className="ambasador__detail__contact">
              <h3>Name:Alison Magid juma</h3>
              <p>Country: Morocco</p>
            </div>
          </div> */}
          
          {/* cards end */}
        </div>
      </div>
      <Footer4 />
    </>
  );
}

export default UniversityAbasador;
