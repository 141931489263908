import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer4 from "../../Layout/footer4";
import Header3 from "../../Layout/header3";
import FileBase from "react-file-base64";
import { createNgodata } from "./../../../actions/ngoData";
const initialState = {
  organizationName: "",
  country: "",
  certificate: "",
  email: "",
  phone: "",
  feeback: "",
};
function NgoJoinForm() {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState(initialState);
  console.log(formData)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [termAndCondition, setTermAndCondition] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createNgodata(formData));
    setShowSuccessMessage(true);
    setTimeout(() => {
      setShowSuccessMessage(false);
    }, 9000);

    clear();
  };
  const clear = () => {
    setFormData({
      organizationName: "",
      country: "",
      certificate: "",
      email: "",
      phone: "",
      feeback: "",
    });
  };
  return (
    <>
      <Header3 />
      <div style={{ padding: "10px 0" }}>
        <div className="container">
          <form onSubmit={handleSubmit}>
            <p className="text-center">
              This form must be completed by an organization that is fully
              preregistered and operational on a national or global level, and
              it must include precise information without escaping any form
              fields.
            </p>

            <div className="form-group">
              <label for="name">Name of the Organization</label>
              <input
                type="text"
                className="form-control"
                id="name"
                aria-describedby="lastnameHelp"
                placeholder="Name of the Organization"
                name="organizationName"
                value={formData.organizationName}
                onChange={(e) =>
                  setFormData({ ...formData, organizationName: e.target.value })
                }
                required
              />
            </div>
            <div className="form-group">
              <label for="name">Country</label>
              <input
                type="text"
                className="form-control"
                id="name"
                aria-describedby="emailHelp"
                placeholder="Country where the organization is operating"
                name="country"
                value={formData.country}
                onChange={(e) =>
                  setFormData({ ...formData, country: e.target.value })
                }
                required
              />
            </div>
            <div className="form-group" style={{ border: "3px solid white" }}>
              <label for="name">ID-Card/CV/Certificate of Registration</label>

              <FileBase
                component="div"
                className="form-control "
                type="file"
                multiple={false}
                onDone={({ base64 }) =>
                  setFormData({ ...formData, certificate: base64 })
                }
                required
              />
            </div>

            <div className="form-group">
              <label for="name">Phone Number/WhatsApp</label>
              <input
                type="text"
                className="form-control"
                id="name"
                aria-describedby="lastnameHelp"
                placeholder="Your whatsap number with country code e.g (+212690300054)"
                name="phone"
                value={formData.phone}
                onChange={(e) =>
                  setFormData({ ...formData, phone: e.target.value })
                }
                required
              />
            </div>
            <div className="form-group">
              <label for="exampleInputEmail1">Email address</label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter email"
                name="email"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                required
              />
              <small id="emailHelp" className="form-text text-muted" required>
                We'll never share your email with anyone else.
              </small>
            </div>
            <div className="form-group">
              <label for="exampleInputPassword1">
                Organization Description
              </label>

              <textarea
                className="form-control"
                id="txtid"
                rows="5"
                cols="50"
                maxlength="200"
                placeholder="Describe the role of your organization ........................"
                required
                type="text"
                name="feeback"
                value={formData.feeback}
                onChange={(e) =>
                  setFormData({ ...formData, feeback: e.target.value })
                }
              ></textarea>
            </div>

            <div className="form-group form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                required
              />
              <label className="form-check-label" for="exampleCheck1">
                Terms & Conditions
              </label>
              <button
                className="btn btn-sm btn-primary"
                onClick={(e) => setTermAndCondition(true)}
              >
                Read
              </button>
              {termAndCondition ? (
                <>
                  <small
                    id="emailHelp"
                    className="form-text text-muted"
                    required
                  >
                    1. We showcase your current program on our website and
                    social media.
                  </small>
                  <small
                    id="emailHelp"
                    className="form-text text-muted"
                    required
                  >
                    2. Your logo will appear on our website.
                  </small>
                  <small
                    id="emailHelp"
                    className="form-text text-muted"
                    required
                  >
                    3. If you have provided us with any misleading information,
                    you are accountable.
                  </small>
                </>
              ) : null}
            </div>

            {showSuccessMessage && (
              <>
                <div class="alert alert-success" role="alert">
                  <h3 class="alert-heading">
                  Thank you for your interest in helping us.
                  </h3>
                  <p>
                  You've successfully entered your information into our system; our administrator is working on it now and will contact you within 24 hours.
                  </p>
                  <hr />
                  <p class="mb-0">
                  Thank you again, and have a wonderful rest of your day. Alison from the Y2Y administration.
                  </p>
                </div>
              </>
            )}
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
      </div>
      <Footer4 />
    </>
  );
}

export default NgoJoinForm;
