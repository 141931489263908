import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { PayPalButton } from "react-paypal-button-v2";
import Loader from "./Loader";
import { createRegistration } from "../../actions/registration";
import {useHistory} from "react-router-dom"
const AddPaypalButton1 = ({ order }) => {
  const [sdkReady, setSdkReady] = useState(false);

  const dispatch = useDispatch();
const history = useHistory();
  const loadingPay = false;

  useEffect(() => {
    // Add PayPal Script
    const addPayPalScript = async () => {
      const clientId =
        "AYXizzVv-EkltAKiYGjxgrh7rQ9arjXFAzX6rWj600XJAhye4iqZsYNA4Mm-YqAFqQLuhiOKP4BWD0tK";

      // Fetch the client Id from the back end-> server.js
      const script = document.createElement("script"); // Vanilla JavaScript
      script.type = "text/javascript";
      script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}`; // https://developer.paypal.com/docs/checkout/reference/customize-sdk/ Query parameters
      script.async = true;
      script.onload = () => {
        setSdkReady(true); // Tell us the script has been loaded
      };
      document.body.appendChild(script); // Dynamically adding the PayPal script
    };
    // addPayPalScript() END
    if (!window.paypal) {
      // Checking if Not Paid and Not window.paypal
      addPayPalScript();
    } else {
      setSdkReady(true);
    }
  }, [dispatch]);
  // useEffect END

  const successPaymentHandler = async (paymentResult) => {
    // This paymentResult is coming from PayPal response after payment success to PayPal Account
    console.log(paymentResult);

    // call the backend API to save data and payment
    const formData = order.paymentMethod;
    dispatch(createRegistration({ paymentResult, formData, history }));

  };

  return (
    <>
      {loadingPay && <Loader />}
      {!sdkReady ? (
        <Loader />
      ) : (
        <PayPalButton
          amount="5.00"
          currency="USD"
          onSuccess={successPaymentHandler}
        />
      )}
    </>
  );
};

export default AddPaypalButton1;
