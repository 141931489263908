import React from "react";
import Typewritter from "typewriter-effect";
import "./webdevelopment.css";
function Typewriter() {
  return (
    <Typewritter
      options={{
        strings: ["Build your career with us", "Get hired", 'Get promoted',"Be your own boss."],
        autoStart: true,
        loop: true,
      }}
    />
  );
}

export default Typewriter;
