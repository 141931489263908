import photoshop from "./graphic_images/photoshop.png";
import afinit from "./graphic_images/afinit.png";
import illustrator from "./graphic_images/illustrator.png";

const graphicData = [
  {
    _id: "15d6s51d2s54ds632ds32d",
    title: "Affinity Suite",
    image: afinit,
    requirement:
      "Access to the Affinity Products - Affinity Photo, Designer and Publisher. If you are intersted in going through the mobile apps, access to those are suggested although not required to enjoy most of course",
    courseDescription:
      "Master the Affinity Suite & Create: Logos, Books, Brochures, Posters, Brushes, Thumbnails, Learn Photo Editing and More!",
    overview:
      "Are you ready to learn the entire suite of Affinity products and up your design game? This course guides through learning the many tools in Affinity Photo, Affinity Designer, Affinity Publisher and the Affinity mobile apps.In Affinity photo Loteleni Kauko will build 3 popular advertisements using the Affinity Photo program as well as 3 separate Youtube thumbnails….an essential skill for any creator using the software. Lastly we will be building a complete movie poster using custom made brushes ...so you learn everything you need to build the poster.In Affinity Designer Lindsay Marsh will work through all of the major tools and work on an engaging downloadable tracing worksheet so you can master that pen tool shapes tool and create a series of icons in your very first hour! We then conquer a bright and colorful logo design as we work with typography and learn how gradients work. Next we will focus on layout design as we create a front and back of a business card.",
    whatYouwillLearn: [
      "You will master the Affinity Designer, Photo, Publisher and Mobile Apps - The Full Suite of Affinity Software, ",
      "Get comfortable using the Pen tool and other vector tools to create logos, icons and graphics of all kinds, ",
      "Create a wide variety of projects including logo designs, brochures, books, Instagram posts, posters, business cards, create brushes, digital graphics, advertisements, ",
    ],
    instractor: "Loteleni Kauko",
  },
  {
    _id: "15d6s51d2s54ds632ds32dss",
    title: "Adobe Photoshop",
    image: photoshop,
    courseDescription:
      "This Adobe Photoshop Essentials course will teach you Photoshop Retouching as well as Photoshop for graphic design.",
    overview:
      "Hi there, my name is Loteleni kauko. I am an Adobe Certified Instructor (ACI) for Photoshop. Are you struggling to learn Photoshop on your own? This course will allow you to use Photoshop professionally. You will be able to add Photoshop to your CV & start getting paid for your Photoshop skills. In this course I will teach you everything you need to know about getting started with Photoshop. You'll learn how to use Photoshop for use in Graphic Design & for Photoshop Retouching. You will create lots of your own projects that you can add to your own portfolio to help your employment.",
    whatYouwillLearn: [
      "You will be able to start earning money from your Photoshop Skills, ",
      "You will be able to add Photoshop to your CV, ",
      "You will create social media post images for Facebook, Instagram, Twitter, Pintrest & Youtube. ",
      "You will have over 20 of your own projects to add to your portfolio, You will create social media post images for Facebook, Instagram, Twitter, Pintrest & Youtube.",
      "You will be able to mask anything… including the dreaded hair!",
    ],
    requirement:
      "Adobe Photoshop, preferably the latest CC (Creative Cloud) version. No prior knowledge or experience with Photoshop is required",
    instractor: "Loteleni Kauko",
  },
  {
    _id: "15d6s51d2s54ds632ds32ddd",
    title: "Adobe Illustrator",
    image: illustrator,
    requirement:
      "Laptop, Adobe Illustrator preferable CC and Knowledge on using Computer",
    courseDescription:
      "Adobe Illustrator Mega Course - From Beginner to Advanced",
    overview:
      "This Adobe Illustrator course is designed to take absolute beginners step by step into an advanced level in design and Illustration. The course consists of two-part the Basic part and Advanced part. The basic part is just like a master class, in this part I will teach all the details you need to know to start using illustrate confidently. Basic does not mean you are not going to make interesting stuff, in this part, you will be creating a dozen of interesting artworks using what you have learnt. After you master adobe illustrator in the first part, you will be ready for more advanced stuff in illustrator, and we will start with the most interesting one which is:Data visualization, in this section I will show you how to create clean & modern charts. I will show you how to connect a spreadsheet to adobe illustrator and then will show you how to turn boring charts to an exciting and eye-catching charts, Then we will have a Geometric section. In this section, I will show you how to create different types of grids and then how to use them to make beautiful abstract geometric artwork ...",
    whatYouwillLearn: [
      "Master Adobe Illustrator. How to create a Project and Navigate In Adobe Illustrator, Everything About Drawing In Illustrator, Everything About Type Tools and Typography, You Will Master Masking And Its Types, Blend Tool For 3D Strokes And Hair Effect and many more",
    ],
    instractor: "Alison De Best",
  },
];

export default graphicData;
