import React from 'react'
import "./Research.css"
function Bullet(props) {
  return (
    <div className="bullet__point">
        <span>🚀</span>
        <p>{props.p}</p>
    </div>
  )
}

export default Bullet