import React from "react";
import Footer4 from "../../../Layout/footer4";
import Header3 from "../../../Layout/header3";
import page2 from "./team.gif";
import { Link } from "react-router-dom";

import onlinewebinar from "./image_processing20200409-28290-1h7r795.gif";
import "./index.css";
import FrequentAskQuestion from "./FrequentAskQuestion";
import image2 from "./ConferenceSpeakers_1024.jpg";
import BenefitCards from "./BenefitCards";
function MunOnline() {
  return (
    <>
      <Header3 />
      <div className="online">
        <div className="container contetent__2__firstpage">
          <div className="online__content">
            <h1>
              {/* <span style={{ color: "#F60101" }}>HERE WE!</span> */}
              <br />
              HERE WE ARE!<br />
              GET READY TO GO ON A NEW <br />
              JOURNEY WITH YOUNG TO YOUNG AND UNLOCK YOUR NEW POTENTIALS TODAY!
            </h1>
           

            <Link to="/join-our-program">
              <button className="button__yellow">Join US</button>
            </Link>
          </div>

          {/* images contents */}
          <div className="online__top-image">
            <img src={image2} alt="aliage" />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="online__page2">
          <h2
            className="text-center transformation__text"
            style={{ textTransform: "uppercase" }}
          >
            LIFE TRANSFORMATION IS ONLY POSSIBLE IF YOU HAVE A VISION OF
            EXCELLENCE.
          </h2>
          <p style={{ fontSize: "1rem" }}>
          A vision is a road map to a happy life. Yes! Vision provides clarity about time, strength, goals, and life in order to have an identity and a meaningful life journey that inspires the world.
          </p>
          <p style={{ fontSize: "1rem" }}>
            A vision inspires you to achieve things you never thought possible. It causes changes in your life. It is more difficult to create a path of excellence without establishing a vision.
          </p>
          <p style={{ fontSize: "1rem" }}>
           Vision teaches you what your strengths are and inspires you to be creative, and your visions determine how you spend your time, and yes! It's the ideal time to find transformation in your life and write your own story by partnering with us.
          </p>
          {/* display flex */}
          <div className="quotes__and__pic">
            <div className="online__secondpage__image">
              <img src={page2} alt="page2" />
            </div>
            <div className="quoute__paragraph">
              <p style={{ fontSize: "1rem" }}>
                "Learning gives creativity Creativity leads to thinking, Thinking
                provides knowledge Knowledge makes you great"A.P.J-Abdul Kalam.
              </p>
              <p style={{ fontSize: "1rem" }}>
                A dream is your creative vision for your life in the
                future-Denis Waitley
              </p>
              <p style={{ fontSize: "1rem" }}>
                Be a hero and always say no fear- Swami Vivekananda
              </p>
            </div>
          </div>
        </div>
        {/* end display flex */}
      </div>
      <div className="container">
        <div className="online__thirdpage">
          <h1 className="text-center">
            <span style={{ color: "#FF8C00" }}>
              If you have a vision for the future, you are amazing!
            </span>
          </h1>
          <div className="online__thirdpage-join">
            {" "}
            <h7>
              It's great to have a vision, but turning your vision into a
              life-changing mission is even better. To improve your skills, you
              must take fundamental steps. It's time to sign up for one of our
              many programs, including webinars, social projects, workshops, and
              more. Each program will help you improve your skills and have the
              best time possible with us.
            </h7>
            <div className="online__thirdpage__image">
              <img className="image__width" src={onlinewebinar} alt="logo" />
            </div>
          </div>
        </div>
      </div>
      <div className="online__forthpage">
        <div className="online__forthpage__content">
          <h1 className="text-center" style={{ textTransform: "uppercase" }}>
            WE ARE GLAD TO HAVE YOU ON BOARD.
          </h1>
          <Link to={"/join-our-program"}>
            <button className="btn btn-lg btn-secondary">
              <span>I'm Ready!</span>
            </button>
          </Link>
        </div>
      </div>

      {/* networking networking cards here */}

      <BenefitCards />

      <div className="oline__sixpages__benefit">
        <h1
          className="text-center text-white"
          style={{ textTransform: "uppercase", color: "#fff" }}
        >
          WE ARE GLAD TO HAVE YOU ON BOARD.
        </h1>
        <p
          className="text-white text-center"
          style={{ fontSize: "1.6rem", fontWeight: "bolder" }}
        >
          Do what you enjoy and meet other creative people to inspire you.{" "}
        </p>

        <div className="online__sixpage__divide text-center">
          {/* <div className="online__fontbolder">
            <h1 className="text-white">$5</h1>
          </div>
          <div className="online__sixpage__divide-money">
            <p style={{ fontSize: "2rem" }}>$28</p>
            <h4 className="online__sixpage__divide_h4 text-white">Save $23</h4>
           
          </div> */}
        </div>
        <Link to={"/join-our-program"}>
          <button className="joinus__button">JOIN US</button>
        </Link>
      </div>
      <FrequentAskQuestion />
      <Footer4 />
    </>
  );
}

export default MunOnline;
