import climate from "./../science-and-tech/images/climate.gif";
import mental from "./../science-and-tech/images/mental.gif";
const data = [
  {
    _id: "44f4f646g44gggd6q7",
    image: climate,
    title: "Climate Change",
    p: "Climate change is triggered by a shift in the earth's energy balance, which determines how much of the sun's energy enters the earth (and its atmosphere) and is released back into space.The earth gains energy as we reduce the amount of solar energy reflected into space, just as people gain weight when their calorie intake and expenditure are out of balance.Human activities have released massive amounts of greenhouse gases (GHG) into the atmosphere since the Industrial Revolution began over 200 years ago. Instead of letting the sun's energy and heat reflect into space, these GHG act as a greenhouse (or a blanket or a car windshield) to trap it. When GHG concentrations are too high, too much heat is trapped, and the earth's temperature rises beyond its natural range. ",
    p1: "Climate change is causing five critical global environmental changes:7",
    p2: "Warming temperature of the earth’s surface and the oceans: ",
    p3: "Changes in the global water cycle (‘hydrologic’ cycle)",
    p4: "Declining glaciers and snowpack: Across the globe, nearly all glaciers are decreasing in area, volume and mass. One billion people living in river watersheds fed by glaciers and snowmelt are thus impacted",
    p5: "Sea level rise: Warmer water expands, so as oceans warm the increased volume of water is causing sea level rise. Melting glaciers and snowpack also contribute to rising seas.",
    p6: "Ocean acidification: Oceans absorb about 25% of emitted CO2 from the atmosphere, leading to acidification of seawater. These global changes result in what we experience as changes in our local weather and climate:",
    p7: "Greater variability, with “wetter wets”, “drier dries” and “hotter hots” ° More frequent and severe extreme heat events ° More severe droughts ° More intense precipitation, such as severe rains, winter storms and hurricanes ",
    p8: "Higher average temperatures and longer frost-free seasons ",
    p9: "Longer wildfire seasons and worse wildfires",
    p10: "Loss of snowpack and earlier spring runoff ",
    p11: "Recurrent coastal flooding with high tides and storm surge",
  },
  {
    _id: "44f4f646g44gggd6q9",
    image: mental,
    title: "Mental Health",
    p: "Cognitive, behavioral, and emotional well-being are all included in mental health. It all comes down to how people think, feel, and act. The term mental health is sometimes used to refer to the absence of a mental disorder. Mental illness can have a negative impact on daily life, relationships, and physical health. Mental health issues can be caused by a variety of factors, including personal experiences, interpersonal relationships, and physical factors.",
    p1: "Mental health issues can be caused by a variety of factors, including personal experiences, interpersonal relationships, and physical factors.",
    p2: "Taking care of one's mental health can help them enjoy life more. This entails striking a balance between daily activities, responsibilities, and psychological resilience efforts.",
    p3: "Stress, depression, and anxiety can all affect a person's mental health and disrupt their routine.",
    p4: "Despite the widespread use of the term mental health, many conditions that doctors recognize as psychological disorders have physical roots.",
    p5: "The terms mental health and mental illness are defined in this article. We also go over the most common mental disorders, their early warning signs, and how to treat them.",
  },
];
export { data };
