import React from "react";
import "./Freebook.css";
import Header3 from "../../../Layout/header3";
import whatyouget from "./section3.png";
import freebook from "./Image-from-iOS.jpg";
import section2 from "./ebookmockupnew2.png";
import YoutubePlayer from "./Youber";
import Giphy from "./Giphy";
import Card from "./Card";
function Freebook() {
  return (
    <>
      <Header3 />
      {/*  */}
      <div className="freebook__header fontsize__header">
        <p className="text-center">Young To Young</p>
        <p className="text-center fontsize__header">
          The Solution for Geneneration Who Wants to be
        </p>
        <button className="freebook__header__button">A Diplomat!</button>
        <div className="freeboob__header__youtube">
          <YoutubePlayer />
        </div>
        <button className="freebook__header__button_red">
          I want this book
        </button>
        <p className="text-center buttom__text ">
          RUSH ME THE FREE E-BOOK HURRY! STOCK OF THESE E-BOOKS AS OF MAY IS LOW
        </p>
      </div>

      {/* gigphy */}
      <Giphy />
      {/* testimonial */}
      {/* <div className="freebook__testimonial">
        <div className="testimonial__card">
          <img className="" alt="card__testimonial" />
          <div className="testimonial__card__detail">
            <p>Alison Juka</p>
            <div className="testimonial__star">
              <i class="social__media__connect-1 ti-star"></i>
              <i class="social__media__connect-1 ti-star"></i>
              <i class="social__media__connect-1 ti-star"></i>
              <i class="social__media__connect-1 ti-star"></i>
              <i class="social__media__connect-1 ti-star"></i>
            </div>
            <p style={{ color: "#9162FF" }}>Morocco</p>
            <p>
              The bottom line is that there is no way to understand the world
              better than to put yourself in the shoes of other countries. From
              the moment you set foot in a United Nations convention, you are a
              diplomat and you must represent your country's interests in the
              global community. If you want to make a change for the world, Y2Y
              is the perfect opportunity to participate, to become enthusiastic,
              and to understand the world around you. The skills you acquire in
              resolving conflict, public speaking, diplomacy and the
              relationships you build will be with you throughout your life.
            </p>
          </div>
        </div>
      </div> */}
      <Card />
      {/* freeboo__what__you__get */}
      <div className="freeboo__what__you__get">
        <h1 className="text-center  pt10">
          HOW EXACTLY Y2Y CAN HELP ME TO BECOME A DIPLOMAT?!
        </h1>
        <img src={whatyouget} alt="Discription" className="section__image1" />
        <div className="freebook8820">
          <p>
            You might have that question on your mind.
            <mask style={{ backgroundColor: "yellow" }}>
              That’s great though,
            </mask>
            because it shows{" "}
            <mask style={{ backgroundColor: "yellow" }}>
              that you care for your dream!,
            </mask>
            And of course, we will try to answer that question.
          </p>
          <p>
            Alright! So, first, what do you need to be a diplomat? There are
            three main skills that you need to possess. they are:
          </p>
          <div className="freebook__middle">
            <p>✅ Public Speaking</p>
            <p>✅ Diplomacy and Negotiation</p>
            <p>✅ Critical Thinking</p>
          </div>
          <p>
            Sounds really simple, right? But, acquiring those three skills is
            not actually that easy. You need to learn the basic theory and
            practice simultaneously.
          </p>
        </div>
      </div>
      {/* great news */}
      <div className="greate__news">
        <div className="great__extern">
          <h2 className="text-center">
            THE GOOD NEWS IS, YOUNG TO YOUNG (Y2Y) CAN HELP YOU TO LEARN THOSE
            SKILLS!
          </h2>
          <img src={freebook} alt="hello__from" />
          <p>
            Y2Y is an educational simulation and academic activity in which
            people can learn about diplomacy, international relations, and the
            United Nations.
          </p>
          <p>
            <mask style={{ backgroundColor: "yellow" }}>
              In a Y2Y conference
            </mask>
            , you will be role-playing as a delegate of one country. Therefore,
            you are required to speak and present your ideas to other delegates,
            convince other delegates to follow your ideas, and solve problems
            that are present. All of these activities will help you to improve
            the three skills that are mentioned above!
          </p>
          <p>
            Last but not least,{" "}
            <mask style={{ backgroundColor: "yellow" }}>
              you will have a firsthand experience of how a diplomat works!{" "}
            </mask>{" "}
            Remember, people who are experienced could be worth a lot more than
            those of who only have the skills!
          </p>
        </div>
      </div>
      {/* REALLY? THAT’S WONDERFUL! BUT, WHAT SHOULD I DO IN A MUN CONFERENCE? */}
      <div className="freebook__mindful">
        <h2 className="text-center">
          REALLY? THAT’S WONDERFUL! BUT, WHAT SHOULD I DO IN A Y2Y CONFERENCE?
        </h2>
        <img src={section2} alt="section__image" />
        <p>Do not worry! We have prepared SEVEN e-books to help you prepare!</p>
        <p>
          In the first three books, you will find the tips to improve your
          public speaking, diplomacy and negotiation, and critical thinking
          skills. We recommend you to read these three books first.
        </p>
        <p>
          After you finish the first three books, you could read the rest. In
          the last four books, you will find everything that you need to do and
          to prepare for a MUN conference!
        </p>
        <p>
          Psst, you will also get the pro-tips to become the best delegate in
          your MUN conference!
        </p>
      </div>
      {/* form__ebook */}
      <div className="form__Ebooknow">
        <h2 className="text-center">
          THAT’S BRILLIANT! HOW DO I GET THE BOOKS?
        </h2>
        <p>
          First, close your wallet, hide your card, and close your e-money
          application on your phone,
          <mask style={{ backgroundColor: "yellow" }}>
            because these e-books are FREE! Yes, all of the seven e-books!
          </mask>
        </p>
        <p>
          All you need to do is to insert your name and e-mail in the boxes
          below. After that, sit tight and we will send the e-books to your
          e-mail!
        </p>
        <p>
          <strong>Super easy,</strong> right? We hope that these e-books will
          help you to become the best delegate and achieve your dream!
        </p>
      </div>
      {/* form */}
      <form className="form__ebook">
        <label>Name*</label>
        <input />
        <label>Email*</label>
        <input />
        <button>I want This E-book Now!</button>
      </form>

      {/* banner fix */}
      <div className="freebook__fixed">
        <h1 className="text-center"> PUSH THE FREE BOOK</h1>
        <i class="social__media__connect ti-star "></i>
        <i class="social__media__connect ti-star"></i>
        <i class="social__media__connect ti-star"></i>
        <i class="social__media__connect ti-star"></i>
        <i class="social__media__connect ti-star"></i>
      </div>
    </>
  );
}

export default Freebook;
